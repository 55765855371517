// Function to standardize fence height input
export function standardizeFenceHeight(value) {
  // Store original value for reference
  let original = value
  let modified = value
  let isEligible = true

  // Handle empty or whitespace-only input
  if (!modified || modified.trim() === '') {
    return {
      original,
      modified: '',
      isEligible: false,
    }
  }

  // Step 1: Clean up consecutive duplicate characters
  // This fixes cases like "::" -> ":", ";;" -> ";", ".." -> ".", etc.
  // Also handles multiple spaces, quotes, and slashes
  modified = modified
    .replace(/::/g, ':') // Double colons to single
    .replace(/;;/g, ';') // Double semicolons to single
    .replace(/\.\./g, '.') // Multiple dots to single
    .replace(/ {2,}/g, ' ') // Multiple spaces to single
    .replace(/'{2,}/g, "'") // Multiple single quotes to single
    .replace(/"{2,}/g, '"') // Multiple double quotes to single
    .replace(/\/\//g, '/') // Double slashes to single

  // Step 2: Remove unwanted characters from both ends
  // First trims whitespace, then removes special characters
  // Handles cases like "  3'6"  " or "//3'6"//" or ",,3'6",,"
  modified = modified
    .trim() // Remove leading/trailing whitespace
    .replace(/^[\/,:\s;]+|[\/,:\s;]+$/g, '') // Remove these special characters from both the start and end of the string:
  // / (forward slash)
  // , (comma)
  // : (colon)
  // \s (any whitespace: spaces, tabs)
  // ; (semicolon)

  // Step 3: Remove trailing dots specifically
  // Handles cases like "3.6..." -> "3.6"
  modified = modified.replace(/\.+$/, '')

  // Step 4: Format standardization - handles various input formats
  modified = modified
    // Convert "meter" or "meters" to "m", handling optional spaces and case
    .replace(/\s*meters?\s*$/gi, 'm')
    // Standardize "m" suffix with optional spaces
    .replace(/\s*m\s*$/gi, 'm')
    // Clean up decimal notation by removing spaces after dots
    .replace(/\.\s+/g, '.')
    // Convert colons to decimal points (e.g., "1:2" -> "1.2")
    .replace(/:/g, '.')
    // Convert semi colons to quote (e.g., "1;2" -> "1'2")
    .replace(/;/g, "'")
    // Remove trailing inch indicators (IN, in, I, i) with optional spaces
    .replace(/(\s*[iI][nN]|\s*[iI])$/gi, '')
    // Format meter values: add decimal after leading zero
    .replace(/^(?=\d*[mM]$)0+(?=\d)/, '0.')
    // Remove leading zeros for non-meter values
    .replace(/^(?!\d*[mM]$)0+(?=\d)(?!\d*\.)/, '')
    // Remove spaces between feet and inches (e.g., "3' 6" -> "3'6")
    .replace(/(\d+)'\s+(\d+)/g, "$1'$2")

  // Special case: convert lone 'm' to '1'
  if (modified.toLowerCase() === 'm') {
    modified = '1'
  }

  // Remove trailing 'm' if it follows numbers
  if (isEligible && /\d+m$/i.test(modified)) {
    modified = modified.replace(/m$/i, '')
  }

  // Eligibility checks:

  // 1. Multiple decimal points make a value ineligible
  // Example: "1.2.3" would be ineligible
  if ((modified.match(/\./g) || []).length > 1) {
    isEligible = false
  }

  // 2. Check for any disallowed characters
  // Only allows: numbers, decimals, single/double quotes
  if (/[^0-9\.'"]/g.test(modified)) {
    isEligible = false
  }

  // 3. Any remaining spaces make a value ineligible
  // Example: "3 6" would be ineligible
  if (/\s/.test(modified)) {
    isEligible = false
  }

  // 4. For plain numbers (no feet/inches), check if > 70
  // Skip this check for foot-inch notation (e.g., "3'6")
  if (!/['"]/.test(modified)) {
    const numericPart = parseFloat(modified.replace(/[^0-9\.]/g, ''))
    // updated the value from 70 to 200
    if (!isNaN(numericPart) && numericPart > 200) {
      isEligible = false
    }
  }

  // Clean Up
  // Remove anything that follows proper foot-inch format (only when both ' and " exist)
  modified = modified.replace(/(\d+'\d+").*$/, '$1')

  return {original, modified, isEligible}
}

// Function to determine the unit of the fence height value
export function determineFenceUnit(value) {
  // First standardize the input
  const {modified, isEligible} = standardizeFenceHeight(value)

  if (!isEligible) {
    return {
      original: value,
      standardized: modified,
      isEligible: false,
      unit: null,
      isValid: false,
      convertedValue: null,
      reason: 'Value is not eligible for conversion',
    }
  }

  let unit = null
  let isValid = false
  let reason = ''
  let numericValue = 0
  let convertedValue = null

  // Helper function to convert feet and inches to total inches
  const convertToInches = (feet, inches) => {
    const footValue = parseFloat(feet)
    const inchValue = parseFloat(inches)
    if (footValue >= 2 && footValue <= 6) {
      isValid = true
      return footValue * 12 + inchValue
    } else {
      reason = `Feet value (${footValue}) must be between 2 and 6`
      return null
    }
  }

  // Handle various feet and inches formats
  let feetInchesMatch = false

  // Case 1: Standard feet and inches format (3'6")
  if (modified.includes("'") && modified.includes('"')) {
    feetInchesMatch = true
    unit = 'feet_inches'
    const [feet, inches] = modified.split("'")
    const inchesWithoutQuote = inches.replace('"', '')
    numericValue = convertToInches(feet, inchesWithoutQuote)
  }
  // Case 2: Format like 3'73 (no inch symbol) or just 3' (no inches specified)
  else if (modified.includes("'")) {
    feetInchesMatch = true
    unit = 'feet_inches'
    const [feet, inches] = modified.split("'")
    // If no inches specified, treat as 0 inches
    numericValue = convertToInches(feet, inches || '0')
  }
  // Case 3: Format like 2'6' (foot symbols)
  else if (modified.split("'").length > 2) {
    feetInchesMatch = true
    unit = 'feet_inches'
    const parts = modified.split("'")
    numericValue = convertToInches(parts[0], parts[1])
  }
  // Case 4: Format like 3"3" (inch symbols)
  else if (modified.split('"').length > 2) {
    feetInchesMatch = true
    unit = 'feet_inches'
    const parts = modified.split('"').filter((p) => p)
    // Handle potential decimal points in feet part
    const [feet, decimal] = parts[0].split('.')
    const inches = decimal || parts[1] // use decimal part if exists, otherwise use the second number
    numericValue = convertToInches(feet, inches)
  }
  // Case 5: Format like 3.3" (decimal with inch symbol)
  else if (modified.includes('"') && modified.includes('.')) {
    feetInchesMatch = true
    unit = 'feet_inches'
    const withoutQuote = modified.replace('"', '')
    const [feet, inches] = withoutQuote.split('.')
    numericValue = convertToInches(feet, '0.' + inches)
  }

  if (feetInchesMatch) {
    convertedValue = numericValue ? numericValue.toString() : null
    return {
      original: value,
      standardized: modified,
      isEligible: true,
      unit,
      isValid,
      numericValue,
      convertedValue,
      reason,
    }
  }

  // Handle other formats (meters and plain inches)
  numericValue = parseFloat(modified)

  // Value less than 2 is considered meters
  if (numericValue < 2) {
    unit = 'meters'
    convertedValue = numericValue.toString()
    isValid = true
  }
  // Value greater than 6 is considered inches
  else if (numericValue > 6) {
    unit = 'inches'
    convertedValue = numericValue.toString()
    isValid = true
  }
  // Values between 2-6 are considered feet
  else {
    unit = 'feet'
    if (numericValue >= 2 && numericValue <= 6) {
      // Treat decimal point exactly like feet and inches notation
      const [feet, decimal] = modified.split('.')
      if (decimal) {
        // Treat the decimal part directly as inches
        convertedValue = (parseInt(feet) * 12 + parseInt(decimal)).toString()
      } else {
        convertedValue = (numericValue * 12).toString()
      }
      isValid = true
    } else {
      reason = `Feet value (${numericValue}) must be between 2 and 6`
    }
  }

  return {
    original: value,
    standardized: modified,
    isEligible: true,
    unit,
    isValid,
    numericValue,
    convertedValue,
    reason: isValid ? '' : reason,
  }
}

// Function related to number formatting decimal points etc
export const formatNumberValue = (value) => {
  const num = parseFloat(value)
  if (Number.isInteger(num)) {
    return num.toString()
  }
  // First convert to fixed 2 to handle long decimals
  const fixed = num.toFixed(2)
  // Remove trailing zeros after decimal
  return parseFloat(fixed).toString()
}
