import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useAlert, useProgress } from '../../../modules/sgl-utils/DialogsProvider';
import { useAppSelector } from '../../../redux/hooks';
import { useOutputContext } from '../../../modules/output-listing/OutputListingContext';

// Metronic Components
import { KTSVG } from '../../../../_metronic/helpers'

// Assets
import "flatpickr/dist/themes/material_blue.css";
import { PrizeMoneyTab } from './ApplyPayments/PrizeMoneyTab';
import axios from 'axios';
import { addFloatingNumbers, getArrayFromObject, loadingSpinnerBtnRelease } from '../../../modules/sgl-utils/SglFunctions';
import PaymentTab from './ApplyPayments';
import { BalanceTransferTab } from './ApplyPayments/BalanceTransferTab';
import { getRowData, updateAGGridWithoutFlickering } from '../../../modules/sgl-utils/agGridHelpers';

const modalsRoot = document.getElementById('root-modals') || document.body
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const ApplyPaymentTool = ({ show, handleClose, tool }) => {
    const intl = useIntl()
    const showFinancialsLocked = useAppSelector(state => state.currentShow.islocked)     
    const { getOutputSelectionAreaIDs, getEventID  } = useOutputContext()
    const alertDialog = useAlert()
    const {progressBar} = useProgress()

    const customerID = useAppSelector(state => state.showCompany.company_id)
    const currentShowID = useAppSelector(state => state.currentShow.show_id) 

    const [selectedTab, setSelectedTab] = useState(0)
    const [selectedEntries, setSelectedEntries] = useState([])
    const [companyPaymentGateway, setCompanyPaymentGateway] = useState("") 
    const [paymentTypes, setPaymentTypes] = useState([])
    const [listOfStates, setlistOfStates] = useState([])
    const [listOfCountries, setlistOfCountries] = useState([])
    const [autoApplyPrizeMoney, setAutoApplyPrizeMoney] = useState(false)
    const [autoApplyPrizeMoneyPayment, setAutoApplyPrizeMoneyPayment] = useState(false)

    // handle how lock and entries selection errors
    useEffect(() => {
        if (show) {  // Fetch list only once popup is displayed 
            if(showFinancialsLocked){
                alertDialog({ title: "warning", message: intl.formatMessage({ id: "ENTRIES.APPLYPAYMENTS.MODAL.ERRORMESSAGE.SHOWLOCKED"}) })
                handleClose()
                return
            }
            // Get EntryIDs from ion
            getOutputSelectionAreaIDs(false)
            .then(res => { 
                if (res.length === 0) {
                    alertDialog({ message: intl.formatMessage({ id: "ENTRIES.APPLYPAYMENTS.MODAL.ERRORMESSAGE.SELECTENTRIES"}) })
                    handleClose()
                } else if (res.length >= 7000) {
                    alertDialog({ message: intl.formatMessage({ id: "ENTRIES.APPLYPAYMENTS.MODAL.ERRORMESSAGE.REFINESELECTION"}) })
                    handleClose()
                }else{
                    // Get entries & related meta data for EntryIDs
                    const eventID = getEventID("apply-payments-tool")
                    progressBar({ show: true, eventID, showProgress: 'inline', title: "Apply Payments", timeElapsed: true })
    
                    axios.post( NEST_API_URL + '/entries/getApplyPaymentsMetadata', {
                        entry_ids: res,
                        show_id: currentShowID,
                        customer_id: customerID,
                        event_id: eventID
                    })
                    .then(response => {
                        // Used in Payments Tab
                        setCompanyPaymentGateway(response.data.companyPaymentGateway ? response.data.companyPaymentGateway.name : 'Gateway Not Found')
                        setlistOfCountries(response.data.countries ?? [])
                        setlistOfStates(response.data.states ?? [])
                        setPaymentTypes(response.data.paymentTypes ?? [])
                        setAutoApplyPrizeMoney(response.data.autoApplyPrizeMoney && tool == 'Apply Payments')
                        setAutoApplyPrizeMoneyPayment(response.data.autoApplyPrizeMoney && tool == 'Apply Payments')
        
                        // move to payments tab if there no available prize money or A/R credit for entry payments >>>
                        let hasAvailablePrizeMoney = response.data.entriesData.filter((entry) => entry.totalAvailablePrizeMoney > 0).length > 0
                        let hasAvailableARCredit = response.data.entriesData.filter((entry) => entry.rpARBalance < 0 || entry.pmrARBalance < 0).length > 0
                        if(!hasAvailablePrizeMoney && !hasAvailableARCredit){
                            setSelectedTab(2)
                        }else if(!hasAvailablePrizeMoney){
                            setSelectedTab(1)
                        }
                        // move to payments tab if there no available prize money or A/R credit for entry payments <<<

                        setSelectedEntries(response.data.entriesData)
                        progressBar({ show: false, eventID })
                    })
                    .catch(() => progressBar({ show: false, eventID }))
                }
            })
        }
    }, [show])

    const getAvailablePrizeMoneyClass = (params) => {
        if(params.data.totalAvailablePrizeMoney > 0){
            if(params.data.hasPrizeRestriction){ // prize money can't be used 
                return {color: '#f04332', textAlign: 'right' };
            }else if(params.data.totalAvailablePrizeMoney != params.data.available){ // available prize money is less than actual prize money bcz of A/R balance or pending checks
                return {color: '#dba707', textAlign: 'right'};
            }
        }

        return {textAlign: 'right'}
    }

    const recalculatePrizeMoney = (gridRef, recalDistribution=true, applyPrizeMoneyPayment=true) => {
        let rowsData = getRowData(gridRef)

        // populate PMR available credit >>>
        let pmrsAvailableCredit = []
        rowsData.map((row) => {
            if(pmrsAvailableCredit.find((pmr) => pmr.people_id == row.prizemoneyrecipient_id)){
                // do nothing
            }else{
                pmrsAvailableCredit.push({people_id: row.prizemoneyrecipient_id, available: row.pmrTransferableCredit})
            }
        })
        // populate PMR available credit <<<

        for(let data of rowsData){
            // get pmr available credit
            let pmrData = pmrsAvailableCredit.find((pmr) => pmr.people_id == data.prizemoneyrecipient_id)

            // entry prize money payment
            let prizeMoneyPayment = !recalDistribution ? data.prizeMoneyPayment : data.availablePrizeMoney
            prizeMoneyPayment = Math.min(prizeMoneyPayment, data.balance)
            data.available = data.availablePrizeMoney

            let prizeMoneyPaymentApplied = true
            if(!data.hasPrizeRestriction && data.apply && data.balance > 0 && pmrData && applyPrizeMoneyPayment){
                // prize money payment can be applied to the entry
                data.prizeMoneyPayment = prizeMoneyPayment
            }else{
                // no prize money payment can be applied to the entry
                data.prizeMoneyPayment = 0
                prizeMoneyPaymentApplied = false
            }

            if(data.unusedPrizeMoney > 0){                    
                // entry has some unused prize money present in A/R.
                let unusedPrizeMoneyAppliedToTheEntry = Math.min(data.unusedPrizeMoney, data.pmrTransferableCredit) // unused prize money that will be used for entry payment

                if(unusedPrizeMoneyAppliedToTheEntry > pmrData.available){
                    /* the unapplied prize money that will be used for entry payment is greater then pmr's available credit.
                    remove the unapplied prize money from available prize money and add the available pmr credit.*/
                    let availablePrizeMoney = addFloatingNumbers([data.availablePrizeMoney, -1*unusedPrizeMoneyAppliedToTheEntry, pmrData.available], 2)
                    availablePrizeMoney = availablePrizeMoney <= 0 ? 0 : availablePrizeMoney

                    if(recalDistribution && prizeMoneyPaymentApplied){ // don't update prize money payment if payment amount is updated
                        data.prizeMoneyPayment = Math.min(availablePrizeMoney, data.balance)
                    }
                    
                    // update the entry available prize money
                    data.available = availablePrizeMoney
                }else{
                    // whole unused prize money can be used for entry payment. So, don't update prize money payment and available prize money
                }

                // calculate how much credit will be used from pmr's A/R. and remove it from pmr's available credit
                let arPrizeMoneyToBeUsed = prizeMoneyPaymentApplied ? addFloatingNumbers([data.prizeMoneyPayment, -1*data.unappliedPrizeMoney]) : 0
                arPrizeMoneyToBeUsed = arPrizeMoneyToBeUsed > 0 ? arPrizeMoneyToBeUsed : 0 // if arPrizeMoneyToBeUsed < 0 then unapplied prize money can completely payoff the entry
                pmrData['available'] = addFloatingNumbers([pmrData['available'], -1*arPrizeMoneyToBeUsed], 2)
            }else{ 
                // entry has unapplied prize money only. So, don't update prize money payment and available prize money
            }
        }

        updateAGGridWithoutFlickering({ current: {api: gridRef }}, rowsData)
        // calculateTotals()
    }

    const applyPayment = async (event, rowData, data) => {
        const eventID =  getEventID("apply-payments-tool")
        try {
            progressBar({ show: true, timeElapsed: true, title: "Applying Payments", eventID })
            const res = await axios.post(`${NEST_API_URL}/entries/applyPayments`, {
                row_data: rowData,
                data,
                customer_id: customerID,
                current_show_id: currentShowID,
                paymentAmount: data.paymentAmount,
                event_id: eventID   
            })

            if(res.data.success) {
                alertDialog({ message: "Payment Successful.", title: "Success", icon: 'info' })
            }else{
                alertDialog({message: res.data.errorMessage})
            }

            if(res.data.success){
                // Update Entries
                const response = await axios.post( NEST_API_URL + '/entries/getApplyPaymentsMetadata', {
                    entry_ids: getArrayFromObject('entry_id', selectedEntries, true),
                    show_id: currentShowID,
                    customer_id: customerID,
                    //event_id: getEventID("apply-payments-tool")
                })

                setSelectedEntries(response.data.entriesData)
            }

            return res.data.success
        
        }  catch (reason) {  
            alertDialog({ message: reason?.response?.data?.message ?? (reason?.response?.data?.error ?? 'Something went wrong..'), title: 'Error' })
            return false
        } finally {
            loadingSpinnerBtnRelease(event)
            progressBar({ show: false, eventID })
        }
    }

    const tabs = ["PRIZEMONEY",  "BALANCETRANSFER", "PAYMENTS"]
    const componentTags = [
        <PrizeMoneyTab handleClose={handleClose} selectedEntries={selectedEntries} applyPayment={applyPayment} getAvailablePrizeMoneyClass={getAvailablePrizeMoneyClass} recalculatePrizeMoney={recalculatePrizeMoney}/>,
        <BalanceTransferTab handleClose={handleClose} selectedEntries={selectedEntries} applyPayment={applyPayment}/>,
        <PaymentTab handleClose={handleClose}  selectedEntries={selectedEntries} companyPaymentGateway={companyPaymentGateway} listOfCountries={listOfCountries} paymentTypes={paymentTypes} listOfStates={listOfStates} applyPayment={applyPayment} calledFrom={tool} autoApplyPrizeMoney={autoApplyPrizeMoney} setAutoApplyPrizeMoney={setAutoApplyPrizeMoney} autoApplyPrizeMoneyPayment={autoApplyPrizeMoneyPayment} setAutoApplyPrizeMoneyPayment={setAutoApplyPrizeMoneyPayment} getAvailablePrizeMoneyClass={getAvailablePrizeMoneyClass} recalculatePrizeMoney={recalculatePrizeMoney}/>
    ]

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered apply-payments-modal'
            show={show}
            onHide={handleClose}
            backdrop='static'
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{tool == 'Checkout Entries' ? intl.formatMessage({ id: 'ENTRIES.QUICKACTION.POPUP.HEADING.CHECKOUT.ENTRIES' }) : intl.formatMessage({ id: 'ENTRIES.QUICKACTION.POPUP.HEADING.APPLYPAYMENTS' })}</h2>
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form' onSubmit={e => e.preventDefault()}>
                    { tool == 'Checkout Entries' ?
                    // if Checkout Entries tool display all tabs 'Prize Money Tab', 'Balance Transfer Tab' and 'Payments Tab'
                    <div className='card-body p-0 row'>
                        <h2 className="fs-4">{intl.formatMessage({ id: 'APPLY.PAYMENT.TOOL.HEADING' })}</h2>
                        <div className='d-flex flex-column my-2 border p-2 bg-white' style={{ height: "100%", position: "relative" }}>
                            <ul tabIndex="-1" className='nav nav-tabs nav-pills fs-6 fw-bolder flex-nowrap' id="myTab" role="tablist">
                                {
                                    tabs.map((tab, index) => 
                                        <li key={`${tab}-${index}`} className='nav-item' style={{lineHeight: "16px",fontSize: "13px", paddingBottom: "1px", backgroundColor:"#2274a5"}} onClick={() => setSelectedTab(index)}>
                                            <a className={"nav-link text-active-dark px-10 py-1 text-gray-700" + (index == selectedTab ? ' active' : '')}
                                                tabIndex="-1"
                                                id={tab + "-tab"}
                                                data-bs-toggle='tab'
                                                href={"#" + tab + "-APPLYPAYMENTS" }
                                            >
                                                {intl.formatMessage({ id: 'APPLY.PAYMENT.TOOL.TAB.' + tab })}
                                            </a>
                                        </li>
                                    )
                                }
                            </ul>
                           
                            <div className="tab-content" id="myTabContent" style={{ height: "100%" }}>
                                {    
                                    tabs.map((tab, index) => {
                                        return (
                                            <div className={"tab-pane fade show" + (index == selectedTab ? " active" : "")} id={tab + "-APPLYPAYMENTS"} role="tabpanel" style={{ height: "100%" }}>
                                                {componentTags[index]}
                                            </div>
                                        );
                                    }) 
                                }
                            </div>
                        </div>
                    </div>
                    :
                    // if Apply Payments display Payments tab only
                    componentTags[2]
                    }
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export default ApplyPaymentTool