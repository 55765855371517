import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../_metronic/helpers'
import { useEffect, useRef, useState } from 'react'
import { useAlert, useConfirm, useLoadingOverlay } from '../../../modules/sgl-utils/DialogsProvider'
import { useAppSelector } from '../../../redux/hooks'
import axios from 'axios'
import { useAnnouncerToolContext } from './AnnouncerToolContext'
import { CombinedClassPopup } from '../../entries/Detail/Classes/CombinedClassPopup'
import { loadingSpinnerBtnRelease, loadingSpinnerBtnWait, overrideEventTarget } from '../../../modules/sgl-utils/SglFunctions'

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL
const modalsRoot = document.getElementById('root-modals') || document.body

const QuickAdd = ({ show, handleClose }) => {
    const intl = useIntl()
    const alertDialog = useAlert()
    const confirmDialog = useConfirm()
    const showId = useAppSelector(state => state.currentShow.show_id)
    const loadingOverlay = useLoadingOverlay()
    const customerId = useAppSelector(state => state.showCompany.company_id)    
    const [entryNumber, setEntryNumber] = useState()
    const [classNumbers, setClassNumbers] = useState('')
    const [entryRiders, setEntryRiders] = useState([])
    const [selectedRider, setSelectedRider] = useState(-1)
    const { selectedClassGroup, setUpcomingTrips } = useAnnouncerToolContext()
    const entryNumberInputRef = useRef(null);

    useEffect(() => { 
        if (entryNumberInputRef.current) 
            entryNumberInputRef.current.focus() 
    }, []) // Focus entry number field on load
    // COMBINED CLASS POPUP STATES
    const [showCombinedClassPopup, setShowCombinedClassPopup] = useState(false)
    const [actualClasses, setActualClasses] = useState({})

    const getEntryFromNumber = async (e) => {
        const _entryNumber = isNaN(e.target.value) ? 0 : Number(e.target.value)
        if (_entryNumber <= 0) { return }

        loadingOverlay({ show: true })
        try {
            const response = await axios.get(`${NEST_API_URL}/entries/getEntryFromNumber`, {
                params: {
                    entry_number: _entryNumber,
                    customer_id: customerId,
                    show_id: showId,
                }
            })
            setEntryRiders(response.data.entry.EntryRiders)
            setSelectedRider(response.data.entry.EntryRiders[0].rider_id)
        } catch (reason) {
            alertDialog({ message: reason.response.data.message })
            setEntryRiders([]) // Clear Entry Riders to update Select
            setSelectedRider(-1)  // Clear selected rider
            setEntryNumber('')
        } finally {
            loadingOverlay({ show: false })
        }
    }

    const quickAddHandler = async (_classNumbers = null) => {
        let message = ''
        if (isNaN(entryNumber) || Number(entryNumber) <= 0) { 
            message = 'Please provide valid entry number.'
        } else if (!classNumbers) {
            message  = 'Please provide one or more class numbers.'
        } else if (!selectedRider || selectedRider === -1) {
            message = 'Please select rider.'
        } 

        if (message) return alertDialog({ message });

        
        let event = overrideEventTarget({}, 'addEntryFromIngate')
        if(event?.target?.disabled){ //safety check to not call multiple times if something triggers it multiple times
            return false;
        }
        loadingSpinnerBtnWait(event)
        loadingOverlay({ show: true })
        try {
            let response = await axios.post(`${NEST_API_URL}/ingate-announcer/addEntryFromIngate`, {
                entry_number: entryNumber,
                class_numbers: _classNumbers === null ? classNumbers : _classNumbers,
                rider_id: selectedRider,
                class_group_id: selectedClassGroup,
                customer_id: customerId,
                show_id: showId,
            })

            const { rule_failed_messages, entryId } = response.data
            // Rules Failed!
            if (rule_failed_messages && rule_failed_messages.length > 0) {
                let confirmMessage = ['The following rules failed:', ...rule_failed_messages, 'Add entries anyway?']
                const choice = await confirmDialog({ message: confirmMessage.join('<br/>') })
                if (choice) {
                    loadingOverlay({ show: true })
                    response = await axios.post(`${NEST_API_URL}/ingate-announcer/saveAddEntryAndRelatedData`, {
                        entry_id: entryId,
                        class_group_id: selectedClassGroup,
                        customer_id: customerId,
                        show_id: showId,
                        add_service_response: response.data
                    })
                    loadingOverlay({ show: false })
                }
            }

            const { success, classesNotPartOfGroup, ignoreAlreadyAdded, requirements_response, upcomingTrips, checkCombinedClasses } = response.data

            // Check for Combined Classes
            if (checkCombinedClasses) {
                setActualClasses(response.data.classList)
                setShowCombinedClassPopup(true)
            } else {
                // Classes Not Part of In-gate Group Error Handling
                const messages = []
                if (classesNotPartOfGroup.length > 0) 
                    messages.push(...classesNotPartOfGroup.map(classNumber => `Class ${classNumber} does not belong to this group.`));

                if (ignoreAlreadyAdded && ignoreAlreadyAdded.length > 0)
                    messages.push(...ignoreAlreadyAdded.map(classNumber => `Entry ${entryNumber} is already in class ${classNumber} with rider ${entryRiders.find(er => er.rider_id == selectedRider).rider_name}.`) )

                // Class Requirements Response Handling
                if (requirements_response) {
                    if (requirements_response.cancel_classes_message.length > 0) // Requirements FAILED!
                        messages.push(...requirements_response.cancel_classes_message);
                    
                    if (requirements_response.warn_classes_message.length > 0) { 
                        if (messages.length > 0) messages.push(''); // To separate disallow & warn messages
                        messages.push(...requirements_response.warn_classes_message)
                    }
                }

                if (success && upcomingTrips)
                    setUpcomingTrips(upcomingTrips)   
                
                if (messages.length > 0) {
                    messages.splice(0, 0 , 'Following errors/warnings were encountered during the add process:<br/>')
                    alertDialog({ message: messages.join('<br/>') })
                }
            }

            if (success && upcomingTrips) {
                setUpcomingTrips(upcomingTrips) 
                handleClose()   
            }
            
            // Clear data
            setEntryNumber('')
            setClassNumbers('')
            setEntryRiders([])
            setSelectedRider(-1)
        } catch (reason) {
            if (reason && reason.response && reason.response.data.message)
                alertDialog({ message: reason.response.data.message })
            else 
                alertDialog({ message: reason })
        } finally {
            loadingOverlay({ show: false })
            loadingSpinnerBtnRelease(event)
            handleClose()
        }
    }

    return createPortal(
        <Modal
            id='kt_modal_1'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal modal-dialog modal-dialog-centered mw-350px announcer-quick-add-form'
            show={show}
            onHide={handleClose}
            onKeyPress={(event) => {
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key == 'Enter' && !isButtonFocused) { //call function only when no button is in focus
                    quickAddHandler()
                }
            }}
        >
            <CombinedClassPopup 
                show={showCombinedClassPopup}
                handleClose={() => setShowCombinedClassPopup(false)}
                isAddMode={true}
                classList={actualClasses}
                area="Ingate"
                addClickHandler={actualClassNumbers => quickAddHandler(actualClassNumbers)}
            />
            <div className="modal-header py-0 px-4">
                <h2 className="fs-4">{intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.MODAL.QUICKADD'})}</h2>
                <div className="btn btn-sm btn-icon btn-active-color-dark" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}>
                    <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon svg-icon-2x"/>
                </div>
            </div>

            <div className="modal-body py-3 px-4">
                <form noValidate className='form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-lg-100px pe-0' htmlFor='entryNumber' data-tooltip-id="ANNOUNCERTOOL.DETAIL.MODAL.QUICKADD.LABEL.ENTRYNO">{intl.formatMessage({ id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.MODAL.LABEL.ENTRYNO' })}</label>
                            <div className='col'>
                                <input
                                    id='entryNumber'
                                    type='number'
                                    className='form-control  mb-2 mb-lg-0 fs-6 h-30px py-1'
                                    value={entryNumber}
                                    onChange={e => setEntryNumber(e.target.value)}
                                    onBlur={getEntryFromNumber}
                                    ref={entryNumberInputRef}
                                />
                            </div>
                        </div>

                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-lg-100px pe-0' htmlFor='classNumbers' data-tooltip-id="ANNOUNCERTOOL.DETAIL.MODAL.QUICKADD.LABEL.CLASSNO">{intl.formatMessage({ id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.MODAL.LABEL.CLASSNO' })}</label>
                            <div className='col'>
                                <input
                                    id='classNumbers'
                                    type='text'
                                    className='form-control  mb-2 mb-lg-0 fs-6 h-30px py-1'
                                    value={classNumbers}
                                    onChange={e => setClassNumbers(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-lg-100px pe-0' htmlFor='rider' data-tooltip-id="ANNOUNCERTOOL.DETAIL.MODAL.QUICKADD.LABEL.RIDER">{intl.formatMessage({ id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.MODAL.LABEL.RIDER' })}</label>
                            <div className='col'>
                                <select 
                                    id="rider" 
                                    className='form-select form-select-sm fs-6 h-30px py-1'
                                    onChange={e => setSelectedRider(e.target.value)}    
                                >
                                    { entryRiders.map(er => <option value={er.rider_id}>{er.rider_name}</option>) }
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='card-footer d-flex justify-content-end py-3 px-0'>
                        <button type='button' className='btn btn-sm btn-secondary me-4 fw-bold text-uppercase' style={{ marginLeft: "auto" }} onClick={handleClose}>
                            {intl.formatMessage({ id: 'FORM.INPUT.COMMON.TAB.NOTES.MODAL.NOTE.BUTTON.CANCEL' })}
                        </button>
                        <button 
                            type='button' 
                            className='btn btn-sm btn-dark fw-bold text-uppercase'
                            autoFocus                            
                            onClick={() => quickAddHandler()}
                            data-tooltip-id="ANNOUNCERTOOL.DETAIL.MODAL.QUICKADD.BUTTON.ADD"
                            id='addEntryFromIngate'
                        >
                            <span className="spinner-border spinner-border-sm d-none h-10px w-10px me-2 mt-1 mb-1" role="status" aria-hidden="true"></span>
                            {intl.formatMessage({ id: 'FORM.INPUT.COMMON.TAB.NOTES.MODAL.NOTE.BUTTON.ADD' })}
                        </button>
                    </div>
                </form>

            </div>
        </Modal>,
        modalsRoot
    )
}

export { QuickAdd }