import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom'
import { Alert, Modal } from 'react-bootstrap'
import { KTSVG } from '../../../_metronic/helpers'
import { useIntl } from 'react-intl'
import Flatpickr from 'react-flatpickr'
import moment from 'moment'
import { date_options } from '../sgl-utils/fieldControls';
import { NumericFormat } from 'react-number-format';
import { useAlert } from '../sgl-utils/DialogsProvider';

type Props = {
  show: boolean
  type: string
  onConfirm: (data: any) => void,
  onClose: () => void,
  name: string
}

const modalsRoot = document.getElementById('root-modals') || document.body

const DateRangeDialog = ({ show, type, onConfirm, onClose, name }: Props) => {
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState('');
  const obj = {
    startDate: '',
    endDate: '',
    date: '',
    year: new Date().getFullYear() - 1,
    showTaxId: ''
  }
  const [data, setData] = useState(obj);
  const intl = useIntl()
  const alertDialog = useAlert()

  useEffect(() => {
    if(!isLoading){
      setErrorMessage('')
      setShowModal(true)
    }else{
      setIsLoading(false)
    }
  }, [show, type])

  const hideModal = () => {
    if(onClose){
      onClose()
    }
    setData(obj)
    setErrorMessage('')
    sessionStorage.removeItem('dateRangeModal')
    setShowModal(false)
  }

  const submitConfirm = () => {
    let flag = false
    
    if(onConfirm){
      // Validate start date and end date for double date input
      if(type == 'Double Date Input'){
        if(!moment(data.startDate).isValid()){
          setErrorMessage(intl.formatMessage({ id: 'DIALOG.COMMON.DATERANGEDIALOG.STARTDATEERROR' }))
        }else if(!moment(data.endDate).isValid()){
          setErrorMessage(intl.formatMessage({ id: 'DIALOG.COMMON.DATERANGEDIALOG.ENDDATEERROR' }))
        }else{
          flag = true
        }
      }
      // Validate date for single date input
      else if(type == 'Single Date Input'){
        if(!moment(data.date).isValid()){
          setErrorMessage(intl.formatMessage({ id: 'DIALOG.COMMON.DATERANGEDIALOG.DATEERROR' }))
        }else{
          flag = true
        }
      }
      // Validate year for Year cause show tax id is optional
      else if(['Year', 'Year and Show Tax Id'].includes(type)){
        if(data.year == 0){
          setErrorMessage(intl.formatMessage({ id: 'DIALOG.COMMON.DATERANGEDIALOG.YEARERROR' }))
        }else{
          flag = true
        }
      }
      if(flag){
        onConfirm(data)
        setData(obj)
        sessionStorage.removeItem('dateRangeModal')
        setShowModal(false)
      }
    } else {
        setData(obj)
    }
  }


  return createPortal(
    <Modal
      id='kt_modal_create_app'
      enforceFocus={false}
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-450px search-form'
      show={!isLoading && showModal}
      onHide={hideModal}
      onKeyDown={(event:any) => {
        if (event.key == "Enter" && !event.shiftKey) {
          submitConfirm()
        }
      }}
    >
      <div className='modal-header py-0 px-4'>
        <h2 className="fs-4">{name == 'Financial Reports' ? '' :  intl.formatMessage({ id: 'DIALOG.COMMON.TITLE.DATERANGEDIALOG.TITLE' })}</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={hideModal}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-3 px-4 shadow-lg'>
        <form noValidate className='form' onSubmit={e => e.preventDefault()}>
          <div className='card-body p-0'>
            {type == 'Double Date Input'? 
            <> 
              <div className='row mb-2'>
                  <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-125px' htmlFor='start_date'>{intl.formatMessage({ id: 'DIALOG.COMMON.DATERANGEDIALOG.STARTDATE' })}</label>
                  <div className='col-lg-7'>
                      <div className="input-group date">     
                          <Flatpickr 
                              className="form-control form-control-sm  fs-6 min-h-20px py-1"
                              id = "start_date"
                              placeholder="MM/DD/YYYY"
                              options={date_options}
                              autoComplete={"off"}
                              onClose={(value: any) => {
                                value.length > 0?
                                setData(prev => ({...prev, startDate: moment(value[0]).format('YYYY-MM-DD')})):
                                setData(prev => ({...prev, startDate: ''}))
                              }}
                              onChange={(value: any) => {
                                value.length > 0?
                                setData(prev => ({...prev, startDate: moment(value[0]).format('YYYY-MM-DD')})):
                                setData(prev => ({...prev, startDate: ''}))
                              }}
                              autoFocus
                          />
                          <div className="input-group-append">
                              <label htmlFor='start_date' className='date-label'
                                onClick={(e) => {
                                  e.stopPropagation(); 
                                  const dateElement = document.getElementById('start_date') as any;
                                  if (dateElement && dateElement._flatpickr) {
                                    dateElement._flatpickr.open();
                                  } 
                                }}
                              >
                                  <span className="input-group-text">
                                      <i className="la la-calendar"></i>
                                  </span>
                              </label>
                          </div>
                      </div>
                  </div>
              </div>

              <div className='row mb-2'>
                  <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-125px' htmlFor='end_date'>{intl.formatMessage({ id: 'DIALOG.COMMON.DATERANGEDIALOG.ENDDATE' })}</label>
                  <div className='col-lg-7'>
                      <div className="input-group date">     
                          <Flatpickr 
                              className="form-control form-control-sm  fs-6 min-h-20px py-1"
                              id = "end_date"
                              placeholder="MM/DD/YYYY"
                              options={date_options}
                              onClose={(value: any) => {
                                value.length > 0 ?
                                setData(prev => ({...prev, endDate: moment(value[0]).format('YYYY-MM-DD') })): 
                                setData(prev => ({...prev, endDate: ''}))
                              }}
                              onChange={(value: any) => {
                                value.length > 0 ?
                                setData(prev => ({...prev, endDate: moment(value[0]).format('YYYY-MM-DD') })): 
                                setData(prev => ({...prev, endDate: ''}))
                              }}
                          />
                          <div className="input-group-append">
                              <label htmlFor='end_date' className='date-label'
                                onClick={(e) => {
                                  e.stopPropagation(); 
                                  const dateElement = document.getElementById('end_date') as any;
                                  if (dateElement && dateElement._flatpickr) {
                                    dateElement._flatpickr.open();
                                  } 
                                }}
                              >
                                  <span className="input-group-text">
                                      <i className="la la-calendar"></i>
                                  </span>
                              </label>
                          </div>
                      </div>
                  </div>
              </div>
            </>
            : null}

            {type == 'Single Date Input'? 
              <div className='row mb-2'>
                <label className='col-lg-2 col-form-label fs-5 py-1 w-lg-125px' htmlFor='date'>{intl.formatMessage({ id: 'DIALOG.COMMON.DATERANGEDIALOG.DATE' })}</label>
                <div className='col-lg-7'>
                  <div className="input-group date">     
                    <Flatpickr 
                        className="form-control form-control-sm  fs-6 min-h-20px py-1"
                        id = "date"
                        placeholder="MM/DD/YYYY"
                        options={date_options}
                        onClose={(value: any) => {
                          value.length > 0?
                          setData(prev => ({...prev, date: moment(value[0]).format('YYYY-MM-DD')})):
                          setData(prev => ({...prev, date: ''}))
                        }}
                        onChange={(value: any) => {
                          value.length > 0?
                          setData(prev => ({...prev, date: moment(value[0]).format('YYYY-MM-DD')})):
                          setData(prev => ({...prev, date: ''}))
                        }}
                        autoFocus
                    />
                    <div className="input-group-append">
                      <label htmlFor='date' className='date-label'
                        onClick={(e) => {
                          e.stopPropagation(); 
                          const dateElement = document.getElementById('date') as any;
                          if (dateElement && dateElement._flatpickr) {
                            dateElement._flatpickr.open();
                          } 
                        }}
                      >
                          <span className="input-group-text">
                              <i className="la la-calendar"></i>
                          </span>
                      </label>
                    </div>
                  </div>
                </div>
             </div>
            : null}

            {['Year', 'Year and Show Tax Id'].includes(type)? 
            <> 
            <div className='row mb-2'>
                <label className='col-lg-5 col-form-label fs-5 py-1' htmlFor='year'>{intl.formatMessage({ id: 'DIALOG.COMMON.DATERANGEDIALOG.YEAR' })}</label>
                <div className='col-lg-6'>
                    <div className="input-group date">
                        <NumericFormat
                          id='year'
                          value={data.year}
                          decimalScale={0}
                          allowNegative={false}
                          onValueChange={(e: any) => {
                            setData(prev => ({...prev, year: e.value}))
                          }}
                          className='form-control form-control-sm fs-6 min-h-20px py-1 number-input'
                          autoFocus
                        />
                    </div>
                </div>
            </div>

            {
              type == 'Year and Show Tax Id' ?
              <div className='row mb-2'>
                  <label className='col-lg-5 col-form-label fs-5 py-1' htmlFor='end_date'>{intl.formatMessage({ id: 'DIALOG.COMMON.DATERANGEDIALOG.SHOWTAXID' })}</label>
                  <div className='col-lg-6'>
                      <div className="input-group date">
                          <NumericFormat
                            id='show_tax_id'
                            decimalScale={0}
                            allowNegative={false}
                            onValueChange={(e: any) => {
                              setData(prev => ({...prev, showTaxId: e.value}))
                            }}
                            className='form-control form-control-sm fs-6 min-h-20px py-1 number-input'
                          />
                      </div>
                  </div>
              </div>
              : null
            }
          </>
          : null}
            
          </div>

          <div>
            <div className='card-footer d-flex justify-content-between py-3 px-0'>
              <label className='text-danger'>{errorMessage}</label>
            </div>
            <div className='d-flex justify-content-end'>
              <button className='btn btn-sm btn-secondary me-4 fw-bold' style={{ marginLeft: "auto" }} onClick={hideModal}>
                { intl.formatMessage({ id: 'DIALOG.COMMON.BUTTON.CANCEL' }) }
              </button>

              <button className='btn btn-sm btn-dark fw-bold' onClick={submitConfirm}>
                { intl.formatMessage({ id: 'DIALOG.COMMON.BUTTON.OK' }) }
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>,
    modalsRoot
  )
}

export { DateRangeDialog }