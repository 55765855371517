import { useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'

// Metronic Components
import { KTSVG } from '../../../../../_metronic/helpers'

type Props = {
    show: boolean
    handleClose: () => void,
    role: string,
    name: string,
    digitalSignatureAction: any,
    futureUnsignedEntries: boolean
}

const modalsRoot = document.getElementById('root-modals') || document.body

const PaperEntryConfirmationForm = ({ show, handleClose, role, name, digitalSignatureAction, futureUnsignedEntries }: Props) => {
    const intl = useIntl()
    const [replicateAuthRequest, setReplicateAuthRequest] = useState(false);

    const digitalSignature = () => {
        digitalSignatureAction('Manual Signature Received', '', role, replicateAuthRequest)
        handleClose()
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-550px search-form'
            show={show}
            onHide={handleClose}
            onKeyDown={(event :any) => {
                let isButtonFocused = false
                let activeElement = document.activeElement //gets the currently focussed element
                if (activeElement && activeElement.tagName === 'BUTTON') { //check if button is in focus
                    isButtonFocused = true;
                }
                if (event.key === "Enter" && !isButtonFocused) {
                    digitalSignature()
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4"></h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        <div className='row mb-3'>
                            <div className='col-lg-1 d-flex align-self-center'>
                                <i className="bi bi-exclamation-octagon-fill fa-2xl text-red"></i>
                            </div>
                            <div className='col ps-lg-1'>
                                <div className='row'>
                                    <label className='col-lg-12 col-form-label fs-5 py-1' data-tooltip-id="ENTRIES.DETAIL.MODAL.PAPERENTRYCONFIRMATIONFORM.LABEL.PAPERRECEIVED">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.MODAL.PAPERENTRYCONFIRMATION.PAPERRECIEVED' })} {name}?</label>
                                </div>
                            </div>
                        </div>
                        {futureUnsignedEntries || true? 
                        <div className='row mb-3'>
                            <div className='col-lg-1'></div>
                            <div className='col ms-lg-1 form-check-sm form-check-custom'>
                                <input
                                    className='form-check-input'
                                    type='checkbox'
                                    id='APPLYSIGNATURE'
                                    checked={replicateAuthRequest}
                                    onChange={e => setReplicateAuthRequest(e.target.checked)}
                                    tabIndex={1}
                                />
                                <label className='col-form-label mx-2 fs-5 py-1' htmlFor='APPLYSIGNATURE' data-tooltip-id="ENTRIES.DETAIL.MODAL.PAPERENTRYCONFIRMATIONFORM.LABEL.APPLYSIGNATURE"> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.MODAL.PAPERENTRYCONFIRMATION.LABEL.APPLYSIGNATURE' })}</label>
                            </div>
                        </div>
                        :null}
                    </div>
                    <div className='card-footer d-flex justify-content-end py-3 px-0'>
                        <button type='button' className='btn btn-sm btn-secondary me-4 fw-bold' style={{ marginLeft: "auto" }} onClick={handleClose} tabIndex={2}>
                            {intl.formatMessage({ id: 'DIALOG.COMMON.BUTTON.CANCEL' })}
                        </button>

                        <button type='button' className='btn btn-sm btn-dark fw-bold' onClick={digitalSignature} tabIndex={3} autoFocus={futureUnsignedEntries ? false : true}>
                            {intl.formatMessage({ id: 'DIALOG.COMMON.BUTTON.OK' })}
                        </button>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { PaperEntryConfirmationForm }