import { useIntl } from 'react-intl'
import { useCurrencyFormatter } from '../../../../modules/sgl-utils/Formatters';
import { useAlert } from '../../../../modules/sgl-utils/DialogsProvider';
import { useEffect, useMemo, useState } from 'react';
import { getExtraNarrowHeaderHeight, getNarrowRowHeight, getRowData, numberValueSetter, renderCheckBox, updateAGGridWithoutFlickering } from '../../../../modules/sgl-utils/agGridHelpers';
import CheckboxHeader from '../../../../modules/components/CheckboxHeader';
import { AgGridReact } from 'ag-grid-react';
import { addFloatingNumbers, getArrayFromObject } from '../../../../modules/sgl-utils/SglFunctions';

const PrizeMoneyTab = ({handleClose, selectedEntries, applyPayment, getAvailablePrizeMoneyClass, recalculatePrizeMoney}) => {
    const intl = useIntl();
    const currencyFormatter = useCurrencyFormatter()
    const alertDialog = useAlert()

    const [checkAll, setCheckAll] = useState(true)
    const [gridRef, setGridRef] = useState()
    const [entriesWithBalanceOnly, setEntriesWithBalanceOnly] = useState(false)
    const [allRowData, setAllRowData] = useState([])
    const [recalPrizeMoney, setRecalPrizeMoney] = useState(true)

    const containerStyle = useMemo(() => ({ width: '100%', height: '65vh' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%'}), []);

    const defaultColDef = {
        suppressMenu: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        cellStyle: params => {
            let style = {textAlign: 'left'}
            if (typeof params.value === 'number') {
                style = { textAlign: 'right' }
            } else if (typeof params.value === 'boolean') {
                style = { textAlign: 'center' }
            }
           return { ...style } 
        },
        headerClass: "ag-center-aligned-header",
        sortable: true,
        resizable: true
    }

    const columnDefs = [
        { 
            field: 'Show.show_name', 
            headerName: intl.formatMessage({ id: 'ENTRIES.FEESPLIT.MODAL.GRID.SHOW' }), 
            flex: 3,
        },
        { 
            field: 'number',
            headerName: intl.formatMessage({ id: "LIST.ENTRIES.NUMBER" }),
            width: 90,
            cellClass: 'text-center'
        },
        { 
            field: 'horse', 
            headerName: intl.formatMessage({ id: "LIST.ENTRIES.HORSE" }), 
            flex: 2,
        },
        { 
            field: 'owner', 
            headerName: intl.formatMessage({ id: "ENTRIES.APPLYPAYMENTS.MODAL.GRID.OWNER" }), 
            flex: 2,
        },
        { 
            field: 'prizemoneyrecipient', 
            headerName: intl.formatMessage({ id: "ENTRIES.APPLYPAYMENTS.MODAL.GRID.PMR" }), 
            flex: 2,
        },
        { 
            field: 'totalPrizeMoneyWon', 
            headerName: intl.formatMessage({ id: "ENTRIES.APPLYPAYMENTS.MODAL.GRID.PRIZEMONEYWON" }), 
            width: 125,
            cellRenderer: params => params.node.rowPinned ? null : currencyFormatter(params.value),
            cellStyle: { textAlign: 'right' }
        },
        { 
            field: 'available', 
            headerName: intl.formatMessage({ id: "ENTRIES.APPLYPAYMENTS.MODAL.GRID.AVAILABLEPRIZEMONEY" }), 
            width: 125,
            cellRenderer: params => params.node.rowPinned ? null : currencyFormatter(params.value),
            cellStyle: { textAlign: 'right' },
            cellStyle: params => getAvailablePrizeMoneyClass(params)
        },
        { 
            field: 'balance', 
            headerName: intl.formatMessage({ id: 'ENTRIES.APPLYPAYMENTS.MODAL.GRID.BALANCE' }),
            width: 130,
            cellRenderer: params => currencyFormatter(params.value),
            cellStyle: { textAlign: "right" }
        },
        { 
            field: 'apply', 
            width: 120,
            cellRenderer: params => params.node.rowPinned ? null : renderCheckBox(params, unCheckEntryPayment, params.node.data.balance <= 0 || params.node.data.hasPrizeRestriction),
            headerComponentFramework: CheckboxHeader,
            headerComponentParams: {
                headerName: intl.formatMessage({ id: "ENTRIES.APPLYPAYMENTS.MODAL.GRID.APPLY" }),
                onChange: (checked) => { 
                    setCheckAll(checked)
                    updateIncludeForAllEntries(checked)
                    setRecalPrizeMoney(!recalPrizeMoney)
                },
                checked: checkAll
            },
            resizable: false,
            cellStyle: { textAlign: "center" }
        },
        { 
            field: 'prizeMoneyPayment',
            headerName: intl.formatMessage({ id: "ENTRIES.APPLYPAYMENTS.MODAL.GRID.PRIZEMONEYPAYMENT" }),
            width: 130,
            cellRenderer: params => currencyFormatter(params.value),
            cellStyle: { textAlign: "right" },
            editable: params => params.data.apply && !params.data.hasPrizeRestriction && params.data.balance > 0, // Allow edit only if apply is true, there is no prize money restriction and entry has some debit balance
            cellStyle: params => getEditableCellStyles(params),
            valueSetter: numberValueSetter
        }
    ]

    // when entry include checkbox is unchecked. uncheck the header include if it is checked.
    const unCheckEntryPayment = (checked) => {
        if(!checked && checkAll){
            setCheckAll(false)
        }

        setRecalPrizeMoney(!recalPrizeMoney)
    }

    // update include value for all entries when header checkbox is checked or unchecked
    const updateIncludeForAllEntries = (checkAll) => {
        const rowData = getRowData(gridRef).map(row => ({ ...row, apply: row.balance > 0 && !row.hasPrizeRestriction ? checkAll : false }))
        updateAGGridWithoutFlickering({ current: {api: gridRef }}, rowData)
        setRecalPrizeMoney(!recalPrizeMoney)
        calculateTotals()
    }

    useEffect(() => {
        if(gridRef){
            const rowData = selectedEntries
            setAllRowData(rowData)
            updateAGGridWithoutFlickering({ current: {api: gridRef }}, rowData)
            calculateTotals()
        }
    }, [gridRef, selectedEntries])

    useEffect(() => {
        if(checkAll && gridRef){
            updateIncludeForAllEntries(checkAll)
        }
    }, [checkAll, gridRef, selectedEntries])

    useEffect(() => {
        if (gridRef) {
            const rowData = getRowData(gridRef) 
            const filteredRowData = []

            if (entriesWithBalanceOnly) { // Display entries with balance
                for (let row of rowData) { 
                    if (row.balance > 0) {
                        filteredRowData.push(row)
                    }
                }
            } else { // Display all entries
                for (let row of allRowData) {
                    // Find row in current display
                    let foundRow = rowData.find(r => r.entry_id == row.entry_id) 
                    if (foundRow) {
                        filteredRowData.push(foundRow)
                    } else {
                        filteredRowData.push({ ...row, apply: row.balance > 0 && !row.hasPrizeRestriction ? checkAll : false })
                    }
                }
            }
            // gridRef.setRowData(filteredRowData)
            updateAGGridWithoutFlickering({ current: {api: gridRef }}, filteredRowData)
            calculateTotals()
        }
    }, [entriesWithBalanceOnly, gridRef])

    useEffect(() => {
        if(gridRef){
            recalculatePrizeMoney(gridRef)
            calculateTotals()
        }
    }, [recalPrizeMoney, selectedEntries, gridRef])

    const getEditableCellStyles = (params) => {
        if(params.node.rowPinned){ // bottom pinned row
            return { textAlign: "right"}
        }

        if(params.data.apply){ // payment column is editable
            return { textAlign: "right", border: '1px solid rgb(202, 202, 202)' }
        }
        
        // payment column is not editable
        return { textAlign: "right", backgroundColor: '#f4f8fb', border: '1px solid rgb(202, 202, 202)' }
    }

    const onCellEditingStopped = (params) => {
        let newAmount = parseFloat(params.data.prizeMoneyPayment)
        if(newAmount > params.data.balance){
            alertDialog({message: `${intl.formatMessage({ id: 'APPLY.PAYMENT.TOOL.TAB.BUTTON.APPLY.PRIZE.MONEY.BALANCE.ERROR' })}`});
            newAmount = params.oldValue
        }

        let updatedRowData = getRowData(gridRef).map((rowdata) => {
            if(rowdata.entry_id === params.data.entry_id){
                rowdata.prizeMoneyPayment = parseFloat(newAmount)
            }

            return rowdata
        })

        updateAGGridWithoutFlickering({ current: {api: gridRef }}, updatedRowData)
        recalculatePrizeMoney(gridRef, false)
        calculateTotals()
    }

    const applyPrizeMoneyPayment = async(event) => {
        const appliedRows = []
        let rowData = getRowData(gridRef)
        for (let row of rowData) {
            if (row.apply && row.prizeMoneyPayment > 0) {
                row.prizeMoneyAmountToUse = row.prizeMoneyPayment // Use prize money amount that is available for use

                // Use only amount that will make balance 0 if unapplied prize money is greater than entry balance
                if (row.prizeMoneyAmountToUse >= row.balance) {
                    row.prizeMoneyAmountToUse = row.balance
                }

                if(row.availablePrizeMoney < row.prizeMoneyPayment){
                    alertDialog({message: `Prize money payment cannot be exceed available prize money for entry: ${row.number}`})
                    return
                }

                appliedRows.push(row)
            }
        }

        if(appliedRows.length == 0){
            alertDialog({message: 'No entries found with prize money payment.'})
            return
        }

        // Create request payload
        let data = {
            paymentAmount: 0, 
            autoApplyPrizeMoney: true,
            autoApplyPrizeMoneyPayment: true,
            addPayment: false,
            CardOwner: 0, 
            CardOwnerID: 0,
            paymentTypeId: 0,
            saveSource: "apply payment to multiple entries"
        }

        await applyPayment(event, appliedRows, data)
    }

    // calculate the total balance, prize money payment and payment
    const calculateTotals = () => {
        const rowData = getRowData(gridRef)
        let totalBalance = 0
        let totalPrizeMoneyPayment = 0

        rowData.forEach(row => {           
            totalBalance = addFloatingNumbers([row.balance, totalBalance], 2)
            if (row.apply) {   
                totalPrizeMoneyPayment = addFloatingNumbers([row.prizeMoneyPayment, totalPrizeMoneyPayment], 2)
            }
        })

        gridRef?.setPinnedBottomRowData([{
            balance: totalBalance,
            prizeMoneyPayment: totalPrizeMoneyPayment
        }])
    }

    return (
        <>
           {/* Entries with balance only */}
           <div className="row mb-0 mt-2 d-flex justify-content-end me-0">
                <div className='w-lg-25px form-check-sm form-check-custom ps-lg-3 py-2'>
                    <input
                        className='form-check-input'
                        type="checkbox"
                        id="prizeMoneyEntriesWithBalance" 
                        checked={entriesWithBalanceOnly}
                        onChange={e => setEntriesWithBalanceOnly(e.target.checked)} 
                    />
                </div>

                <label 
                    className='col-form-label fs-5 py-1 w-lg-200px'
                    htmlFor="entriesWithBalance"
                    data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.APPLYPAYMENTS.LABEL.ENTRIESWITHBALANCEONLY"
                >
                    { intl.formatMessage({ id: "ENTRIES.APPLYPAYMENTS.MODAL.LABEL.ENTRIESWITHBALANCE" }) }
                </label>
            </div>
            {/* Entries Grid */}
            <div style={containerStyle} className='row mb-2'>
                <div style={gridStyle} className="ag-theme-alpine ag-narrow-cell">
                    <AgGridReact
                        onGridReady={params => setGridRef(params.api)}
                        defaultColDef={defaultColDef}
                        rowData={[]}
                        rowStyle={{ fontSize: '13px', 'paddingLeft': 0, 'paddingRight': 0}}
                        columnDefs={columnDefs} 
                        suppressScrollOnNewData={true}
                        headerHeight={getExtraNarrowHeaderHeight}
                        rowHeight={getNarrowRowHeight}
                        stopEditingWhenCellsLoseFocus={true}
                        singleClickEdit={true}
                        getRowStyle={params => {
                            if (params.node.rowPinned) {
                                return { fontWeight: "bold" };
                            }
                        }}
                        onCellEditingStopped={onCellEditingStopped}
                    />
                </div>
            </div>

            {/* Footer */}
            <div className='card-footer d-flex justify-content-end me-6 align-items-center'>
                {/* Info label */}
                <div className='row col-9'>
                    <div className='d-flex align-items-center'>
                        <div className='me-2' style={{ width:'35px',height:'7px', backgroundColor: '#ff8c81' }}></div>
                        <label className='col-form-label fs-5 py-0'>
                            {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.ERROR' })}
                        </label>
                    </div>

                    <div className='d-flex align-items-center'>
                        <div className='me-2' style={{ width:'35px',height:'7px', backgroundColor: '#dba707' }}></div>
                        <label className='col-form-label fs-5 py-0'>
                            {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.WARNING' })}
                        </label>
                    </div>
                </div>
                
                <button type='button' className='btn btn-sm btn-secondary me-4 fw-bold' style={{ marginLeft: "auto" }} onClick={handleClose} id='ApplyPaymentCloseButton'>
                    {intl.formatMessage({ id: 'FORM.INPUT.COMMON.BUTTON.CLOSE' })}
                </button>
                <button 
                    type='button' 
                    className='btn btn-sm btn-dark fw-bold text-uppercase' 
                    onClick={applyPrizeMoneyPayment} 
                    id='ApplyPrizeMoneySubmitButton'
                >
                    <span className="spinner-border spinner-border-sm d-none me-2"></span>
                    {intl.formatMessage({ id: 'APPLY.PAYMENT.TOOL.TAB.BUTTON.APPLY.PRIZE.MONEY' })}
                </button>
            </div>
        </>
    );
}

export { PrizeMoneyTab }