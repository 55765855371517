import { useState, useEffect, useRef, useImperativeHandle, memo, forwardRef } from 'react'
import Flatpickr from "react-flatpickr";
import axios from 'axios';


// Assets
import "flatpickr/dist/themes/material_blue.css";
import USEFLogo from '../../../_metronic/assets/CustomIcons/USEF_Icon.jpeg'
import ECLogo from '../../../_metronic/assets/CustomIcons/EC_icon.png'
import FEILogo from '../../../_metronic/assets/CustomIcons/fei_icon.jpeg'
import JALogo from '../../../_metronic/assets/CustomIcons/JA.jpeg'
import NORCALLogo from '../../../_metronic/assets/CustomIcons/norcal_icon.jpeg'
import PCHALogo from '../../../_metronic/assets/CustomIcons/pcha_icon.jpeg'
import USHJALogo from '../../../_metronic/assets/CustomIcons/USHJA_icon.jpg'
import { roundFloatingNumber } from './SglFunctions';

export const renderCheckBox = (params, callback = null, disabled = false) => {
    return (
        <input
            name="ag-grid-checkbox-renderer"
            type="checkbox"
            onChange={(e) => { 
                const result = params.node.setDataValue(params.column.colId, e.target.checked)
                if (callback && typeof callback === 'function') {
                    callback(e.target.checked)
                }
                return result
            }}
            checked={params.value}
            disabled={disabled}
        />
    )
}

export const renderIcon = (params) => {
    switch (params.value?.toUpperCase()) {
        case 'USEF': case 'SAFESPORT': return <img className='w-20px' src={USEFLogo} />;
        case 'EC': case 'EC-SAFESPORT': case 'EC-Coach': return <img className='w-20px' src={ECLogo} />;
        case 'FEI': return <img className='w-20px h-75' src={FEILogo} />;
        case 'JA': return <img className='w-20px' src={JALogo} />;
        case 'NORCAL': return <img className='w-20px' src={NORCALLogo} />;
        case 'PCHA': return <img className='w-20px' src={PCHALogo} />;
        case 'USHJA': return <img className='w-20px' src={USHJALogo} />;
        default: return ""
    }
}

export const renderValidInValidDot = (params) => {
    switch(params.value){
        case 'valid': return <i className="fa-solid fa-circle py-3 text-success"></i>
        case 'invalid': return <i className="fa-solid fa-circle py-3 text-danger"></i>
        default: return ""
    }
}

export const SaveModifiedColumnDefinitions = (updated_colDef, area, currentUser, customer_id, action='column updated', other_settings={}) =>{
    axios.post( process.env.REACT_APP_NEST_API_URL + '/utility/column_definitions', {
        params: {
            reference: area ,
            user_id: currentUser?.id,
            customer_id: customer_id,
            columns_new_settings: updated_colDef,
            other_settings,
            action
        }
    })

    if(action === 'column updated'){
        localStorage.setItem(area, JSON.stringify(updated_colDef))
    }else if(action === 'settings updated'){
        // Loop over the parsed JSON object and save each key-value pair in localStorage
        for (const [settingKey, settingValue] of Object.entries(other_settings)) {
            if(settingKey=="visible_rows"){
                localStorage.setItem(`${area}_${settingKey}`, settingValue);
            }else{
                localStorage.setItem(settingKey, settingValue);
            }
        } 
    } 
}

export const overwriteColumnDefinitions = (custom_column_definitions, original_columnDefs) => {
    let overwrite_colDefs = []
    let grid_def_fields = ['filter', 'sortable', 'minWidth', 'maxWidth', 'resizable', 'editable', 'headerName', 'cellStyle', 'cellClass']; // grid settings that we dont need to overwrite

    if(custom_column_definitions && custom_column_definitions != undefined && custom_column_definitions.length == original_columnDefs.length && custom_column_definitions.every((element_1) => original_columnDefs.some((element_2) => element_1.field === element_2.field))){
        // overwrite and merge custom column definitions on original column definitions
        let merge_objects = original_columnDefs.map(t1 => ({...t1, ...custom_column_definitions?.find(t2 => t2.field == t1.field)}))
        // again overwrite merged colDefs to get same order of columns, modified by the user
        overwrite_colDefs = custom_column_definitions.map(t1 => ({...t1, ...merge_objects?.find(t2 => t2.field == t1.field)}))
    
        // looping over overwrite_colDefs to set grid setting from original column defs instead of overwriting it
        for(let overwrite_colDef of overwrite_colDefs){
            let original_column = original_columnDefs.find(col => col.field == overwrite_colDef.field)
            if(original_column){
                for(let grid_field of grid_def_fields){
                    // keeping original values from grid settings for few fields
                    overwrite_colDef[grid_field] = original_column[grid_field]
                    if(grid_field == 'sortable' && original_column[grid_field] == false){
                        overwrite_colDef['sort'] = ''
                    }
                }
            }
        }
    }

    return overwrite_colDefs
}

export const overwriteStaggerGridColumnDefinitions = (custom_column_definitions, original_columnDefs) => { //overwrites column definitions for stagger grid
    let overwrite_colDefs = []
    let grid_def_fields = ['filter', 'sortable', 'minWidth', 'maxWidth', 'resizable', 'editable']; // grid settings that we dont need to overwrite

    if(custom_column_definitions && custom_column_definitions != undefined && custom_column_definitions.length == original_columnDefs.length && custom_column_definitions.every((element_1) => original_columnDefs.some((element_2) => element_1.field === element_2.field))){
        // overwrite and merge custom column definitions on original column definitions
        let merge_objects = original_columnDefs.map(t1 => ({...t1, ...custom_column_definitions?.find(t2 => t2.field == t1.field)}))
        // again overwrite merged colDefs to get same order of columns, modified by the user. also overwrite hide/show status based on custom column definitions
        overwrite_colDefs = custom_column_definitions.map(t1 => ({...t1, ...merge_objects?.find(t2 => t2.field == t1.field), hide: t1.hasOwnProperty('hide') ? t1.hide : false}))
    
        // looping over overwrite_colDefs to set grid setting from original column defs instead of overwriting it
        for(let overwrite_colDef of overwrite_colDefs){
            let original_column = original_columnDefs.find(col => col.field == overwrite_colDef.field)
            if(original_column){
                for(let grid_field of grid_def_fields){
                    // keeping original values from grid settings for few fields
                    overwrite_colDef[grid_field] = original_column[grid_field]
                    if(grid_field == 'sortable' && original_column[grid_field] == false){
                        overwrite_colDef['sort'] = ''
                    }
                }
            }
        }
    }

    return overwrite_colDefs
}

// for views that need to show narrow lines
export const getStandardHeaderHeight = 49
export const getNarrowRowHeight = 32
export const getNarrowHeaderHeight = 35
export const getExtraNarrowHeaderHeight = 26
export const getExtraNarrowRowHeight = 25
export const editableCellStyle = { backgroundColor: '#f4f8fb', border: '1px solid rgb(202, 202, 202)' }
export const getAllColumnDefinitions = async (user_id, customer_id, action) => {
    if (customer_id == -1) { return }

    try {
        const response = await axios.get( process.env.REACT_APP_NEST_API_URL + '/utility/all_column_definitions', {
            params: {
                user_id: user_id,
                customer_id: customer_id,
        }})

        if(response.data.success) {
            response.data.user_column_definitions.forEach((key) => {
                switch(action){
                    case "set":
                        localStorage.setItem(key.reference, key.column_definitions)
                        if(key.other_settings){
                            const otherSettings = JSON.parse(key.other_settings);
                            // Loop over the parsed JSON object and save each key-value pair in localStorage
                            for (const [settingKey, settingValue] of Object.entries(otherSettings)) {
                                if(settingKey=="visible_rows"){ // SK - This should be the correct way to save or remove other settings - Only updated it for this particular setting for now.
                                    localStorage.setItem(`${key.reference}_${settingKey}`, settingValue);
                                }else{
                                    localStorage.setItem(settingKey, settingValue);
                                }
                            }
                        }
                        break;
                    case "remove": 
                        localStorage.removeItem(key.reference)
                        if(key.other_settings){
                            const otherSettings = JSON.parse(key.other_settings);
                            // Loop over the parsed JSON object and save each key-value pair in localStorage
                            for (const [settingKey, settingValue] of Object.entries(otherSettings)) {
                                if(settingKey=="visible_rows"){ // SK - This should be the correct way to save or remove other settings - Only updated it for this particular setting for now.
                                    localStorage.removeItem(`${key.reference}_${settingKey}`, settingValue);
                                }else{
                                    localStorage.removeItem(settingKey)
                                }
                            }
                        }
                        break;
                }
            })

            // To let react know local storage has changed.
            // See useStorage Hook for more details.
            window.dispatchEvent(new Event("custom-col-defs-updated-event"))
        }
    } catch (error) {
        console.log('Error Getting Column Definitions', error)
    }
}

export const getAllRecordIds = (area, params, columnDefs) => {
    let obj = columnDefs.find(o => o.field.includes("_id"));
    let field = obj.field;
    let rowData = [];
    params.api.forEachNode(node => rowData.push(node.data));
    let idsArray = rowData.map(row => row[field]);
    return idsArray
}

// Get only non system report ids in case of reports when the user does not have access to edit system reports
export const getNonSystemReportIds = (area, params, columnDefs) => {
    let obj = columnDefs.find(o => o.field.includes("_id"));
    let field = obj.field;
    let rowData = [];

    // push only non system reports
    params.api.forEachNode(node => {
        const isSystemReport = node.data.is_system_report;
        if (isSystemReport == 0) {
            rowData.push(node.data)
        }
    });

    let idsArray = rowData.map(row => row[field]);
    return idsArray
}

// In case of reports and the user has no access to edit non system reports, the current row index will be wrong because the main list contains both system and non system reports
// So we need to get the row index of selected report from non system reports array
export const getRowIndexForNonSystemReport = (params) => {
    let selectedNode = params.api.getSelectedNodes()[0]
    const rowData = [];
    // Get all nodes
    params.api.forEachNode(node => {
        const isSystemReport = node.data.is_system_report;
        if (isSystemReport == 0) {
            rowData.push(node.data)
        }
    });

    const selectedNodeSglId = selectedNode.data.sgl_id;

    // Find that sgl id in the row data
    const rowIndex = rowData.findIndex(row => row.sgl_id == selectedNodeSglId);
    return rowIndex;
}

export const getSelectedRowIndex = (params) => {
    let node = params.api.getSelectedNodes()[0]
    return node.rowIndex
}

export const clearSessionStorageByKeysPrefix = (key_prefixes_list) => {

    for (let [key, value] of Object.entries(sessionStorage)) {
        // Check if the key matches any of the provided key prefixes
        const matchesPrefix = key_prefixes_list.some(prefix => key.startsWith(prefix));
        if(matchesPrefix){
            sessionStorage.removeItem(key)
        }
    }
}

// Default column definitions for AgGrid React
export const defaultColDef = {
    suppressMenu: true,
    cellStyle: params => {
        if (typeof params.value === 'number') {
            return {textAlign: 'right'};
        } 
        return {textAlign: 'left'}
    }
}

// Get Ag-grid data
export const getRowData = (gridRef) => {
    if(!gridRef) { 
        console.warn("Grid Ref not initialized.")
        return [] 
    }

    const rowData = []
    gridRef.forEachNode(node => rowData.push(node.data));
    return rowData
}

export const agGridPositiveNumberSetter = (params, columnName, keepDecimal=true) => { 
    const newNumber = Number(params.newValue) // Typecast to Number
    params.data[columnName] = isNaN(newNumber) ? 0 : keepDecimal ? parseFloat(newNumber.toFixed(2)) : Math.round(newNumber) // Don't allow invalid numbers/characters and fractions
    if (params.data[columnName] < 0) params.data[columnName] *= -1 // Don't allow negative
    return true
}

// Get Ag-grid data
export const updateAGGridWithoutFlickering = (gridRef, rows, identifierProperty = 'sgl_id', columnsWithDelta = [], deltaColumnsRenderDelay = 200) => {
    if(!gridRef) { 
        console.warn("Grid Ref not initialized.")
    }

    if (rows.length === 0) {
        gridRef.current.api.setRowData(rows)
    } else {
        // Remove all the rows from existing row data first that are not present in new row data
        let existingRows = []
        gridRef.current.api.forEachNode(function (node) { existingRows.push(node); });
        for (const rowIndex in existingRows) { 
            if (rows.findIndex(r => r[identifierProperty] == existingRows[rowIndex].data[identifierProperty]) < 0) {
                gridRef.current.api.applyTransaction({ remove: [existingRows[rowIndex].data] })
            }
        }

        //Add/Update data received from update in stagger grid
        let deltaRowNodes = {};
        for(const rowIndex in rows){
            existingRows = []
            gridRef.current.api.forEachNode(function (node) { existingRows.push(node); });

            //check if matching trip already present
            let matchingRowIndex = existingRows.findIndex(r => r.data[identifierProperty] == rows[rowIndex][identifierProperty]);

            if(matchingRowIndex >= 0){ //Trip already present in grid
                let rowNode = existingRows[matchingRowIndex]; //gridRef.current.api.getDisplayedRowAtIndex(matchingRowIndex)
                let columnsToFlash = {};
                for(let column of columnsWithDelta){ //replace old values to retain their orginal state
                    if(column.includes('.')){ //TeamxClass.score
                        let nameParts = column.split('.');
                        for(let level of nameParts){
                            columnsToFlash[level] = rowNode.data[column][level];
                            break;
                        }
                    }
                    else{
                        columnsToFlash[column] = rowNode.data[column];
                    }
                }
                
                rowNode.data = {...structuredClone(rows[rowIndex]), ...columnsToFlash }
                if(matchingRowIndex != rowIndex){
                    let transactionResult = gridRef.current.api.applyTransaction({ 
                    remove: [rowNode.data], //remove from old index
                    add: [rowNode.data], addIndex: parseInt(rowIndex) //add at current index which is correct index for row
                    }) 
                    rowNode = transactionResult.add[0]
                }
                else{
                    gridRef.current.api.applyTransaction({ 
                        update: [rowNode.data], //update row data
                    })
                }

                //Add row node to update in list so that its delta tied columns can be updated later
                deltaRowNodes[rowIndex] = rowNode;
            }
            else{ //Trip not found in existing data
                gridRef.current.api.applyTransaction({ add: [rows[rowIndex]], addIndex: parseInt(rowIndex) })
            }
        }

        //Replace values in columns that need to show delta after delay due to issue with delta when applying changes directly via applyTransaction for whole row
        if(columnsWithDelta.length > 0){
            //Apply all delta row updates at once to fix issue with missing delta during rendering animation for rows
            new Promise(r => setTimeout(r, deltaColumnsRenderDelay)).then(res => {
                for(let rowIndex in deltaRowNodes){
                    for(let column of columnsWithDelta){
                        let value = '';
                        if(column.includes('.')){ //TeamxClass.score
                            let nameParts = column.split('.');
                            for(let level of nameParts){ //Replace data from rows object for whole tree for multi-level json in order to replace latest state
                                deltaRowNodes[rowIndex].data[level] = rows[rowIndex][level]
                                break;
                            }
                        }
                        else{
                            value = rows[rowIndex][column]
                            deltaRowNodes[rowIndex].setDataValue(column, value);
                        }
                    }
                }
            })
        }
    }
}

// If value is non-numeric OR (negative and its not alowed) then return zero
export const numberValueSetter = (params) => {
    const newValue = params.newValue;
    if (isNaN(newValue) || newValue === '' || (!params?.allowNegative && newValue < 0)) {
        params.data[params.colDef.field] = 0;
    } else {
        params.data[params.colDef.field] = roundFloatingNumber(Number(newValue), 2);
    }
    return true; // indicates that the value has been set successfully
}