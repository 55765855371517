import { KTSVG } from '../../../../_metronic/helpers'
import { useOutputContext } from '../../output-listing/OutputListingContext'
import {useIntl} from 'react-intl'
import { useAppSelector } from '../../../redux/hooks'
import { useEffect, useRef, useState } from 'react'
import { reactSelectAsMenuStyles } from '../../sgl-utils/fieldControls'
import Select, { components } from 'react-select';

const QuickSearch = () => {
    const intl = useIntl()
    const { setQuickSearch } = useOutputContext()
    const { outputGrid, isSearchOnSubset, setIsSearchOnSubset, handleToggleChange } = useOutputContext()
    const quickSearches = useAppSelector(state => state.showCompanyMetadata.quickSearches)
    const [ quickSearchOptions, setQuickSearchOptions] = useState(null)

    const entriesQuickSearches = [
        {
            searchCriteria: 'Entry Balance Greater Than Zero',
            showProgress: false
        },
        {
            searchCriteria: 'Entry Owes Money',
            showProgress: false
        },
        {
            searchCriteria: 'Entries Not Picked Up',
            showProgress: false
        },
        {
            searchCriteria: 'Entries Paid Online',
            showProgress: false
        },
        {
            searchCriteria: 'Entries Picked Up',
            showProgress: false
        },
        {
            searchCriteria: 'Find Competing Entries',
            showProgress: false
        },
        {
            searchCriteria: 'Find Entries From FEI Import',
            showProgress: false
        },
        {
            searchCriteria: 'Find Entries With Non-zero Balance',
            showProgress: false
        },
        {
            searchCriteria: 'Find Entries With Past Balances',
            showProgress: false
        },
        {
            searchCriteria: 'Find Suspended',
            showProgress: false
        },
        {
            searchCriteria: 'Missing SGL Contact Info',
            showProgress: false
        },
        {
            searchCriteria: 'Orphaned Trips',
            showProgress: false
        },
        {
            searchCriteria: 'Outstanding Prize Money',
            showProgress: false
        },
        {
            searchCriteria: 'People With EC Membership Name Mismatch',
            showProgress: false
        },
        {
            searchCriteria: 'People With FEI Membership Name Mismatch',
            showProgress: false
        },
        {
            searchCriteria: 'Show Owes Money',
            showProgress: false
        },
        {
            searchCriteria: 'Supply Ordered From Web',
            showProgress: false
        },
        {
            searchCriteria: 'With Pending Supply Orders',
            showProgress: false
        }
    ]    

    const classesQuickSearches = [
        {
            searchCriteria: 'Classes From FEI Import',
            showProgress: false
        },
        {
            searchCriteria: 'Classes Missing Results',
            showProgress: false
        },
        {
            searchCriteria: 'Classes With No Class Rule',
            showProgress: false
        },
        {
            searchCriteria: 'Classes With No Placing Template',
            showProgress: false
        },
        {
            searchCriteria: 'Classes With No Results',
            showProgress: false
        },
        {
            searchCriteria: 'Classes With Verified Results',
            showProgress: false
        },
        {
            searchCriteria: 'Equitation Classes',
            showProgress: false
        },
        {
            searchCriteria: 'Hunter Classes',
            showProgress: false
        },
        {
            searchCriteria: 'Jumper Classes',
            showProgress: false
        },
        {
            searchCriteria: 'Outstanding Prize Money',
            showProgress: false
        },
        {
            searchCriteria: 'Web Add/Drop Disabled',
            showProgress: false
        }
    ]    

    const showsQuickSearches = [
        {
            searchCriteria: 'Shows Last Year',
            showProgress: false
        },
        {
            searchCriteria: 'Shows This Year',
            showProgress: false
        }
    ]

    const divisionQuickSearches = [
        {
            searchCriteria: 'Divisions That End Today',
            showProgress: false
        },
        {
            searchCriteria: 'Divisions That End Tomorrow',
            showProgress: false
        }
    ]

    const peopleQuickSearches = [ // RTO
        {
            searchCriteria: "Duplicate RTO's",
            showProgress: false
        },
        {
            searchCriteria: 'EC Membership Name Mismatch',
            showProgress: false
        },
        {
            searchCriteria: 'FEI Membership Name Mismatch',
            showProgress: false
        },
        {
            searchCriteria: 'Unapplied Prize Money',
            showProgress: false
        }
    ]

    const paymentsQuickSearches = []

    const horsesQuickSearches = [
        {
            searchCriteria: 'FEI Membership Name Mismatch',
            showProgress: false
        },
        {
            searchCriteria: 'Horses Not Associated With Any Entries',
            showProgress: false
        }
    ]    

    const feesQuickSearches = [
        {
            searchCriteria: 'Need To Enable For Web Ordering',
            showProgress: false 
        }
    ]

    const circuitsQuickSearches = [
        {
            searchCriteria: 'Circuits Last Year',
            showProgress: false
        },
        {
            searchCriteria: 'Circuits This Year',
            showProgress: false
        },
    ]

    // Quick Searches coming from Backend (dependent on ACL/Privileges/Prefs)
    peopleQuickSearches.push(...quickSearches.people)
    paymentsQuickSearches.push(...quickSearches.payments)
    entriesQuickSearches.push(...quickSearches.entries)

    // Sort the arrays after appending
    peopleQuickSearches.sort((a, b) => a.searchCriteria.localeCompare(b.searchCriteria))
    paymentsQuickSearches.sort((a, b) => a.searchCriteria.localeCompare(b.searchCriteria))
    entriesQuickSearches.sort((a, b) => a.searchCriteria.localeCompare(b.searchCriteria))

    const getQuickSearchOptions = () => {
        let quickSearches = []
        if(outputGrid?.apiEndPoint?.includes('classes')){
            quickSearches = classesQuickSearches
        }else if(outputGrid?.apiEndPoint?.includes('entries')){
            quickSearches = entriesQuickSearches
        }else if(outputGrid?.apiEndPoint?.includes('shows')){
            quickSearches = showsQuickSearches
        }else if(outputGrid?.apiEndPoint?.includes('divisions')){
            quickSearches = divisionQuickSearches
        }else if(outputGrid?.apiEndPoint?.includes('people')){
            quickSearches = peopleQuickSearches
        }else if(outputGrid?.apiEndPoint?.includes('payments')){
            quickSearches = paymentsQuickSearches
        }else if(outputGrid?.apiEndPoint?.includes('horses')){
            quickSearches = horsesQuickSearches
        }else if(outputGrid?.apiEndPoint?.includes('fees') && !outputGrid?.apiEndPoint?.includes('master-fees')){
            quickSearches = feesQuickSearches
        }else if(outputGrid?.apiEndPoint?.includes('circuits')){
            quickSearches = circuitsQuickSearches
        }
        
        let quickSearchOptions = quickSearches.map((params,i) =>{
            return {label: intl.formatMessage({id:`Toolbar.Label.QuickSearch.${params.searchCriteria.replace(/\s/g, '')}`}), value: params} 
        })
        setQuickSearchOptions(quickSearchOptions)
    }

    const lastKeyPressed = useRef(null);
    const selectRef = useRef(null);
    const handleMouseOver = (e) => {
        if(selectRef){
            selectRef.current.focus();
        }
    }

    const handleMouseLeave = (e) => {
        if(selectRef){
            selectRef.current.blur();
        }
    }

    const handleOnChange = (e) => {
        if(lastKeyPressed && lastKeyPressed.current == 'Tab') { return }
        let params = e.value
        setQuickSearch({ ...params })
        if (selectRef && selectRef.current) {
            selectRef.current.blur();
            selectRef.current.select.clearValue();
        }
        lastKeyPressed = ''
    }

    const handleKeyDown = (e) => {
        if(e.key == 'Tab'){
            lastKeyPressed = e.key
        }
    }

    useEffect(() => {
        getQuickSearchOptions()
    }, [outputGrid?.area, quickSearches])



    useEffect(() => {
        const savedPref = JSON.parse(sessionStorage.getItem('searchOnSubset'));
        if (savedPref !== null) {
            setIsSearchOnSubset(savedPref);
        }
    }, []);

    const CustomMenuList = (props) => {
        return (
            <div>
                {/* Toggle Switch */}
                <div className="d-flex align-items-center justify-content-between p-2" style={{ borderBottom: '1px solid #ddd', background: '#fff' }}>
                    <span className="fw-bold">Search on Subset</span>
                    <div 
                        className={`toggle-switch ${isSearchOnSubset ? 'active' : ''}`} 
                        onClick={handleToggleChange}
                        style={{
                            width: "34px",
                            height: "16px", 
                            borderRadius: "16px",
                            backgroundColor: isSearchOnSubset ? "#4CAF50" : "#ccc",
                            position: "relative",
                            cursor: "pointer",
                            transition: "background-color 0.3s ease",
                            display: "flex",
                            alignItems: "center",
                            padding: "2px",
                            marginLeft: "5px"
                        }}
                    >
                        <div 
                            style={{
                                width: "12px",
                                height: "12px",
                                backgroundColor: "#fff",
                                borderRadius: "50%",
                                position: "absolute",
                                left: isSearchOnSubset ? "18px" : "2px",  
                                transition: "left 0.3s ease"
                            }}
                        />
                    </div>
                </div>
    
                {/* Render Default Options */}
                <components.MenuList {...props}>
                    {props.children}
                </components.MenuList>
            </div>
        );
    };
    

    return (
        <div className='d-flex justify-content-end me-3' data-kt-user-table-toolbar='base'>
            <div className="">
                {/* <a href="#" className="btn btn-secondary fw-bolder px-4" data-kt-menu-trigger="{default:'click', lg: 'hover'}" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
                    { intl.formatMessage({id: 'Toolbar.Label.QuickSearch'}) }
                    <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-2 me-0 mx-1'  />
                </a>
                
                { outputGrid.area === "Classes" && 
                    <div className="menu menu-sub menu-sub-dropdown menu-column menu-title-dark fw-semibold py-0 fs-6 mw-lg-250px" data-kt-menu="true" data-kt-element="theme-mode-menu">
                        { classesQuickSearches.map((params,i) =>
                            <div key={i} className="menu-item px-2 my-0">
                                <a onClick={() => setQuickSearch({ ...params })} className="menu-link px-3 py-2 active" data-kt-element="mode" data-kt-value="light">
                                    <span className="menu-title">
                                        { intl.formatMessage({id:`Toolbar.Label.QuickSearch.${params.searchCriteria.replace(/\s/g, '')}` }) }
                                    </span>
                                </a>
                            </div>
                        )}        
                    </div>
                } */}
                <div>
            {/* Search Dropdown */}
                {
                    quickSearchOptions?.length > 0 ?
                    <div onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
                    <Select
                        ref={selectRef}
                        theme={(theme) => ({ ...theme, borderRadius: 0 })}
                        options={quickSearchOptions}
                        onChange={handleOnChange}
                        placeholder="Quick Search"
                        isSearchable={false}
                        styles={reactSelectAsMenuStyles}
                        openMenuOnFocus={true}
                        onKeyDown={handleKeyDown}
                        components={{ MenuList: CustomMenuList }} 
                    />
                    </div> :
                    <a href="#" className="btn btn-secondary fw-bolder px-4" data-kt-menu-trigger="{default:'click', lg: 'hover'}" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
                    { intl.formatMessage({id: 'Toolbar.Label.QuickSearch'}) }
                    <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-2 me-0 mx-1'  />
                    </a>
                }
        </div>
                {/* {
                    outputGrid.area === "Entries" &&
                    <div className="menu menu-sub menu-sub-dropdown menu-column menu-title-dark fw-semibold py-0 fs-6 mw-lg-325px" data-kt-menu="true" data-kt-element="theme-mode-menu" style={{ maxHeight: '525px', overflowY: 'auto' }}>
                        { entriesQuickSearches.map((params,i) =>
                            <div key={i} className="menu-item px-2 my-0">
                                <a onClick={() => setQuickSearch({ ...params })} className="menu-link px-3 py-1 active" data-kt-element="mode" data-kt-value="light">
                                    <span className="menu-title">
                                        { intl.formatMessage({id:`Toolbar.Label.QuickSearch.${params.searchCriteria.replace(/\s/g, '')}` }) }
                                    </span>
                                </a>
                            </div>
                        )}
                    </div>
                }
                {
                    outputGrid.area === "Shows" &&
                    <div className="menu menu-sub menu-sub-dropdown menu-column menu-title-dark fw-semibold py-0 fs-6 mw-lg-200px" data-kt-menu="true" data-kt-element="theme-mode-menu" style={{ maxHeight: '525px', overflowY: 'auto' }}>
                    { showsQuickSearches.map((params,i) =>
                        <div key={i} className="menu-item px-2 my-0">
                            <a onClick={() => setQuickSearch({ ...params })} className="menu-link px-3 py-1 active" data-kt-element="mode" data-kt-value="light">
                                <span className="menu-title">
                                    { intl.formatMessage({id:`Toolbar.Label.QuickSearch.${params.searchCriteria.replace(/\s/g, '')}` }) }
                                </span>
                            </a>
                        </div>
                    )}
                    </div>
                }
                {
                    outputGrid.area === "Divisions" &&
                    <div className="menu menu-sub menu-sub-dropdown menu-column menu-title-dark fw-semibold py-0 fs-6 mw-lg-250px" data-kt-menu="true" data-kt-element="theme-mode-menu" style={{ maxHeight: '525px', overflowY: 'auto' }}>
                    { divisionQuickSearches.map((params,i) =>
                        <div key={i} className="menu-item px-2 my-0">
                            <a onClick={() => setQuickSearch({ ...params })} className="menu-link px-3 py-1 active" data-kt-element="mode" data-kt-value="light">
                                <span className="menu-title"> 
                                    { intl.formatMessage({id:`Toolbar.Label.QuickSearch.${params.searchCriteria.replace(/\s/g, '')}` }) }
                                </span>
                            </a>
                        </div>
                    )}
                    </div>
                }
                {
                    outputGrid.area === "People" &&
                    <div className="menu menu-sub menu-sub-dropdown menu-column menu-title-dark fw-semibold py-0 fs-6 mw-lg-250px" data-kt-menu="true" data-kt-element="theme-mode-menu" style={{ maxHeight: '525px', overflowY: 'auto' }}>
                    { peopleQuickSearches.map((params,i) =>
                        <div key={i} className="menu-item px-2 my-0">
                            <a onClick={() => setQuickSearch({ ...params })} className="menu-link px-3 py-1 active" data-kt-element="mode" data-kt-value="light">
                                <span className="menu-title"> 
                                    { intl.formatMessage({id:`Toolbar.Label.QuickSearch.${params.searchCriteria.replace(/\s/g, '')}` }) }
                                </span>
                            </a>
                        </div>
                    )}
                    </div>
                }
                {
                    outputGrid.area === "Payments" &&
                    <div className="menu menu-sub menu-sub-dropdown menu-column menu-title-dark fw-semibold py-0 fs-6 mw-lg-250px" data-kt-menu="true" data-kt-element="theme-mode-menu" style={{ maxHeight: '525px', overflowY: 'auto' }}>
                    { paymentsQuickSearches.map((params,i) =>
                        <div key={i} className="menu-item px-2 my-0">
                            <a onClick={() => setQuickSearch({ ...params })} className="menu-link px-3 py-1 active" data-kt-element="mode" data-kt-value="light">
                                <span className="menu-title"> 
                                    { intl.formatMessage({id:`Toolbar.Label.QuickSearch.${params.searchCriteria.replace(/\s/g, '')}` }) }
                                </span>
                            </a>
                        </div>
                    )}
                    </div>
                }
                {
                    outputGrid.area === "Horses" &&
                    <div className="menu menu-sub menu-sub-dropdown menu-column menu-title-dark fw-semibold py-0 fs-6 mw-lg-300px" data-kt-menu="true" data-kt-element="theme-mode-menu" style={{ maxHeight: '525px', overflowY: 'auto' }}>
                    { horsesQuickSearches.map((params,i) =>
                        <div key={i} className="menu-item px-2 my-0">
                            <a onClick={() => setQuickSearch({ ...params })} className="menu-link px-3 py-1 active" data-kt-element="mode" data-kt-value="light">
                                <span className="menu-title"> 
                                    { intl.formatMessage({id:`Toolbar.Label.QuickSearch.${params.searchCriteria.replace(/\s/g, '')}` }) }
                                </span>
                            </a>
                        </div>
                    )}
                    </div>
                }
                {
                    outputGrid.area === "Fees" &&
                    <div className="menu menu-sub menu-sub-dropdown menu-column menu-title-dark fw-semibold py-0 fs-6 mw-lg-300px" data-kt-menu="true" data-kt-element="theme-mode-menu" style={{ maxHeight: '525px', overflowY: 'auto' }}>
                    { feesQuickSearches.map((params,i) =>
                        <div key={i} className="menu-item px-2 my-0">
                            <a onClick={() => setQuickSearch({ ...params })} className="menu-link px-3 py-1 active" data-kt-element="mode" data-kt-value="light">
                                <span className="menu-title"> 
                                    { intl.formatMessage({id:`Toolbar.Label.QuickSearch.${params.searchCriteria.replace(/\s/g, '')}` }) }
                                </span>
                            </a>
                        </div>
                    )}
                    </div>
                } */}
            </div>
        </div>
    )
}

export default QuickSearch
