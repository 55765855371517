import { useIntl } from 'react-intl'
import { useEffect, useMemo, useRef, useState } from 'react';
import axios from 'axios';
import { useAppSelector } from '../../../../redux/hooks';
import { getNarrowHeaderHeight, getNarrowRowHeight, renderCheckBox } from '../../../../modules/sgl-utils/agGridHelpers';
import { AgGridReact } from 'ag-grid-react';
import { useCurrencyFormatter } from '../../../../modules/sgl-utils/Formatters';
import { addFloatingNumbers, getArrayFromObject, multiSort } from '../../../../modules/sgl-utils/SglFunctions';
import { useFlashAlert } from '../../../../modules/sgl-utils/DialogsProvider';
import { usePaymentBatchContext } from '../../PaymentBatchContext';
const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const EntriesGrid = ({ entriesList, setEntriesList, selectedRTO, setSelectedRTO, checkoutEntries }) => {
    const intl = useIntl();
    const containerStyle = useMemo(() => ({ width: '100%', height: '48vh' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%'}), []);
    const customerID = useAppSelector(state => state.showCompany.company_id);
    const currencyFormatter = useCurrencyFormatter()
    const { closePaymentBatchDetail } = usePaymentBatchContext()

    let gridRef = useRef()
    const defaultColDef = useMemo(() => {
        return {
          minWidth: 110,
          width: 110,
          resizable: true,
          sortable: false, //retain original sort
          suppressMenu: true,
          suppressMovable: true,
          cellStyle: function(params) {
            if (typeof params.value === 'number') {
                return {textAlign: 'center'};
            } else {
              return {textAlign: 'left'};
            }
          },
          wrapHeaderText: true,
          autoHeaderHeight: true,
          headerClass: "ag-center-aligned-header",
          singleClickEdit: true,
        };
    }, []);

    const columnDefs = [
        { 
            field: 'include',
            cellRenderer: params => params.node.rowPinned ? null : renderCheckBox(params, () => {
                selectEntry(params)
            }, params?.node?.data?.isPayable == 0 || (!params?.node?.data?.include && selectedRTO?.creditNotUsed <= 0)), // disable checkbox if entry is not payable or the RTO has no remaining credit for entry payoff
            maxWidth: 50,
            headerName: '',
            cellStyle: ({textAlign: 'center'})
        },
        { 
            field: 'Show.show_name', 
            headerName: intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.CHECKOUT.ENTRIES.TAB.GRID.COL.SHOW" }), 
            width: 150,
        },
        { 
            field: 'number', 
            headerName: intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.CHECKOUT.ENTRIES.TAB.GRID.COL.ENTRY" }), 
            maxWidth: 80,
        },
        { 
            field: 'horse', 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CHECKOUT.ENTRIES.TAB.GRID.COL.HORSE' }),
            minWidth: 100,
            flex: 1,
        },
        { 
            field: 'balance', 
            headerName: intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.CHECKOUT.ENTRIES.TAB.GRID.COL.BALANCE" }),
            maxWidth: 95,
            cellRenderer: params => currencyFormatter(params.value),
            cellStyle: { textAlign: 'right' }
        },
        { 
            field: 'amountApplied', 
            headerName: intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.CHECKOUT.ENTRIES.TAB.GRID.COL.AMOUNT.APPLIED" }),
            maxWidth: 95,
            cellRenderer: params => currencyFormatter(params.value),
            cellStyle: { textAlign: 'right' }
        },
        { 
            field: 'roles', 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CHECKOUT.ENTRIES.TAB.GRID.COL.ROLE' }),
            maxWidth: 90,
            valueGetter: (params) => {
                if(params.node.data.roles){
                    return params.node.data.roles.toString().replace(',', ', ')
                }
            }
        },
        { 
            field: 'status', 
            width: 200,
            flex: 1,
            headerName: intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.CHECKOUT.ENTRIES.TAB.GRID.COL.STATUS" }),
            resizable: true,
            valueGetter: (params) => params?.data?.status?.toString().replace(',', ', ')
        }
    ]

    const selectEntry = (params) => {
        if (!selectedRTO) return;
    
        const isSelected = params?.node?.data?.include;
        const selectedEntryId = params?.node?.data?.entry_id;
        let recomputeRequired = !isSelected;
        let creditNotUsed = recomputeRequired ? selectedRTO.available_credit : selectedRTO.creditNotUsed;

        // if recomputed then consider paying off entries with small balance first
        //retain original sort
        //entriesList = multiSort(entriesList, { balance: 'asc' })
    
        let updatedData = entriesList.map((record) => {
            const isCurrentEntry = record.entry_id === selectedEntryId;
    
            if (isCurrentEntry) {
                // if entry is selected then update the amountApplied and creditNotUsed for the selectedRTO
                record.include = isSelected;
                if (isSelected) {
                    record.amountApplied = Math.min(creditNotUsed, params?.node?.data?.balance);
                    creditNotUsed = addFloatingNumbers([creditNotUsed, -1*record.amountApplied], 2)
                } else {
                    // if entry is unselected the change the amountApplied to 0 and recompute all other entries
                    record.amountApplied = 0
                }
            }
    
            if (recomputeRequired && record.include) {
                if(creditNotUsed > 0){
                    record.amountApplied = Math.min(creditNotUsed, record?.balance);
                    creditNotUsed = addFloatingNumbers([creditNotUsed, -1*record.amountApplied], 2)
                }else{
                    // should never happen
                    record.amountApplied = 0
                }
               
            }
    
            return record;
        });

        // display entries in number asc sort on FE
        //retain original sort
        //updatedData = multiSort(updatedData, { number: 'asc' })
    
        // Update selectedRTO with the new credit available
        setSelectedRTO({ ...selectedRTO, creditNotUsed, entries: updatedData });
    
        // Update the data in the grid and state
        gridRef?.current?.api?.setRowData(updatedData);
        setEntriesList(updatedData);
    };
    

    const handleRowDoubleClicked = (row_data) => {
        if(row_data.data.entry_id){
            window.open(process.env.PUBLIC_URL+'/entries/detail/'+row_data.data.entry_id+`?customer_id=${customerID}&selectedTab=3`,'_blank')
        }
    }

    const getRowStyle = (params) => {
        if (params.node.rowPinned) {
            return { fontWeight: "bold" };
        }
    };

    return (
        <>     
            {/* Grid */}
            <div>    
                {/* Total Entries Count */}
                <div className='row'>
                    <label className='col-form-label fs-5 py-1 text-end'>
                        {  entriesList.length  } { entriesList.length === 1 ? 'Entry' : 'Entries' }
                    </label>
                </div>
                <div style={containerStyle}>
                    <div style={gridStyle} className="ag-theme-alpine ag-narrow-cell">
                        <AgGridReact
                            ref={gridRef}
                            defaultColDef={defaultColDef}
                            rowData={entriesList}
                            columnDefs={columnDefs} 
                            rowStyle={{ fontSize: '12px' }}
                            headerHeight={getNarrowHeaderHeight}
                            rowHeight={getNarrowRowHeight}
                            suppressMovableColumns={true}
                            rowSelection="single"
                            suppressScrollOnNewData={true}
                            getRowClass={params => {
                                if ((!params.data.isPayable || selectedRTO.available_credit == 0) && !params.node.rowPinned) {
                                    return 'expired-text'
                                }  
                            }}
                            getRowStyle={params => getRowStyle(params)}
                            onRowDoubleClicked={(row_data) => handleRowDoubleClicked(row_data)}
                            overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Please wait while entries are loading...</span>'}
                        />
                    </div>
                </div>
            </div>
            {/* Grid */}

            {/* Summary Section */}
            <div className='mt-2'>

                {/* RTO used for check out */}
                <div className='row align-items-center justify-content-end'>
                    <div className='col-2'></div>
                    <div className='col-6 w-lg-450px'>
                        <label className='fs-6 fw-bold'>
                            {`${intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CHECKOUT.ENTRIES.TAB.SUMMARY.RTOFORCHECKOUT' })} ${selectedRTO?.fl_name ? `- ${selectedRTO?.fl_name}` : ''}`}
                        </label>
                    </div>
                    {/* <div className='col-3 w-lg-250px'>
                        <span className='fs-6 d-flex justify-content-end text-end'>
                            
                        </span>
                    </div> */}
                   
                </div>

                {/* Pending Entries Balance */}
                <div className='row align-items-center justify-content-end'>
                    <div className='col-2'></div>
                    <div className='col-6 w-lg-200px'>
                        <label className='fs-6 '>
                            {intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CHECKOUT.ENTRIES.TAB.SUMMARY.ENTRIES.BALANCE' })}
                        </label>
                    </div>
                    <div className='col-5=3 w-lg-250px'>
                        <span className='fs-6 d-flex justify-content-end text-end'>
                            {`${currencyFormatter(addFloatingNumbers(getArrayFromObject('balance', entriesList?.filter((entry) => entry.include))))}`}
                        </span>
                    </div>
                </div>

                {/* A/R Credit */}
                <div className='row align-items-center justify-content-end'>
                    <div className='col-2'></div>
                    <div className='col-6 w-lg-200px'>
                        <label className='fs-6 '>
                            {intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CHECKOUT.ENTRIES.TAB.SUMMARY.ACCOUNTREGISTERCREDIT' })}
                        </label>
                    </div>
                    <div className='col-3 w-lg-250px'>
                        <span className='fs-6 d-flex justify-content-end text-end'>
                            {`${currencyFormatter(selectedRTO?.available_credit)}`}
                        </span>
                    </div>
                </div>

                {/* Total Entries Balance Checked out */}
                <div className='row align-items-center justify-content-end'>
                    <div className='col-2'></div>
                    <div className='col-6 w-lg-200px'>
                        <label className='fs-6 '>
                            {intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.CHECKOUT.ENTRIES.TAB.SUMMARY.ENTRIESBALANCE' })}
                        </label>
                    </div>
                    <div className='col-5=3 w-lg-250px'>
                        <span className='fs-6 d-flex justify-content-end text-end'>
                            {`${currencyFormatter(addFloatingNumbers(getArrayFromObject('amountApplied', entriesList)))}`}
                        </span>
                    </div>
                </div>

                 {/* Footer */}
                <div className='card-footer d-flex justify-content-end py-3 px-0'>
                    <button type="button" className='btn btn-sm btn-secondary fw-bold text-uppercase me-2' onClick={closePaymentBatchDetail} data-tooltip-id="PAYMENTBATCHES.DETAIL.TAB.MOVECREDITTOACCOUNT.BUTTON.CLOSE">
                        {intl.formatMessage({ id: 'FORM.INPUT.PAYMENT.BATCHES.TAB.MOVE.CREDIT.TOACCOUNT.TAB.BUTTON.CLOSE' })}
                    </button>
                    
                    <button 
                        type="button" 
                        className='btn btn-sm btn-secondary fw-bold text-uppercase' 
                        autoFocus
                        disabled= {!entriesList.find((data) => data.include) || !selectedRTO}
                        onClick={checkoutEntries}
                    >
                        <span className="spinner-border spinner-border-sm d-none me-2" role="status" aria-hidden="true"></span>
                        {intl.formatMessage({ id: "FORM.INPUT.PAYMENT.BATCHES.TAB.POSTPAYMENTS.BUTTON.CHECKOUTENTRIES" })}
                    </button>
                </div>
                {/* Footer */}

            </div>
            
        </>
    );
}

export { EntriesGrid }