import { useMemo, useEffect, useState } from "react";
import { useIntl } from "react-intl";

// Custom Components
import OutputListingGridStatic from '../../../../modules/output-listing/OutputListingGridStatic'

const ExportUSEFResultsGrid = ({ rowData, selectedShowId, onClose, setDisplayErrors, exportErrors, exportResults }) => {
    const intl = useIntl()
    const containerStyle = useMemo(() => ({ width: '100%', height: '300px' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%'}), []);
    const [gridRef, setGridRef] = useState();
       
    const getColumnDefs = () => {
        return [
            { field: 'class_number', cellStyle: {textAlign: 'center'},  width:100, sortable: true, headerName: intl.formatMessage({ id: "SHOWS.EXPORTUSEFRESULTS.MODAL.GRID.CLASS" })},
            { field: 'entry_number', cellStyle: {textAlign: 'center'}, width:100, sortable: true, headerName: intl.formatMessage({ id: "SHOWS.EXPORTUSEFRESULTS.MODAL.GRID.ENTRY" })},
            { field: 'rider_name',  cellStyle: {textAlign: 'left'}, width: 150, sortable: true, headerName: intl.formatMessage({ id: "SHOWS.EXPORTUSEFRESULTS.MODAL.GRID.RIDER" }) },
            { field: 'error_message', cellStyle: {textAlign: 'left'}, width: 180, sortable: true, headerName: intl.formatMessage({ id: "SHOWS.EXPORTUSEFRESULTS.MODAL.GRID.ERROR" })},
        ]
    }

    return (
        <div>
            {/* USEF Error Grid */}
            <div style={containerStyle}>
                <div style={gridStyle} className="ag-theme-alpine ag-narrow-cell">
                    
                    <OutputListingGridStatic
                        key='export-grid'
                        columnDefs={getColumnDefs()}
                        rowData={rowData}
                        setGridRef={setGridRef}
                        containerStyle={containerStyle}
                    ></OutputListingGridStatic>
                </div>
            </div>
          
            {/* Action buttons */}
            <div className='card-footer d-flex justify-content-end py-3 px-0'>
                <button type="button" disabled={rowData.length == 0} className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' onClick={exportErrors} data-tooltip-id="SHOW.DETAIL.QUICKACTION.EXPORTUSEFRESULTS.BUTTON.EXPORTUSEFERRORS">
                    {intl.formatMessage({ id: 'FORM.INPUT.SHOWS.MODAL.BUTTON.EXPORTUSEFERRORS' })}
                </button>
                <button type="button" className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' onClick={(e) => {exportResults(selectedShowId)}} data-tooltip-id="SHOW.DETAIL.QUICKACTION.EXPORTUSEFRESULTS.BUTTON.EXPORTUSEFRESULTS">
                    {intl.formatMessage({ id: 'FORM.INPUT.SHOWS.MODAL.BUTTON.EXPORTUSEFRESULTS' })}
                </button>
                <button type="button" onClick={(e) => { onClose(); e.preventDefault()}} className='btn btn-sm btn-secondary fw-bold me-5 text-uppercase' >
                    {intl.formatMessage({ id: 'FORM.INPUT.SHOWS.MODAL.BUTTON.CLOSEUSEFEXPORT' })}
                </button>
            </div>
        </div>
       
    )
}

export default ExportUSEFResultsGrid;