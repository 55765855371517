import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom'
import { Alert, Modal } from 'react-bootstrap'
import { KTSVG } from '../../../_metronic/helpers'
import { useIntl } from 'react-intl'
import moment from 'moment'
import { useAlert } from '../sgl-utils/DialogsProvider';

type Props = {
    show: boolean
    onConfirm: (data: any) => void,
    onClose: () => void,
    name: string
}

const modalsRoot = document.getElementById('root-modals') || document.body

const SupplyDeliveryOptionsModal = ({ show, onConfirm, onClose, name }: Props) => {
    const intl = useIntl()
    const [showModal, setShowModal] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const obj = {
        includeDeliveredSupplies: true,
        includePrintedSupplies: true,
    }
    const [data, setData] = useState(obj);

    useEffect(() => {
        if (!isLoading) {
            setShowModal(true)
        } else {
            setIsLoading(false)
        }
    }, [show])

    const hideModal = () => {
        if (onClose) {
            onClose()
        }
        setData(obj)
        sessionStorage.removeItem('supplyDeliveryOptionsModal')
        setShowModal(false)
    }

    const submitConfirm = () => {
        if (onConfirm) {
            onConfirm(data)
            setData(obj)
            sessionStorage.removeItem('supplyDeliveryOptionsModal')
            setShowModal(false)
        } else {
            setData(obj)
        }
    }


    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-450px search-form'
            show={!isLoading && showModal}
            onHide={hideModal}
            onKeyDown={(event: any) => {
                if (event.key == "Enter" && !event.shiftKey) {
                    submitConfirm()
                }
            }}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{intl.formatMessage({ id: 'DIALOG.COMMON.TITLE.SUPPLYDELIVERYOPTIONS.TITLE' })}</h2>
                {/* begin::Close */}
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={hideModal}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
                {/* end::Close */}
            </div>

            <div className='modal-body py-3 px-4 shadow-lg'>
                <form noValidate className='form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        <div className='col form-check-sm form-check-custom ps-lg-3'>
                            <input
                                className='form-check-input'
                                type='checkbox'
                                checked={data.includePrintedSupplies}
                                id='includePrintedSupplies'
                                onChange={(e) => setData({ ...data, includePrintedSupplies: e.target.checked })}
                            />
                            <label className='col-form-label mx-2 fs-6 py-1' htmlFor='includePrintedSupplies' data-tooltip-id="REPORT.DETAIL.MODAL.SELECTREPORT.LABEL.INCLUDEPRINTEDSUPPLIES">{intl.formatMessage({ id: 'FORM.INPUT.REPORTS.INFO.LABEL.INCLUDEPRINTEDSUPPLIES' })}</label>
                        </div>

                        <div className='col form-check-sm form-check-custom ps-lg-3'>
                            <input
                                className='form-check-input'
                                type='checkbox'
                                checked={data.includeDeliveredSupplies}
                                id='includeDeliveredSupplies'
                                onChange={(e) => setData({ ...data, includeDeliveredSupplies: e.target.checked })}
                            />
                            <label className='col-form-label mx-2 fs-6 py-1' htmlFor='includeDeliveredSupplies' data-tooltip-id="REPORT.DETAIL.MODAL.SELECTREPORT.LABEL.INCLUDEDELIVEREDSUPPLIES">{intl.formatMessage({ id: 'FORM.INPUT.REPORTS.INFO.LABEL.INCLUDEDELIVEREDSUPPLIES' })}</label>
                        </div>
                    </div>

                    <div>
                        <div className='d-flex justify-content-end'>
                            <button className='btn btn-sm btn-secondary me-4 fw-bold' style={{ marginLeft: "auto" }} onClick={hideModal}>
                                {intl.formatMessage({ id: 'DIALOG.COMMON.BUTTON.CANCEL' })}
                            </button>

                            <button className='btn btn-sm btn-dark fw-bold' onClick={submitConfirm}>
                                {intl.formatMessage({ id: 'DIALOG.COMMON.BUTTON.OK' })}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export { SupplyDeliveryOptionsModal }