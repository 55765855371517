
import { useIntl } from 'react-intl'
import Select from 'react-select'
import { reactSelectStyles } from '../../modules/sgl-utils/fieldControls'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { useAppSelector } from '../../redux/hooks'
import { useAlert } from '../../modules/sgl-utils/DialogsProvider'
import { useAuth } from '../../modules/auth'
import { useOutputContext } from '../../modules/output-listing/OutputListingContext'

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

const CloudApiLogsFilter = () => {
  const intl = useIntl()
  const customerID = useAppSelector(state=> state.showCompany.company_id);
  const [ dropDownLists, setDropDownLists ] = useState(null)
  const [ userOptions, setUserOptions ] = useState([])
  const alertDialog = useAlert();
  const { currentUser } = useAuth()
  const {customFilters, setCustomFilters} = useOutputContext();
  const reactSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      background: state?.isDisabled? '#eff2f5' : '#fff',
      height: 40,
      minHeight: 40,
      fontSize: 14,
      fontFamily: 'Lato',
      color: "#3F4254",
      borderColor: "#A1A5B7",
      borderWidth: 1,
      boxShadow: 'none',
      borderRadius: 0,
      outline:0,
      '&:focus': {
          borderColor: '#A1A5B7',
      },
      '&:hover': {
          borderColor: '#A1A5B7',
      }
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      padding: '0 6px',
      width: '180px'
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '39px',
    }),
    placeholder: (defaultStyles) => {
      return {
          ...defaultStyles,
          color: '#3F4254',
      }
  }
};

  useEffect(()=> {//Populate drop down values
    axios.get(`${NEST_API_URL}/utility/getCloudApiLogsMetaData?customer_id=${customerID}`)
    .then(response => {   
        setDropDownLists({
            moduleOptions: response?.data?.moduleOptions && response?.data?.moduleOptions || [],
            actionOptions: response?.data?.actionOptions && response?.data?.actionOptions || []
        })
    })
    .catch((error) => {
        if (error.response) {
            alertDialog({message: error.response.data.error});
        }
    }).finally(() => {
        //populate company drop down
        const companyOptions = currentUser?.smc_access.map(company => ({
            label: company?.urlpath?.toUpperCase(), value: company.company_id
        })).sort((a, b) => a.label.localeCompare(b.label))// sort the array by label in desc order
        companyOptions?.unshift({label: 'All Companies', value: ''})
        setDropDownLists((prevState) => ({...prevState, companyOptions}))
    })

    //by default select current company
    setCustomFilters({...customFilters, company: customerID})
  },[])

  useEffect(() => {
    setCustomFilters(() => {return {...customFilters, company: customerID}})
  },[customerID])

  useEffect(()=> {//Populate drop down values
    setCustomFilters({...customFilters, users: ""})
    axios.get(`${NEST_API_URL}/utility/getCloudApiLogsUsers?customer_id=${customerID}&selectedCompany=${customFilters?.company || null}`)
    .then(response => {   
        setUserOptions(response?.data?.users || [])
    })
    .catch((error) => {
        if (error.response) {
            alertDialog({message: error.response.data.error});
        }
    })
  },[customFilters?.company])

  const reqTimeOptions = [
    {label: "All Req. Time", value: ""},
    {label: "No Response", value: "no_response"},
    {label: "Error", value: "error"},
    {label: "More than 5 sec", value: "more_than_five"},
    {label: "Between 2-5 sec", value: "between_two_to_five"},
    {label: "Between 1-2 sec", value: "between_one_to_two"},
    {label: "Less than 1 sec", value: "less_than_one"}
  ]

  return (
    <div className='w-450px ms-3' style={{ display: 'flex', gap: '10px' }}>
      <div className='col-lg-4'>
        <Select
            options={dropDownLists?.companyOptions ?? []}
            id='company'
            name={'company'}
            isSearchable={true}
            className='fs-6'
            onChange={e => setCustomFilters({...customFilters, company: e.value})}
            value={customFilters.company !== undefined ? dropDownLists?.companyOptions.filter((ss) => ss.value === customFilters.company):""}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
            })}
            styles={reactSelectStyles}
            placeholder={intl.formatMessage({ id: 'LIST.CLOUDAPILOGS.SELECT.ALLCOMPANIES' })}
        />
      </div>
      <div className='col-lg-4'>
        <Select
            options={dropDownLists?.moduleOptions ?? []}
            id='module'
            name={'module'}
            isSearchable={true}
            className='fs-6'
            onChange={e => setCustomFilters({...customFilters, module: e.value})}
            value={customFilters.module !== undefined ? dropDownLists?.moduleOptions.filter((ss) => ss.value === customFilters.module):""}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
            })}
            styles={reactSelectStyles}
            placeholder={intl.formatMessage({ id: 'LIST.CLOUDAPILOGS.SELECT.ALLMODULES' })}
        />
      </div>
      <div className='col-lg-4'>
        <Select
            options={dropDownLists?.actionOptions ?? []}
            id='action'
            name={'action'}
            isSearchable={true}
            className='fs-6'
            onChange={e => setCustomFilters({...customFilters, action: e.value})}
            value={customFilters.action !== undefined ? dropDownLists?.actionOptions.filter((ss) => ss.value === customFilters.action):""}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
            })}
            styles={reactSelectStyles}
            placeholder={intl.formatMessage({ id: 'LIST.CLOUDAPILOGS.SELECT.ALLACTIONS' })}
        />
      </div>
      <div className='col-lg-3'>
        <Select
            options={userOptions ?? []}
            id='users'
            name={'users'}
            isSearchable={true}
            className='fs-6'
            onChange={e => setCustomFilters({...customFilters, users: e.value})}
            value={customFilters.users !== undefined ? userOptions.filter((ss) => ss.value === customFilters.users):""}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
            })}
            styles={reactSelectStyles}
            placeholder={intl.formatMessage({ id: 'LIST.CLOUDAPILOGS.SELECT.ALLUSERS' })}
        />
      </div>
      <div className='col-lg-4'>
        <Select
            options={reqTimeOptions}
            id='reqTime'
            name={'reqTime'}
            isSearchable={true}
            className='fs-6'
            onChange={e => setCustomFilters({...customFilters, reqTime: e.value})}
            value={customFilters.reqTime !== undefined ? reqTimeOptions.filter((ss) => ss.value === customFilters.reqTime):""}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
            })}
            styles={reactSelectStyles}
            placeholder={intl.formatMessage({ id: 'LIST.CLOUDAPILOGS.SELECT.ALLREQTIME' })}
        />
      </div>
    </div>
  )
}

export { CloudApiLogsFilter }
