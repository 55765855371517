import { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useFormContext } from 'react-hook-form';
import { defaultColDef, getNarrowHeaderHeight, getNarrowRowHeight, getRowData, editableCellStyle, agGridPositiveNumberSetter } from '../../../modules/sgl-utils/agGridHelpers';
import { AgGridReact } from 'ag-grid-react';

const CircuitPointsTab = ({ rowData }) => {
    const intl = useIntl();
    const containerStyle = useMemo(() => ({ width: '100%', height: '425px' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '95%'}), []);
    const [gridRef, setGridRef] = useState(null)
    
    const methods = useFormContext()
 
    const columnDefs = [
        { 
            field: 'place', 
            headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.TAB.CIRCUITPOINTS.PLACE' }), 
            flex: 1,
            cellStyle: { textAlign: 'center' },
        },
        { 
            field: 'points', 
            editable:true,
            valueSetter: params => agGridPositiveNumberSetter(params, 'points', true),
            cellStyle: { textAlign: 'center', ...editableCellStyle },  
            headerName: intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.TAB.CIRCUITPOINTS.POINTS' }),
            flex: 1
        },
    ]

    const onCellValueChanged = () =>{
        const rowData = getRowData(gridRef)
        let circuits_points = ''
        for (let row of rowData){ // convert place and point data according to the pattern required to store in database 
            circuits_points += row.place + "¡" + row.points + "†" ;
        }
        methods.setValue('circuit.circuit_points', circuits_points, { shouldDirty: true })
    }

    return (
        <>
            <div className='form-group hunter-increment-tab'>
                <div className='row mb-2'>
                    <div className='col-lg-2'>
                        <div style={containerStyle}>
                            <div style={gridStyle} className="ag-theme-alpine ag-narrow-cell">
                                <AgGridReact
                                    defaultColDef={defaultColDef}
                                    rowData={rowData}
                                    columnDefs={columnDefs} 
                                    onGridReady={params =>  { 
                                        setGridRef(params.api)
                                    }}
                                    rowStyle={{ fontSize: '14px' }}
                                    headerHeight={getNarrowHeaderHeight}
                                    rowHeight={getNarrowRowHeight}
                                    onCellValueChanged={onCellValueChanged}
                                    stopEditingWhenCellsLoseFocus={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-7'>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-225px' htmlFor='ADDITIONALCHAMPIONPOINTS' data-tooltip-id="CIRCUITS.DETAIL.TAB.CIRCUITPOINTS.LABEL.ADDITIONALCHAMPIONPOINTS">{intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.TAB.HUNTERINCREMENT.LABEL.ADDITIONALCHAMPIONPOINTS' })}</label>
                            <div className='col-lg-1 w-lg-125px'>
                                <input
                                    id='ADDITIONALCHAMPIONPOINTS'
                                    {...methods.register( 'circuit.c_p_champ_additional_points', 
                                        {
                                        required: false
                                        })
                                    }
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1 number-input'
                                />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-225px' htmlFor='ADDITIONALCHAMPIONPOINTS' data-tooltip-id="CIRCUITS.DETAIL.TAB.CIRCUITPOINTS.LABEL.ADDITIONALRESERVEPOINTS">{intl.formatMessage({ id: 'FORM.INPUT.CIRCUITS.TAB.HUNTERINCREMENT.LABEL.ADDITIONALRESERVEPOINTS' })}</label>
                            <div className='col-lg-1 w-lg-125px'>
                                <input
                                    id='ADDITIONALRESERVEPOINTS'
                                    {...methods.register( 'circuit.c_p_reserve_additional_points', 
                                        {
                                        required: false
                                        })
                                    }
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1 number-input'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export { CircuitPointsTab }