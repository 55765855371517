import { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useAppSelector } from '../../../../redux/hooks';
import { useOutputContext } from '../../../../modules/output-listing/OutputListingContext';
import { useAlert, useLoadingOverlay, useProgress } from '../../../../modules/sgl-utils/DialogsProvider';
import axios from 'axios';

// Metronic Components
import { KTSVG } from '../../../../../_metronic/helpers';

// Assets
import "flatpickr/dist/themes/material_blue.css";
import ExportUSEFResultsGrid from './ExportUSEFResultsGrid';
import useGenerateExport from '../../../../modules/hooks/use-generate-export';
import { downloadTextFile } from '../../../../modules/sgl-utils/SglFunctions';

const modalsRoot = document.getElementById('root-modals') || document.body

const ExportUSEFResults = ({ show, handleClose }) => {
    const intl = useIntl() 
    const { getOutputSelectionAreaIDs } = useOutputContext()
    const alertDialog = useAlert()
    const loadingOverlay = useLoadingOverlay()
    const customerId = useAppSelector(state=> state.showCompany.company_id);
    const [showModal, setshowModal] = useState(false)
    const [displayErrors, setDisplayErrors] = useState(false)
    const [selectedShowId, setSelectedShowId] = useState(0)
    const [errorData, setErrorData] = useState([]);
    const [errorHeading, setErrorHeading] = useState('');
    const [errorCount, setErrorCount] = useState({});
    const generateExport = useGenerateExport();
    const {progressBar} = useProgress()
    const { getEventID } = useOutputContext();

    useEffect(() => {
        if (show) { 
            getOutputSelectionAreaIDs()
            .then(res => { 
                if (res.length !==  1){
                    // error message if no show is selected
                    alertDialog({ message: intl.formatMessage({ id: "SHOWS.EXPORTECRESULTS.MODAL.SELECTSHOWSMESSAGE"}) })
                    handleClose()
                }
                else if (res.length ===  1) {
                    loadingOverlay({show: true, message: 'Processing USEF Export...'})
                    let currentShowID = res;
                    setSelectedShowId(currentShowID)
                    axios.get( process.env.REACT_APP_NEST_API_URL + '/shows/getUsefExportMetadata', {
                        params: {
                          show_id: currentShowID,
                          customer_id: customerId
                        }
                      })
                    .then((response) => {
                        // api will return success(T/F), error_message, flag for display alert and data to display in modal
                        if(!response.data.success){
                            alertDialog({ message: response.data.erorr_message })
                            loadingOverlay({ show: false, message: 'Processing USEF Export...' })
                            handleClose()
                        } else {
                            let errors = response.data.error_list;  // for immidiate processing
                            if(errors.length > 0){ // if there are errors, display dialog
                                setshowModal(true);
                                setErrorData(errors);
                                setErrorHeading(response.data.error_heading);

                                // Get error counts to display on the right side of the modal
                                const errorCounts = {};

                                // Find error count for each error
                                errors.forEach(item => {
                                    const errorType = item.error_message;
                                    errorCounts[errorType] = (errorCounts[errorType] || 0) + 1;
                                });
                                setErrorCount(errorCounts);
                            } else { // if there are no errors, do not display dialog and export results                                
                                exportResults(currentShowID)
                                handleClose()
                            }

                        }
                        loadingOverlay({ show: false })
                    })
                    .catch(() => loadingOverlay({ show: false }))
                } 
            })
            .catch(() => loadingOverlay({ show: false }))
        }
    }, [show])


    const onClose = () => {
        loadingOverlay({ show: false })
        handleClose();  
    }

    // This function will export errors of the show in excel file
    const exportErrors = async () => {
        const settings = {
            url: 'shows/exportUsefErrors',
            progressBarTitle: 'Preparing Data for Export',
            showId: selectedShowId
        };

        // Generate export
        generateExport(settings);
    }

    // This function will send a request to download .dat export
    const exportResults = async (showId) => {
        // const settings = {
        //     url: 'shows/exportUsefShowResults',
        //     progressBarTitle: 'Preparing USEF Data for Export',
        //     showId: selectedShowId
        // };

        // // Generate export
        // generateExport(settings);

        const eventID = getEventID("export-show-USEF-Results")
        progressBar({ show: true, eventID, showProgress: 'inline', title: "Preparing Data for Export", timeElapsed: true })
        
        axios.post(process.env.REACT_APP_NEST_API_URL + `/shows/exportUsefShowResults`, {
            customer_id: customerId,
            show_id: showId,
            progress_event_id: eventID
        })
        .then(async response => {
            progressBar({ show: false, eventID })
            if(response.data.success){
                loadingOverlay({ show: true, message: 'Downloading File'});
                downloadTextFile(response.data.file_info.text_data, response.data.file_info.doc_name);
                loadingOverlay({ show: false, message: 'Downloading File'});
            }
        })
        .catch((response) => {progressBar({ show: false, eventID })});
    }

    return createPortal(
        <Modal
            id='kt_modal_create_app'
            enforceFocus={false}
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-1000px search-form'
            show={showModal}
            onHide={onClose}
        >
            <div className='modal-header py-0 px-4'>
                <h2 className="fs-4">{displayErrors?intl.formatMessage({ id: 'SHOWS.QUICKACTION.POPUP.HEADING.EXPORTUSEFRESULTSERRORS' }):intl.formatMessage({ id: 'SHOWS.QUICKACTION.POPUP.HEADING.EXPORTUSEFRESULTS' })}</h2>
                <div className='btn btn-sm btn-icon btn-active-color-dark' onClick={onClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>

            <div className='modal-body py-3 px-4'>
                <form noValidate className='form add-fee-form' onSubmit={e => e.preventDefault()}>
                    <div className='card-body p-0'>
                        <div className="d-flex mb-2">
                            
                            <div className='col'>
                                {(displayErrors) ?
                                <div className='row mb-2'>
                                    <label className='col-lg-4 col-form-label fs-5 py-1 fw-bold text-danger'>
                                        { intl.formatMessage({ id: "SHOWS.EXPORTECRESULTS.MODAL.EXPORTECRESULTSERRORS"  }) }
                                    </label>
                                </div>
                                :
                                <div className='row mb-2'>
                                    <label className='col-lg-12 col-form-label fs-5 py-1 fw-bold'>
                                        { intl.formatMessage({ id: "SHOWS.EXPORTUSEFRESULTS.MODAL.USEFRESULTSVERIFICATION"  }) }
                                    </label>
                                    <label className='col-lg-12 col-form-label fs-5 py-1'>
                                        {errorHeading}
                                    </label>
                                </div>
                                }
                            </div> 
                        </div>
                     
                    </div>

                    <div className='row'>
                        <div className='col-8'>
                            <ExportUSEFResultsGrid 
                                rowData={errorData}
                                selectedShowId={selectedShowId}
                                onClose = {onClose}
                                setDisplayErrors = {setDisplayErrors}
                                exportErrors={exportErrors}
                                exportResults={exportResults}
                            />
                        </div>
                        <div className='col-4'>
                            <label className='col-lg-12 col-form-label fs-6 py-1'>
                                    { intl.formatMessage({ id: "SHOWS.EXPORTUSEFRESULTS.MODAL.USEFRESULTSTOTALERRORS"  })}
                                    {errorData?.length}
                                    { intl.formatMessage({ id: "SHOWS.EXPORTUSEFRESULTS.MODAL.USEFRESULTSERRORS"  })}
                            </label>

                            {Object.keys(errorCount).map((key, index) => {
                                return (
                                    <label className='col-lg-12 col-form-label fs-6 py-1' key={index}>
                                        {key}: {errorCount[key]}  { intl.formatMessage({ id: "SHOWS.EXPORTUSEFRESULTS.MODAL.USEFRESULTSERRORS"  })}
                                    </label>
                                );
                            })}
                        </div>

                    </div>
                </form>
            </div>
        </Modal>,
        modalsRoot
    )
}

export default ExportUSEFResults;