import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl'
import Select from 'react-select';
import { useAlert, useConfirm, useLoadingOverlay, useProgress } from '../../../modules/sgl-utils/DialogsProvider';
import {  reactSelectStyles } from '../../../modules/sgl-utils/fieldControls';
import { useAppSelector } from '../../../redux/hooks';
import EntriesGrid from './ApplyPayments/EntriesGrid';
import { useOutputContext, useOutputContextUpdater } from '../../../modules/output-listing/OutputListingContext';
import { useCurrencyFormatter } from '../../../modules/sgl-utils/Formatters';
import axios from 'axios';

// Assets
import "flatpickr/dist/themes/material_blue.css";
import VISALogo from '../../../../_metronic/assets/CardImages/Card_Visa.jpeg';
import AMEXLogo from '../../../../_metronic/assets/CardImages/Card_Amex.jpeg';
import DISCOVERLogo from '../../../../_metronic/assets/CardImages/Card_Discover.jpeg';
import MCLogo from '../../../../_metronic/assets/CardImages/Card_MC.jpeg';
import { addFloatingNumbers, getArrayFromObject, loadingSpinnerBtnWait } from '../../../modules/sgl-utils/SglFunctions';
import { loadingSpinnerBtnRelease } from '../../../modules/sgl-utils/SglFunctions';
import { NumericFormat, PatternFormat } from 'react-number-format';
import { updateAGGridWithoutFlickering } from '../../../modules/sgl-utils/agGridHelpers';

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL
const Buffer = require("buffer").Buffer;

const initialState = {
    paymentType: "",
    creditCardNum: "",
    creditCardCVV: "",
    creditCardName: "",
    creditCardExp: "",
    RetainCard: "",
    email: "",
    addr1: "",
    addr2: "",
    cityname: "",
    state: "", 
    zip: "",
    country: "",
    phoneNumber: "",
    updateRTO: false,
    selectedCardId: 0,
    newPaymentDescription: "",
    paymentMethodID: 0,
    base64CompleteCardNumber: "",
    entSecuredPaymentMethodID: "",
    newPaymentCode: "",
    saveSource: "apply payment to multiple entries",
    peopleID: "",
    forceCIT: false,
    cardType: ''
}

const PaymentTab = ({ handleClose, selectedEntries, companyPaymentGateway, listOfCountries, paymentTypes, listOfStates, applyPayment, calledFrom, autoApplyPrizeMoney, setAutoApplyPrizeMoney, autoApplyPrizeMoneyPayment, setAutoApplyPrizeMoneyPayment, getAvailablePrizeMoneyClass, recalculatePrizeMoney }) => {
    const intl = useIntl()
    const customerID = useAppSelector(state => state.showCompany.company_id)
    const currentShowID = useAppSelector(state => state.currentShow.show_id)
    const currencyCode = useAppSelector(state => state.showCompany.currency_code)  

    const alertDialog = useAlert()
    const loadingOverlay = useLoadingOverlay()
    const confirmDialog = useConfirm()
    const currencyFormatter = useCurrencyFormatter()

    const [allRowData, setAllRowData] = useState([])
    const [entriesWithBalanceOnly, setEntriesWithBalanceOnly] = useState(false)
    const [checkAll, setCheckAll] = useState(true)
    const [trigger, setTrigger] = useState(0) // Force Use Effect to Run Again

    const [entryPaymentMethods, setEntryPaymentMethods] = useState([])
    const [entrySecuredPaymentMethodId, setEntrySecuredPaymentMethodId] = useState(0)
    const [info, setInfo] = useState("")

    const [type, setType] = useState("Payment")
    const [amount, setAmount] = useState(0)
    const [totalAmount, setTotalAmount] = useState(0) // amount + serviceFee
    const [serviceFee, setServiceFee] = useState(0)

    const [refCodeLabel, setRefCodeLabel] = useState("REFCODE")
    const [cardOwnerOptions, setCardOwnerOptions] = useState([])
    const [selectedCardOwner, setSelectedCardOwner] = useState()

    const [paymentData, setPaymentData] = useState(initialState)

    const [isCreditSecurityCodeDisabled, setIsCreditSecurityCodeDisabled] = useState(true)
    const [hasCiTWarning, setHasCiTWarning] = useState(false)
    const [isDisabledForCompanyGateway, setIsDisabledForCompanyGateway] = useState(true)
    const [isDisabledForNonCard, setIsDisabledForNonCard] = useState(true)
    const [isEnabledForOtherCardType, setisEnabledForOtherCardType] = useState(false) 
    
    // To Force useEffect to reapply distribution & other logics when apply is checked/unchecked
    const [forceReapplySplit, setForceReapplySplit] = useState(false)
    const [gridRef, setGridRef] = useState()

    // New Structure Change: Payment Type Id of Selected Payment Type
    const [paymentTypeId, setPaymentTypeId] = useState(0)
    const [recalPrizeMoney, setRecalPrizeMoney] = useState(true)

    // Get Ag-grid data
    const getRowData = (_gridRef) => {
        const rowData = []
        _gridRef.forEachNode(node => rowData.push(node.data));
        return rowData
    }

    const createCardOwnerSelect = (entriesData) => {
        // Create Owner Select
        const cardOwners = []
        for (let entry of entriesData) {

            if (entry.trainer_id) {
                cardOwners.push({ label: entry.trainer, value: entry.trainer_id })
            } 
            
            if (entry.entryowner_id) {
                cardOwners.push({ label: entry.owner, value: entry.entryowner_id })
            }
            
            if (entry.prizemoneyrecipient_id) {
                cardOwners.push({ label: entry.prizemoneyrecipient, value: entry.prizemoneyrecipient_id })
            }

            if (entry.responsibleparty_id) {
                cardOwners.push({ label: entry.responsibleparty, value: entry.responsibleparty_id })
            }

            for (let er of entry.EntryRiders) {
                cardOwners.push({ label: er.rider_name, value: er.rider_id })
            }
        }

        let uniqueCardOwners = cardOwners.filter((obj, index, self) => index === self.findIndex(({ value }) => value === obj.value))

        setCardOwnerOptions(uniqueCardOwners)
    }

    // update include value for all entries when header checkbox is checked or unchecked
    const updateIncludeForAllEntries = (checkAll) => {
        const rowData = getRowData(gridRef).map(row => ({ ...row, apply: checkAll }))
        updateAGGridWithoutFlickering({ current: {api: gridRef }}, rowData)
        setRecalPrizeMoney(!recalPrizeMoney)
    }

    useEffect(() => {
        if(gridRef){
            for (let entryData of selectedEntries) {
                entryData.payment = 0
            }

            setAllRowData(selectedEntries)
            updateAGGridWithoutFlickering({ current: {api: gridRef }}, selectedEntries)
            createCardOwnerSelect(selectedEntries)
            setEntriesWithBalanceOnly(false)
            recalculate(true)
        }
    }, [gridRef, selectedEntries])

    useEffect(() => {
        if(checkAll && gridRef){
            updateIncludeForAllEntries(checkAll)
            setRecalPrizeMoney(!recalPrizeMoney)
        }
    }, [checkAll, gridRef, selectedEntries])

    useEffect(() => {
        if (gridRef) {
            const rowData = getRowData(gridRef) 
            const filteredRowData = []

            if (entriesWithBalanceOnly) { // Display entries with balance
                for (let row of rowData) { 
                    if (row.balance > 0) {
                        filteredRowData.push(row)
                    }
                }
            } else { // Display all entries
                for (let row of allRowData) {
                    // Find row in current display
                    let foundRow = rowData.find(r => r.entry_id == row.entry_id) 
                    if (foundRow) {
                        filteredRowData.push(foundRow)
                    } else {
                        filteredRowData.push({ ...row, apply: checkAll })
                    }
                }
            }
            // gridRef.setRowData(filteredRowData)
            updateAGGridWithoutFlickering({ current: {api: gridRef }}, filteredRowData)
        }
    }, [entriesWithBalanceOnly, gridRef])

    useEffect(() => {
        if (gridRef) {
            calculateCreditCardServiceFee()
        }
    }, [paymentData.paymentType, companyPaymentGateway, gridRef]) // on paymentType change

    const recalculate = (resetDistributions = false, amountToUse = null) => {
        recalculatePrizeMoney(gridRef, true, autoApplyPrizeMoneyPayment)
        distributePayment(resetDistributions, amountToUse)
    }

    useEffect(() => {
        if (gridRef) {
            recalculate()
        }
    }, [checkAll, entriesWithBalanceOnly, paymentData.paymentType, forceReapplySplit, gridRef])

    useEffect(() => {   
        if (gridRef) {
            recalculate(true)
        }
    }, [autoApplyPrizeMoney, gridRef, autoApplyPrizeMoneyPayment, selectedEntries])

    const calculateCreditCardServiceFee = (amountToUse = null) => {
        amountToUse = amountToUse ?? parseFloat(amount) // Use passed amount if available else state amount

        let _serviceFee = 0
        if (amountToUse > 0 && paymentData.paymentType === companyPaymentGateway) {
            const rowData = getRowData(gridRef)
            for (let row of rowData) {
                if(row.Show.credit_card_service_fee && row.Show.credit_card_service_fee_id > 0 && row.Show.credit_card_service_fee_percent > 0 && row.Show.credit_card_service_fee_percent < 100){
                    const payment = parseFloat(row.payment)
                    if (payment > 0 && row.apply) {
                        _serviceFee = addFloatingNumbers([_serviceFee, (payment * (row.Show.credit_card_service_fee_percent / 100))], 2)
                    }
                }
            }
        }
        setServiceFee(_serviceFee)
        setTotalAmount(addFloatingNumbers([amountToUse, _serviceFee], 2))
    }

    const updateInfo = (amountToUse = null) => {
        amountToUse = amountToUse ?? parseFloat(amount) // Use passed amount if available else state amount

        let totalOwed = 0
        let totalAllocated = 0
        let checkedEntriesCount = 0
        const rowData = getRowData(gridRef)
        for (let row of rowData) {
            if (row.apply) { // Check with all selected entries (applied)
                if (row.balance > 0 ) { // total owned should be computed for +ve balance only
                    totalOwed = addFloatingNumbers([totalOwed, row.balance], 2)
                }
            
                totalOwed = addFloatingNumbers([totalOwed, -1*row.prizeMoneyPayment], 2)
                totalAllocated = addFloatingNumbers([totalAllocated, row.payment], 2)
                checkedEntriesCount++
            }
        }

        let infoString = currencyFormatter(totalOwed) + " owed on "+ checkedEntriesCount + " selected "
        infoString += checkedEntriesCount === 1 ? "entry." : "entries."
        
        let overUnder = addFloatingNumbers([amountToUse, -1*totalAllocated], 2)

        if (amountToUse != 0 || overUnder != 0) {
            if (overUnder > 0) { // Still amount is left
                infoString += "\n" + "(" + currencyFormatter(overUnder) + " still unused on this payment.)"
            } else if (overUnder < 0) { // Overpaid
                infoString += "\n" + "("+ currencyFormatter(-1*overUnder)+" over-paid on this payment.)"
            } else {  // amount entered is allocated totally. 
                if (addFloatingNumbers([totalOwed, -1*totalAllocated], 2) > 0) { //is there something owned yet?
                    infoString += "\n" + "(" + currencyFormatter(addFloatingNumbers([totalOwed, -1*totalAllocated], 2)) + " still owed.)"
                } else {
                    infoString += "\n (No outstanding amount after this payment)."
                }
            }
        }

        setInfo(infoString)
    }

    const autoDistribute = () => {
        const rowData = getRowData(gridRef)
        let newAmount = 0
        for (let row of rowData) {
            let balanceAfterPrizeMoney = addFloatingNumbers([row.balance, -1*row.prizeMoneyPayment], 2)
            if (row.apply && balanceAfterPrizeMoney > 0) {
                newAmount = addFloatingNumbers([newAmount, balanceAfterPrizeMoney], 2)
            }
        }
        newAmount = parseFloat(newAmount.toFixed(2))
        recalculate(true, newAmount)
        setAmount(newAmount)
    }

    const distributePayment = (resetDistributions = false, amountToUse = null) => { 
        amountToUse = amountToUse ?? parseFloat(amount) // Use passed amount if available else state amount
        // Calculate amount of payment available to allocate >>
        let amountOfPaymentToAllocate = amountToUse
        const rowData = getRowData(gridRef)

        if (!resetDistributions) {
            for (let row of rowData) {
                if (row.payment !== 0) {
                    amountOfPaymentToAllocate = addFloatingNumbers([amountOfPaymentToAllocate, -1*row.payment], 2)
                }
            }
        }

        for (let row of rowData) { // now distribute this credit reduction
            if (!row.apply) { // If unselected, set amount to pay to 0.
                row.payment = 0
            } else {
                // Distribute amount only when 
                // 1. Payment value is 0 (Doesn't already have a value)
                // 2. There is still balance left after subtracting prize money payment. 
                // 3. There is amount left from input payment amount to allocate
                // NOTE: When auto apply prize money flag is off or conditions for prize money payment are not met, prize money payment will be 0 so it will have no effect on balance.
                let balanceAfterPrizeMoney = addFloatingNumbers([row.balance, -1*row.prizeMoneyPayment], 2) 
                if ((row.payment == 0 || resetDistributions) && balanceAfterPrizeMoney > 0 && amountOfPaymentToAllocate > 0) {
                    row.payment = Math.min(amountOfPaymentToAllocate, balanceAfterPrizeMoney) 
                    amountOfPaymentToAllocate = addFloatingNumbers([amountOfPaymentToAllocate, -1*row.payment], 2)
                } else if (resetDistributions) {
                    row.payment = 0
                }
            }
        }
        updateAGGridWithoutFlickering({ current: {api: gridRef }}, rowData)
        updateInfo(amountToUse)
        calculateCreditCardServiceFee(amountToUse)  
    }

    const handleCustomClose = () => {
        setEntriesWithBalanceOnly(false)
        setCheckAll(false)
        setAmount(0)
        setPaymentData(initialState)
        setRefCodeLabel("REFCODE")
        setIsDisabledForCompanyGateway(true)
        setIsDisabledForNonCard(true)
        setisEnabledForOtherCardType(false)
        setInfo("")
        setTotalAmount(0)
        setServiceFee(0)
        // gridRef.setRowData([])
        updateAGGridWithoutFlickering({ current: {api: gridRef }}, [])
        setAllRowData([])
        setGridRef(null)
        setIsCreditSecurityCodeDisabled(true)
        //refresh list
        //outputContextUpdater({action: 'refresh'})
        
        handleClose();
    }

    const validatePayments = async (event) => {

        // Create request payload
        let data = {
            ...paymentData,
            paymentAmount: amount, 
            autoApplyPrizeMoney,
            autoApplyPrizeMoneyPayment,
            addPayment: paymentData.paymentType ? true : false,
            CardOwner: selectedCardOwner ? selectedCardOwner.value : 0, 
            CardOwnerID: selectedCardOwner ? selectedCardOwner.value : 0,
            paymentTypeId
        }

        let applied = 0
        let numberOfAppliedEntries = 0
        const rowData = getRowData(gridRef)
        const appliedRows = []
        for (let row of rowData) {
            if (row.apply) {
                numberOfAppliedEntries++
                applied = addFloatingNumbers([applied, row.payment], 2)

                row.prizeMoneyAmountToUse = row.prizeMoneyPayment // Use prize money amount that is available for use

                // Use only amount that will make balance 0 if unapplied prize money is greater than entry balance
                if (row.prizeMoneyAmountToUse >= row.balance) {
                    row.prizeMoneyAmountToUse = row.balance
                }

                appliedRows.push(row)
            }
        }

        let message = ''
        if (!autoApplyPrizeMoney && !autoApplyPrizeMoneyPayment) { 
            if (!data.paymentType) { // Payment Type is not set
                message = 'A payment type is required.'
            }
        } else if (numberOfAppliedEntries === 0) {
            message = 'Please select at least one entry.'
        }

        // Either Payment Type is selected or Payment Amount is Entered
        if (data.paymentType || parseFloat(data.paymentAmount)) {
            if(!data.paymentType) {
                message = 'A payment type is required.'  
            } else if (applied != parseFloat(data.paymentAmount)) {
                message = 'Applied amount must match the total payment amount.'
            }
        }

        if (message) {
            alertDialog({ message })
            return
        }

        if(data.base64CompleteCardNumber){                
            let encoded_number = (Number(data.base64CompleteCardNumber) * 2).toString()
            data.base64CompleteCardNumber = Buffer.from(encoded_number).toString('base64');
        }           

        //validate add payment and check with errors and confirmations
        try {
            let entry_ids = getArrayFromObject('entry_id', appliedRows, true)
            loadingSpinnerBtnWait(event)
            const response = await axios.post(NEST_API_URL + "/entries/validatePaymentForEntries", {
                entry_ids,  
                customer_id: customerID,              
                data: data,
                entryRelatedData: appliedRows
            })

            let userChoice = true
            if (response.data.message) {
                userChoice = await confirmDialog ({ message: response.data.message })
            }        
            
            if (userChoice) {
                let response = await applyPayment(event, appliedRows, data) 
                if(response){
                    setPaymentData(prevPaymentData => ({
                        ...prevPaymentData,
                        newPaymentDescription: "",
                        newPaymentCode: "",
                        selectedCardId: 0
                    }))
                    resetCreditCardFields()
                    setAmount(0)
                    setTrigger(prevTrigger => prevTrigger + 1)
                }
            }                                
        } catch (reason) {  
            alertDialog({ message: reason?.response?.data?.error ?? 'Something went wrong..', title: 'Error' })
        } finally {
            loadingSpinnerBtnRelease(event)
        }
    }

    const onCreditCardNumberFocus = () => {
        setPaymentData(prevPaymentData => ({
            ...prevPaymentData, 
            creditCardNum: prevPaymentData.base64CompleteCardNumber
        }))
    }

    const onCreditCardNumberBlur = (e) => {
        let cardNumber = isNaN(e.target.value) ? "" : e.target.value
        let hiddenCardNumber = cardNumber.replace(/.(?=.{4,}$)/g, '*')

        setPaymentData(prevPaymentData => ({
            ...prevPaymentData,
            base64CompleteCardNumber: cardNumber,
            creditCardNum: hiddenCardNumber
        }))
    }

    const resetCreditCardFields = () => {
        const newPaymentData = {}
        newPaymentData.creditCardCVV = ""
        newPaymentData.creditCardExp = ""
        newPaymentData.creditCardName = ""
        newPaymentData.creditCardNum = ""
        newPaymentData.email = ""
        newPaymentData.addr1 = ""
        newPaymentData.addr2 = ""
        newPaymentData.cityname = ""
        newPaymentData.state = ""
        newPaymentData.zip =""
        newPaymentData.country = ""
        newPaymentData.phoneNumber = ""
        newPaymentData.forceCIT = 0
        newPaymentData.paymentMethodID = 0
        newPaymentData.cardType = ''

        setPaymentData(prevPaymentData => ({
            ...prevPaymentData, 
            ...newPaymentData
        }))
    }

    const handleCreditCardChange = (value) => {
        const _selectedCard = entryPaymentMethods.find((pm) => {
            return pm.payment_method_id == value
        })

        if (!_selectedCard) {
            return
        }

        
        if(value == 0){
            setPaymentData(prevPaymentData => ({ ...prevPaymentData, selectedCardId: 0 }))
            resetCreditCardFields()
        }
        else if (value == '-1') { //Other
            setisEnabledForOtherCardType (true)
            setPaymentData(prevPaymentData => ({ ...prevPaymentData, selectedCardId: Number(value) }))
            // Reset credit card fileds
            resetCreditCardFields()
        } else {
            setisEnabledForOtherCardType (false)

            const newPaymentData = {}
            setHasCiTWarning( !_selectedCard['has_cit'] )
            newPaymentData.selectedCardId = Number(value)
            newPaymentData.creditCardCVV = _selectedCard['card_securitycode']
            newPaymentData.creditCardExp = _selectedCard['card_exp']
            newPaymentData.creditCardName = _selectedCard['name_on_card']
            newPaymentData.creditCardNum = _selectedCard['card_lastfour']
            newPaymentData.paymentMethodID = _selectedCard['payment_method_id']
            newPaymentData.email = _selectedCard['email']
            newPaymentData.addr1 = _selectedCard['address_1']
            newPaymentData.addr2 = _selectedCard['address_2']
            newPaymentData.cityname = _selectedCard['city']
            newPaymentData.state = _selectedCard['state']
            newPaymentData.zip = _selectedCard['zip']
            newPaymentData.country = _selectedCard['country']
            newPaymentData.phoneNumber = _selectedCard['phone']
            newPaymentData.peopleID = _selectedCard['people_id']
            newPaymentData.RetainCard = _selectedCard['retained']
            newPaymentData.entSecuredPaymentMethodID = entrySecuredPaymentMethodId
            newPaymentData.forceCIT = _selectedCard['has_cit']
            newPaymentData.cardType = _selectedCard['card_type']

            setPaymentData(prevPaymentData => ({
                ...prevPaymentData, 
                ...newPaymentData
            }))

            const cardOwner = cardOwnerOptions.find(cardOwner => cardOwner.value == _selectedCard['people_id']) 
            setSelectedCardOwner(cardOwner ? cardOwner : {})
        }
    }

    const onPaymentTypeChange = async (e) => {
        const type = e.value

        const foundPaymentType = paymentTypes.find(pt => pt.label === type)
        if (foundPaymentType) {
            setPaymentTypeId(foundPaymentType.sgl_id)
        }

        setisEnabledForOtherCardType (false) //disable all
        if(type === "Authorize.net" || type === "SecureNet" || type === "TD Bank" || type === companyPaymentGateway) {

            setIsCreditSecurityCodeDisabled(false)
            setIsDisabledForNonCard(false)

            if (entryPaymentMethods.length === 0) { // Check if cards list is already loaded
                try {
                    loadingOverlay({ show: true }) 
                    const response = await axios.post( NEST_API_URL + '/entries/getAvailableCardsForEntries', {
                        customer_id: customerID,
                        entry_ids: getArrayFromObject('entry_id', selectedEntries, true),     
                        show_id: currentShowID,           
                    })

                    response.data.entryPaymentMethods.unshift({ dropdown_label: 'Select card ...', payment_method_id: 0 })
                    response.data.entryPaymentMethods.push({ dropdown_label: 'Other', payment_method_id: -1 })
                    setEntryPaymentMethods(response.data.entryPaymentMethods)
                    setEntrySecuredPaymentMethodId(response.data.entSecuredPaymentMethodID)
                } catch (reason) {}
                finally {
                    loadingOverlay({ show: false })  
                }
            }
        } else {
            resetCreditCardFields()
            setIsDisabledForNonCard(true) // enable non-cards objects
            setIsCreditSecurityCodeDisabled(true)
        }

        setIsDisabledForCompanyGateway(companyPaymentGateway === type ? false : true)

        setPaymentData(prevPaymentData => ({ ...prevPaymentData, paymentType: type })) // set payment type to send to backend API 

        if (companyPaymentGateway === type) {
            setRefCodeLabel("CCAUTHCODE")
        } else if (foundPaymentType?.PaymentTypeCategory?.name == 'Check') {
            setRefCodeLabel("CHECKNUM")
        } else{
            setRefCodeLabel("REFCODE")
        }
    }

    const renderCardLogo = () => {
        switch (paymentData.cardType) {
            case 'visa': return <img className='h-42 w-45px' src={VISALogo} />
            case 'american_express': return <img className='h-42 w-45px' src={AMEXLogo} />
            case 'discover': return <img className='h-42 w-45px' src={DISCOVERLogo} />
            case 'master': return <img className='h-42 w-45px' src={MCLogo} />
        } 
    }

    useEffect(() => {
        handleCreditCardChange(entrySecuredPaymentMethodId)
    }, [entryPaymentMethods, entrySecuredPaymentMethodId])


    const entryPaymentMethodOptions = entryPaymentMethods.map((card) => ({ label: card.dropdown_label, value: card.payment_method_id }))

    return (
        <div className='my-3 mx-2'>
            <form noValidate className='form' onSubmit={e => e.preventDefault()}>
                <div className='card-body p-0 row'>
                    <div className="col-lg-6 mb-2">
                        <div className='row mb-2 d-none'>
                            <label className='col-form-label fs-5 py-1 w-lg-175px'> {intl.formatMessage({ id: "FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.TYPE" })}</label>

                            <div className='w-lg-25px form-check-sm form-check-custom ps-lg-3'>
                                <input
                                    className='form-check-input'
                                    type="radio"
                                    id="Payment" 
                                    checked={type === "Payment"} 
                                    onChange={() => setType("Payment")}
                                />
                            </div>
                            <label 
                                className='col-form-label fs-5 py-1 w-lg-125px'
                                htmlFor="Payment"
                            >
                                Payment
                            </label>
                            
                            <div className='w-lg-25px form-check-sm form-check-custom ps-lg-3'>
                                <input
                                    className='form-check-input'
                                    type="radio"
                                    id="OpenCheck"  
                                    checked={type === "OpenCheck"} 
                                    onChange={() => setType("OpenCheck")}
                                />
                            </div>
                            <label 
                                className='col-form-label fs-5 py-1 w-lg-175px' htmlFor="OpenCheck"
                            >
                                Open Check/PO
                            </label>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-form-label fs-5 py-1 w-lg-175px' htmlFor="paymentType" data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.APPLYPAYMENTS.LABEL.PAYMENTTYPE">
                                {intl.formatMessage({ id: "ENTRIES.APPLYPAYMENTS.MODAL.LABEL.PAYMENTTYPE" })}
                            </label>
                            <div className='col'>
                                <Select
                                    options={paymentTypes}
                                    style={reactSelectStyles}
                                    onChange={onPaymentTypeChange}
                                    theme={(theme) => ({...theme, borderRadius: 0})}
                                    autoFocus
                                    filterOption={(option, inputValue) =>
                                        option?.label?.toLowerCase()?.startsWith(inputValue?.toLowerCase())
                                    }
                                />
                            </div>
                        </div>
                        
                        {(!isDisabledForCompanyGateway) &&
                        <>
                            <div className='row mb-2'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-175px'></label>
                                <div className='col'>
                                    {/* <Select 
                                        onChange={handleCreditCardChange}
                                        options={entryPaymentMethodOptions}
                                        value={entryPaymentMethodOptions.find((card) => card.value == paymentData.selectedCardId) ?? {}}
                                        style={reactSelectStyles}
                                        isDisabled={isDisabledForNonCard}
                                        theme={(theme) => ({...theme, borderRadius: 0})}
                                        placeholder='Select Card'
                                    /> */}
                                    <select name='selectedCardId'
                                        className='form-select form-select-sm fs-6 min-h-20px py-1' 
                                        onChange={e => handleCreditCardChange(e.target.value)} 
                                        disabled={isDisabledForNonCard}
                                        placeholder='Select Card'
                                    >
                                        { entryPaymentMethodOptions.map((pm) => <option value={pm.value} selected={pm.value == paymentData.selectedCardId}>{pm.label}</option>) }
                                    </select>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-1 col-form-label fs-5 py-1  w-lg-175px' htmlFor='CREDITCARDNUM' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.APPLYPAYMENTS.LABEL.CREDITCARDNUMBER">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.CREDITCARDNUM' })}</label>
                                <div className='col pe-0'>
                                    <input
                                        value={paymentData.creditCardNum}
                                        id='CREDITCARDNUM'
                                        type='text'                                    
                                        className='form-control form-control-sm fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.CREDITCARDNUM' })}
                                        disabled={!(isEnabledForOtherCardType)}
                                        onBlur={onCreditCardNumberBlur}
                                        onFocus={onCreditCardNumberFocus}
                                        onChange={e => setPaymentData(prevPaymentData => ({
                                            ...prevPaymentData,
                                            creditCardNum: e.target.value
                                        }))}
                                    />
                                </div>
                                <div className='w-lg-65px text-end'>
                                    {renderCardLogo()}
                                </div>
                            </div>
                            <div className="row mb-2">   
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-175px' htmlFor='EXPIRATIONDATE' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.APPLYPAYMENTS.LABEL.EXPIRTATIONDATE">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.EXPIRATIONDATE' })}</label>
                                <div className='col-lg-2'>
                                    <PatternFormat
                                        valueIsNumericString={true}
                                        value={paymentData.creditCardExp}
                                        id="expiration"
                                        format="##/##"
                                        onValueChange={(e)=> setPaymentData(prevPaymentData => ({...prevPaymentData, creditCardExp: e.value}))}
                                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.PEOPLE.MODAL.LABEL.EXPIRATIONFORMAT' })}
                                        disabled={!(isEnabledForOtherCardType)}
                                    />
                                </div>
                                <label className='col-form-label fs-5 py-1 w-lg-175px' htmlFor='CREDITSECURITYCODE' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.APPLYPAYMENTS.LABEL.CREDITSECURITYCODE">
                                    { intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.CREDITSECURITYCODE' }) }
                                </label>
                                <div className='col-lg-2'>   
                                    <NumericFormat
                                        id="CREDITSECURITYCODE"
                                        value={paymentData.creditCardCVV}
                                        placeholder='CVV'
                                        className='form-control form-control-sm fs-6 min-h-20px py-1'
                                        type="text"
                                        valueIsNumericString={true} // Allow only numeric input
                                        allowNegative={false} // disallow negative values
                                        onValueChange={e=>
                                            setPaymentData(prevPaymentData => ({...prevPaymentData, creditCardCVV: e.value}))
                                        }
                                        isAllowed={({ floatValue }) =>  floatValue ? floatValue < 10000 : true} // Max 4 digit
                                        disabled={isCreditSecurityCodeDisabled}
                                        allowLeadingZeros={true}
                                    />                                 
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-form-label fs-5 py-1 w-lg-175px' htmlFor='NAMEONCARD' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.APPLYPAYMENTS.LABEL.NAMEONCARD">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.NAMEONCARD' })}</label>
                                <div className='col'>
                                    <input
                                        onChange={e => setPaymentData(prevPaymentData => ({
                                            ...prevPaymentData, creditCardName: e.target.value
                                        }))}
                                        value={paymentData.creditCardName}
                                        id='NAMEONCARD'
                                        type='text'
                                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.NAMEONCARD' })}                                    
                                        disabled={!isEnabledForOtherCardType}
                                    />
                                </div>
                            </div>
                            
                            <div className='row force_cit_row mb-2'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-175px'>&nbsp;</label>
                                <div className='col form-check-sm form-check-custom ps-lg-3'>
                                    <input
                                        onChange={e => setPaymentData(prevPaymentData => ({
                                            ...prevPaymentData, forceCIT: e.target.checked
                                        }))}
                                        className='form-check-input'
                                        type='checkbox'
                                        id='FORCECIT'
                                        checked={paymentData.forceCIT}
                                    />
                                    <label className='col-form-label mx-2 fs-5 py-1' htmlFor='FORCECIT' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.APPLYPAYMENTS.LABEL.FORCECIT"> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.FORCECIT' })}</label>
                                </div>
                            </div>
                            
                            { hasCiTWarning && 
                            <div className='row cit_warning mb-2'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-175px'>&nbsp;</label>
                                <div className='col'>
                                    <label className='text-danger'>{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.CITWARNING' })} </label>
                                </div>
                            </div>
                            }
                        </>
                        }

                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-175px' htmlFor='paymentAmount' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.APPLYPAYMENTS.LABEL.AMOUNT">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.AMOUNT' })}</label>
                            <div className='w-lg-250px pe-0'>
                                    <NumericFormat
                                    id="paymentAmount"
                                    value={amount}
                                    className='form-control form-control-sm fs-6 min-h-20px py-1'
                                    type="text"
                                    valueIsNumericString={true} // Allow only numeric input
                                    onValueChange={e => setAmount(e.value)}
                                    onBlur={e=> recalculate(true, amount)}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    prefix={intl.formatMessage({ id: `CURRENCY.CODE.SYMBOL.${currencyCode}`})}
                                    allowNegative={false} // disallow negative values
                                />             
                            </div>
                            <div className='col-lg-1'>
                                <button
                                    onBlur={() => document.getElementById(refCodeLabel)?.focus()}
                                    onClick={autoDistribute} 
                                    className='btn btn-secondary p-5 py-1 w-lg-50px fw-bold'
                                    type="button"> 
                                    <i className="fs-3 fa-solid fa-arrow-left-long mb-1"></i>
                                </button>
                            </div>     
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-175px' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.APPLYPAYMENTS.LABEL.SERVICEFEE"> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.SERVICEFEE' })}</label>
                            <label className='col col-form-label fs-5 py-1'>
                                { currencyFormatter(serviceFee) }
                            </label>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-175px' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.APPLYPAYMENTS.LABEL.TOTALAMOUNT"> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.TOTALAMOUNT' })}
                            </label>
                            <label className='col col-form-label fs-5 py-1'>
                                { currencyFormatter(totalAmount) }
                            </label>
                        </div>
                        <div className={'row mb-2' + (isDisabledForCompanyGateway ? ' d-none' : '')}>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-175px' htmlFor='WHOSECARD' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.APPLYPAYMENTS.LABEL.WHOSECARDISTHIS">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.WHOSECARD' })}</label>
                            <div className='col'>
                                <Select 
                                    inputId="WHOSECARD" 
                                    isDisabled={!isEnabledForOtherCardType}
                                    options={cardOwnerOptions}
                                    value={selectedCardOwner}
                                    onChange={e => setSelectedCardOwner(e)}
                                    theme={(theme) => ({...theme, borderRadius: 0})}
                                />
                            </div>
                            <div className='col-lg-1 form-check-sm form-check-custom ps-lg-3' style={{ width: '146px' }}>
                                <input
                                    onChange={e => setPaymentData(prevPaymentData => ({
                                        ...prevPaymentData, RetainCard: e.target.checked
                                    }))}
                                    checked={paymentData.RetainCard}
                                    className='form-check-input'
                                    type='checkbox'
                                    id='STORETHISCARD'
                                    disabled={!isEnabledForOtherCardType}
                                />
                                <label className='col-form-label mx-2 fs-5 py-1' htmlFor='STORETHISCARD' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.APPLYPAYMENTS.LABEL.STORETHISCARD"> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.STORETHISCARD' })}</label>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6'> 
                        <fieldset className={'mb-2' + (isDisabledForCompanyGateway ? ' d-none' : '')}>
                            <legend className="fs-5 fw-bold">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.GROUPLABEL.BILLINGADDRESS' })}</legend>
                            <div className='row mb-2'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px' htmlFor='ADDRESSONE' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.APPLYPAYMENTS.SECTION.BILLINGADDRESS.LABEL.ADDRESS1">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.ADDRESSONE' })}</label>
                                <div className='col'>
                                    <input
                                        onChange={e => setPaymentData(prevPaymentData => ({
                                            ...prevPaymentData, addr1: e.target.value
                                        }))}
                                        value={paymentData.addr1}
                                        id='ADDRESSONE'
                                        type='text'
                                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.ADDRESSONE' })}
                                    />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px' htmlFor='ADDRESSTWO' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.APPLYPAYMENTS.SECTION.BILLINGADDRESS.LABEL.ADDRESS2">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.ADDRESSTWO' })}</label>
                                <div className='col'>
                                    <input
                                        onChange={e => setPaymentData(prevPaymentData => ({
                                        ...prevPaymentData, addr2: e.target.value
                                        }))}
                                        value={paymentData.addr2}
                                        id='ADDRESSTWO'
                                        type='text'
                                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.ADDRESSTWO' })}
                                    />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px' htmlFor='CITY' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.APPLYPAYMENTS.SECTION.BILLINGADDRESS.LABEL.CITY">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.CITY' })}</label>
                                <div className='col'>
                                    <input                                        
                                        onChange={e => setPaymentData(prevPaymentData => ({
                                            ...prevPaymentData, cityname: e.target.value
                                        }))}
                                        value={paymentData.cityname}
                                        id='CITY'
                                        type='text'
                                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.CITY' })}
                                    />
                                </div>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-35px px-0' htmlFor='STATE' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.APPLYPAYMENTS.SECTION.BILLINGADDRESS.LABEL.STATE">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.STATE' })}</label>
                                <div className='col'>
                                    <Select 
                                        inputId="STATE" 
                                        options={listOfStates}
                                        onChange={e => setPaymentData(prevPaymentData => ({
                                            ...prevPaymentData, state: e.value
                                        }))}
                                        theme={(theme) => ({...theme, borderRadius: 0})}
                                        styles={reactSelectStyles}
                                        value={listOfStates.find((option) => option.value == paymentData.state)}
                                    />
                                </div>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-25px px-0' htmlFor='ZIP' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.APPLYPAYMENTS.SECTION.BILLINGADDRESS.LABEL.ZIP">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.ZIP' })}</label>
                                <div className='col'>
                                    <input
                                        onChange={e => setPaymentData(prevPaymentData => ({
                                        ...prevPaymentData, zip: e.target.value
                                        }))}
                                        value={paymentData.zip}
                                        id='ZIP'
                                        type='text'
                                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.ZIP' })}
                                    />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px' htmlFor='COUNTRY' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.APPLYPAYMENTS.SECTION.BILLINGADDRESS.LABEL.COUNTRY">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.COUNTRY' })}</label>
                                <div className='col-lg-4'>
                                    <Select 
                                        inputId="COUNTRY" 
                                        options={listOfCountries}
                                        onChange={e => setPaymentData(prevPaymentData => ({
                                            ...prevPaymentData, country: e.value
                                        }))}
                                        theme={(theme) => ({...theme, borderRadius: 0})}
                                        styles={reactSelectStyles}
                                        value={listOfCountries.find((option) => option.value == paymentData.country)}
                                    />
                                </div>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-75px' htmlFor='PHONE' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.APPLYPAYMENTS.SECTION.BILLINGADDRESS.LABEL.PHONE">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.PHONE' })}</label>
                                <div className='col'>
                                    {/* <input
                                        onChange={e => setPaymentData(prevPaymentData => ({
                                        ...prevPaymentData, phoneNumber: e.target.value
                                        }))}
                                        value={paymentData.phoneNumber}
                                        id='PHONE'
                                        type='text'
                                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.PHONE' })}
                                    /> */}
                                    <PatternFormat
                                        id='PHONE'
                                        format="(###) ###-####"
                                        value={
                                            paymentData.phoneNumber === "" ? "" : Number(paymentData.phoneNumber)
                                        }
                                        onValueChange={e => setPaymentData(prevPaymentData => ({...prevPaymentData, phoneNumber: e.value}))}
                                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.PHONE' })}
                                    />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px' htmlFor='EMAIL' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.APPLYPAYMENTS.SECTION.BILLINGADDRESS.LABEL.EMAIL">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.EMAIL' })}</label>
                                <div className='col'>
                                    <input
                                        onChange={e => setPaymentData(prevPaymentData => ({
                                            ...prevPaymentData, email: e.target.value
                                        }))}
                                        value={paymentData.email}
                                        id='EMAIL'
                                        type='text'
                                        className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                        placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.EMAIL' })}
                                    />
                                </div>
                                <div className='w-lg-10px form-check-sm form-check-custom ps-lg-2'>
                                    <input
                                        className='form-check-input'
                                        type='checkbox'
                                        id='UPDATERTO'
                                        onChange={e => setPaymentData(prevPaymentData => ({
                                            ...prevPaymentData, updateRTO: e.target.checked
                                        }))}
                                        checked={paymentData.updateRTO}
                                    />
                                </div>
                                <label className='w-lg-100px col-form-label fs-5 py-1 ms-2 px-2' htmlFor='UPDATERTO' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.APPLYPAYMENTS.SECTION.BILLINGADDRESS.LABEL.UPDATERTO"> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.UPDATERTO' })}</label>
                            </div>
                        </fieldset>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px' htmlFor={refCodeLabel} data-tooltip-id={`ENTRIES.DETAIL.QUICKACTION.APPLYPAYMENTS.LABEL.${refCodeLabel}`}>{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.' + refCodeLabel })}</label>
                            <div className='col'>
                                <input
                                    onChange={e => setPaymentData(prevPaymentData => ({
                                        ...prevPaymentData, newPaymentCode: e.target.value
                                    }))}
                                    value={paymentData.newPaymentCode}
                                    id={refCodeLabel}
                                    type='text'
                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.' + refCodeLabel })}
                                />
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px' htmlFor='DESCRIPTION' data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.APPLYPAYMENTS.LABEL.DESCRIPTION">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.DESCRIPTION' })}</label>
                            <div className='col'>
                                <textarea
                                    onChange={e => setPaymentData(prevPaymentData => ({
                                        ...prevPaymentData, newPaymentDescription: e.target.value
                                    }))}
                                    value={paymentData.newPaymentDescription}
                                    id="DESCRIPTION"
                                    rows={4}
                                    className='form-control h-100 form-control-sm fs-6'
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <EntriesGrid 
                        info={info}
                        entriesWithBalanceOnly={entriesWithBalanceOnly}
                        setEntriesWithBalanceOnly={setEntriesWithBalanceOnly}
                        checkAll={checkAll}
                        setCheckAll={setCheckAll}
                        setForceReapplySplit={setForceReapplySplit}
                        gridRef={gridRef}
                        setGridRef={setGridRef}
                        trigger={trigger}
                        calculateCreditCardServiceFee={calculateCreditCardServiceFee}
                        updateInfo={updateInfo}
                        updateIncludeForAllEntries={updateIncludeForAllEntries}
                        recalculate={recalculate}
                        paymentTypes={paymentTypes}
                        paymentTypeId={paymentTypeId}
                        autoApplyPrizeMoney={autoApplyPrizeMoney}
                        setAutoApplyPrizeMoney={setAutoApplyPrizeMoney}
                        autoApplyPrizeMoneyPayment={autoApplyPrizeMoneyPayment}
                        setAutoApplyPrizeMoneyPayment={setAutoApplyPrizeMoneyPayment}
                        calledFrom={calledFrom}
                        getAvailablePrizeMoneyClass={getAvailablePrizeMoneyClass}
                        recalculatePrizeMoney={recalculatePrizeMoney}
                        recalPrizeMoney={recalPrizeMoney}
                        setRecalPrizeMoney={setRecalPrizeMoney}
                    />
                </div>

                <div className='card-footer d-flex justify-content-end py-3 px-0 align-items-center'>
                    {
                        calledFrom == 'Apply Payments' ?
                            // Info label for prize money  
                            <div className='row'>
                                <div className='d-flex align-items-center'>
                                    <div className='me-2' style={{ width:'35px',height:'7px', backgroundColor: '#ff8c81' }}></div>
                                    <label className='col-form-label fs-5 py-0'>
                                        {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.ERROR' })}
                                    </label>
                                </div>

                                <div className='d-flex align-items-center'>
                                    <div className='me-2' style={{ width:'35px',height:'7px', backgroundColor: '#dba707' }}></div>
                                    <label className='col-form-label fs-5 py-0'>
                                        {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.ACCOUNT.MODAL.ADDPAYMENT.LABEL.WARNING' })}
                                    </label>
                                </div>
                            </div>
                        : null
                    }
                    
                    <button type='button' className='btn btn-sm btn-secondary me-4 fw-bold' style={{ marginLeft: "auto" }} onClick={handleCustomClose} id='ApplyPaymentCloseButton'>
                        {intl.formatMessage({ id: 'FORM.INPUT.COMMON.BUTTON.CLOSE' })}
                    </button>
                    <button 
                        type='button' 
                        className='btn btn-sm btn-dark fw-bold text-uppercase' 
                        onClick={validatePayments} 
                        id='ApplyPaymentSubmitButton'
                        data-tooltip-id="ENTRIES.DETAIL.QUICKACTION.APPLYPAYMENTS.BUTTON.APPLY"
                    >
                        <span className="spinner-border spinner-border-sm d-none me-2"></span>
                        {intl.formatMessage({ id: 'FORM.INPUT.COMMON.BUTTON.APPLY' })}
                    </button>
                </div>
            </form> 
        </div>
    )
}

export default PaymentTab