import {useState, memo, useEffect} from 'react'
import '../barn-designer.css'
import {
  Square,
  Circle as CircleIcon,
  Triangle,
  Type,
  Trash2,
  Copy,
  FlipHorizontal,
  FlipVertical,
  Minus,
  Save,
  SaveAll,
  Upload,
  Redo,
  Lock,
  FilePlus,
  LockOpen,
  ChevronUp,
  ChevronDown,
  ArrowUpToLine,
  ArrowDownToLine,
  Layers,
  FileEdit,
  Undo,
  RectangleHorizontal,
  RectangleVertical,
  Palette,
} from 'lucide-react'
import {set} from 'react-hook-form'

function MultipleElementSelectionPanel({
  setCanvasElements,
  selectedElement,
  canvasElements,
  getStallTextArea,
  getStallPoints,
  selectedShapeRef,
  lastAisleNumber,
  setLastAisleNumber,
  totalVerticalAisles,
  handleSelect,
  setLastStallNumber,
  setTotalVerticalAisles,
  totalHorizontalAisles,
  setTotalHorizontalAisles,
  lastStallNumber,
  stageRef,
  deleteElement,
  selectedElements,
  moveElementUp,
  moveElementDown,
  moveElementToFront,
  moveElementToBack,
  setSelectedElements,
  transformerRef,
  handleOnDuplicate,
  handleOnDelete,
  handleOnUnGroup,
  handleOnGroup,
  isGrouped,
}) {
  const [initalElementText, setInitalElementText] = useState('')
  const [initialFontSize, setInitialFontSize] = useState(1)
  const [initialStrokeWidth, setInitalStrokeWidth] = useState(1)
  const [initialLineWidth, setInitialLineWidth] = useState(1)

  useEffect(() => {
    setInitalElementText(element.children?.find((child) => child.type === 'text')?.text || '')
    setInitialFontSize(
      element.isGroup
        ? element.children[0].children?.find((child) => child.type === 'text')?.fontSize || 1
        : element.children?.find((child) => child.type === 'text')?.fontSize || 1
    )
    setInitalStrokeWidth(
      element.isGroup
        ? element.children[0].children?.[0]?.strokeWidth || 1
        : element.children?.[0]?.strokeWidth || 1
    )

    setInitialLineWidth(
      element.isGroup
        ? element.children[0].children?.[0]?.strokeWidth || 1
        : element.children?.[0]?.strokeWidth || 1
    )
  }, [selectedElement, selectedElements])

  const element = canvasElements.find((el) => el.id === selectedElement)

  if (!element) {
    return null
  }

  // --------------------------- IMPORTANT ELEMENTS CHECK ---------------------------//
  const isStandaloneLine =
    element?.children?.[0]?.type === 'line' && !element.isStall && element.shapeType !== 'triangle'
  const isTextElement = element.children?.length === 1 && element.children[0].type === 'text'
  const isBasicShape =
    element.children?.[0]?.type === 'rectangle' ||
    element.children?.[0]?.type === 'circle' ||
    (element.children?.[0]?.type === 'line' && element.shapeType === 'triangle')
  const isTopBottomStall =
    element.isStall && (element.orientation === 'top' || element.orientation === 'bottom')
  const isLeftRightStall =
    element.isStall && (element.orientation === 'left' || element.orientation === 'right')

  const handleContainerClick = (e) => {
    e.stopPropagation()
  }

  const ColorPickerExpanded = ({onColorChange, initialColor = '#333333'}) => {
    const [selectedColor, setSelectedColor] = useState(initialColor)

    const colors = [
      '#333333', // Dark gray
      '#e9edef', // Default gray
      '#FFB6C1', // Light pink
      '#ADD8E6', // Light blue
      '#98FB98', // Pale green
      '#DDA0DD', // Plum
      '#F0E68C', // Khaki
      '#E6E6FA', // Lavender
      '#FFDAB9', // Peach
      '#D3D3D3', // Light gray
      '#FFFFFF', // White
      '#90EE90', // Light green
      '#FFA07A', // Light salmon
      '#f6f6f6', // Very Light gray
      '#86dcc2', // Alt Green
      '#cbc8f7', // Light Purple
    ]

    const handleColorClick = (color) => {
      setSelectedColor(color)
      onColorChange(color)
    }

    return (
      <div
        style={{
          marginTop: '4px',
          padding: '4px',
          background: 'white',
          borderRadius: '4px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          gap: '4px',
          width: '170px',
        }}
      >
        {colors.map((color) => (
          <button
            key={color}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              handleColorClick(color)
            }}
            style={{
              width: '24px',
              height: '24px',
              backgroundColor: color,
              border: `2px solid ${color === selectedColor ? '#333' : '#ddd'}`,
              borderRadius: '4px',
              cursor: 'pointer',
            }}
            title={color}
            onMouseDown={(e) => e.stopPropagation()}
          />
        ))}
      </div>
    )
  }

  // ===========================HANDLE FILL COLOR CHANGE =================================//
  const handleFillColorChange = (color) => {
    setCanvasElements((prev) =>
      prev.map((el) => {
        if (selectedElements.includes(el.id)) {
          if (el.isGroup) {
            // Handle grouped elements
            // return {
            //   ...el,
            //   children: el.children.map((groupChild) => ({
            //     ...groupChild,
            //     children: groupChild.children?.map((child) => {
            //       console.log("🚀 ~ children:groupChild.children?.map ~ child:", child)

            //       if (child.type === 'line' && child.closed) {
            //         return {...child, fill: color}
            //       }
            //       if (['rectangle', 'circle'].includes(child.type)) {
            //         return {...child, fill: color}
            //       } else if (
            //         child.type === 'text' &&
            //         (el.isGroup
            //           ? el.children.every(
            //               (child) =>
            //                 (child.isStall === undefined || child.isStall === false) &&
            //                 (child.isLine === undefined || child.isLine === false) &&
            //                 (child.isAisle === undefined || child.isAisle === false)
            //             )
            //           : (el.isStall === undefined || el.isStall === false) &&
            //             (el.isLine === undefined || el.isLine === false) &&
            //             (el.isAisle === undefined || el.isAisle === false))
            //       ) {
            //         return {...child, fill: color}
            //       }
            //       return child
            //     }),
            //   })),
            // }
            return {
              ...el,
              children: el.children.map((groupChild) => {
                if (
                  groupChild.id.startsWith('text-element-') &&
                  groupChild.children[0].type === 'text' &&
                  groupChild.children.length === 1
                ) {
                  return {
                    ...groupChild,
                    children: groupChild.children.map((child) => {
                      if (child.type === 'text') {
                        return {...child, fill: color}
                      }
                      return child
                    }),
                  }
                }
                return {
                  ...groupChild,
                  children: groupChild.children?.map((child) => {
                    if (child.type === 'line' && child.closed) {
                      return {...child, fill: color}
                    }
                    if (['rectangle', 'circle'].includes(child.type)) {
                      return {...child, fill: color}
                    }
                    return child
                  }),
                }
              }),
            }
          } else {
            // Handle non-grouped elements
            return {
              ...el,
              children: el.children.map((child) => {
                if (child.type === 'line' && child.closed) {
                  return {...child, fill: color}
                }
                if (['rectangle', 'circle'].includes(child.type)) {
                  return {...child, fill: color}
                } else if (
                  child.type === 'text' &&
                  (el.isStall === undefined || el.isStall === false) &&
                  (el.isLine === undefined || el.isLine === false) &&
                  (el.isAisle === undefined || el.isAisle === false) &&
                  !el.id.startsWith('element-')
                ) {
                  return {...child, fill: color}
                }

                return child
              }),
            }
          }
        }
        return el
      })
    )
  }

  // =================== RELATED TO THE BORDER CONTROL =======================//
  const handleStrokeWidth = (strokeWidth) => {
    if (strokeWidth > 0 && !isNaN(strokeWidth)) {
      setCanvasElements((prev) =>
        prev.map((el) => {
          if (selectedElements.includes(el.id)) {
            if (el.isGroup) {
              return {
                ...el,
                children: el.children.map((groupChild) => ({
                  ...groupChild,
                  children: groupChild.children?.map((child) => {
                    if (child.type !== 'text') {
                      return {
                        ...child,
                        strokeWidth: strokeWidth,
                      }
                    } else if (
                      child.type === 'text' ||
                      (child.type === 'group' &&
                        (el.isGroup
                          ? el.children.every(
                              (child) =>
                                (child.isStall === undefined || child.isStall === false) &&
                                (child.isLine === undefined || child.isLine === false) &&
                                (child.isAisle === undefined || child.isAisle === false)
                            )
                          : (el.isStall === undefined || el.isStall === false) &&
                            (el.isLine === undefined || el.isLine === false) &&
                            (el.isAisle === undefined || el.isAisle === false)))
                    ) {
                      return {...child, strokeWidth: strokeWidth}
                    }
                    return child
                  }),
                })),
              }
            } else {
              return {
                ...el,
                children: el.children.map((child) => {
                  if (child.type !== 'text') {
                    return {
                      ...child,
                      strokeWidth: strokeWidth,
                    }
                  } else if (
                    child.type === 'text' &&
                    (el.isStall === undefined || el.isStall === false) &&
                    (el.isLine === undefined || el.isLine === false) &&
                    (el.isAisle === undefined || el.isAisle === false)
                  ) {
                    return {
                      ...child,
                      strokeWidth: strokeWidth,
                    }
                  }
                  return child
                }),
              }
            }
          }
          return el
        })
      )
    }
  }

  const BorderColorExpandedPallete = ({initialColor = '#333333', onBorderChange}) => {
    const [selectedColor, setSelectedColor] = useState(initialColor)
    const colors = [
      '#333333', // Dark gray
      '#666666', // Medium gray
      '#999999', // Light gray
      '#000000', // Black
      '#1a237e', // Dark blue
      '#4CAF50', // Green
      '#f44336', // Red
      '#2196F3', // Blue
      '#FF9800', // Orange
      '#9C27B0', // Purple
      '#795548', // Brown
      '#607D8B', // Blue gray
    ]
    const handleColorClick = (color) => {
      setSelectedColor(color)
      onBorderChange(color)
    }

    return (
      <div
        style={{
          marginTop: '4px',
          padding: '4px',
          background: 'white',
          borderRadius: '4px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          gap: '4px',
          width: '170px',
        }}
      >
        {colors.map((color) => (
          <button
            key={color}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              handleColorClick(color)
            }}
            style={{
              width: '24px',
              height: '24px',
              backgroundColor: color,
              border: `2px solid ${color === selectedColor ? '#333' : '#ddd'}`,
              borderRadius: '4px',
              cursor: 'pointer',
            }}
            title={color}
            onMouseDown={(e) => e.stopPropagation()}
          />
        ))}
      </div>
    )
  }

  // =================== HANDLE STROKE COLOR CHANGE =======================//
  const handleOnChangeStrokeColor = (color) => {
    if (color && color !== '') {
      setCanvasElements((prev) =>
        prev.map((el) => {
          if (selectedElements.includes(el.id)) {
            if (el.isGroup) {
              // return {
              //   ...el,
              //   children: el.children.map((groupChild) => ({
              //     ...groupChild,
              //     children: groupChild.children?.map((child) => {
              //       if (child.type !== 'text') {
              //         return {
              //           ...child,
              //           stroke: color,
              //         }
              //       } else if (
              //         child.type === 'text' &&
              //         (el.isGroup
              //           ? el.children.every(
              //               (child) =>
              //                 (child.isStall === undefined || child.isStall === false) &&
              //                 (child.isLine === undefined || child.isLine === false) &&
              //                 (child.isAisle === undefined || child.isAisle === false)
              //             )
              //           : (el.isStall === undefined || el.isStall === false) &&
              //             (el.isLine === undefined || el.isLine === false) &&
              //             (el.isAisle === undefined || el.isAisle === false))
              //       ) {
              //         return {...child, stroke: color}
              //       }
              //       return child
              //     }),
              //   })),
              // }
              return {
                ...el,
                children: el.children.map((groupChild) => {
                  if (
                    groupChild.id.startsWith('text-element-') &&
                    groupChild.children[0].type === 'text' &&
                    groupChild.children.length === 1
                  ) {
                    return {
                      ...groupChild,
                      children: groupChild.children.map((child) => {
                        if (child.type === 'text') {
                          return {...child, stroke: color}
                        }
                        return child
                      }),
                    } 
                  }

                  return {
                    ...groupChild,
                    children: groupChild.children?.map((child) => {
                      if (child.type === 'line' && child.closed) {
                        return {...child, stroke: color}
                      }
                      if (['rectangle', 'circle'].includes(child.type)) {
                        return {...child, stroke: color}
                      }
                      return child
                    }),
                  }
                }),
              }
            } else {
              return {
                ...el,
                children: el.children.map((child) => {
                  if (child.type !== 'text') {
                    return {
                      ...child,
                      stroke: color,
                    }
                  } else if (
                    child.type === 'text' &&
                    (el.isStall === undefined || el.isStall === false) &&
                    (el.isLine === undefined || el.isLine === false) &&
                    (el.isAisle === undefined || el.isAisle === false) &&
                    !el.id.startsWith('element-')
                  ) {
                    return {
                      ...child,
                      stroke: color,
                    }
                  }
                  return child
                }),
              }
            }
          }
          return el
        })
      )
    }
  }

  // =================== HANDLE FONT SIZE CHANGE =======================//
  const handleFontSizeChange = (fontSize) => {
    if (!isNaN(fontSize) && fontSize > 0) {
      setCanvasElements((prev) =>
        prev.map((el) => {
          if (selectedElements.includes(el.id)) {
            if (el.isGroup) {
              return {
                ...el,
                children: el.children.map((groupChild) => ({
                  ...groupChild,
                  children: groupChild.children?.map((child) => {
                    if (child.isStall) {
                      const scaleX = el.scaleX || 1
                      const scaleY = el.scaleY || 1
                      const textArea = el.isStall
                        ? getStallTextArea(el.width, el.height, el.orientation)
                        : el.isText
                        ? {
                            x: el.width / 2,
                            y: el.height / 2,
                            // width: el.width - 20,
                            // height: el.height - 20,
                            width: undefined,
                            height: undefined,
                            offsetX: (el.width - 20) / 2,
                            offsetY: (el.height - 20) / 2,
                          }
                        : {
                            x: el.width / 2,
                            y: el.height / 2,
                            width: el.width - 20,
                            height: el.height - 20,
                            offsetX: (el.width - 20) / 2,
                            offsetY: (el.height - 20) / 2,
                          }

                      return {
                        ...el,
                        children: el.children.map((child) => {
                          if (child.type === 'text') {
                            return {
                              ...child,
                              ...textArea,
                              fontSize: fontSize,
                              // scaleX: 1 / scaleX,
                              // scaleY: 1 / scaleY,
                            }
                          }
                          return child
                        }),
                      }
                    }

                    if (child.type === 'text') {
                      return {
                        ...child,
                        fontSize: fontSize,
                      }
                    }
                    return child
                  }),
                })),
              }
            } else {
              const scaleX = el.scaleX || 1
              const scaleY = el.scaleY || 1
              const textArea = el.isStall
                ? getStallTextArea(el.width, el.height, el.orientation)
                : el.isText
                ? {
                    x: el.width / 2,
                    y: el.height / 2,
                    // width: el.width - 20,
                    // height: el.height - 20,
                    width: undefined,
                    height: undefined,
                    offsetX: (el.width - 20) / 2,
                    offsetY: (el.height - 20) / 2,
                  }
                : {
                    x: el.width / 2,
                    y: el.height / 2,
                    width: el.width - 20,
                    height: el.height - 20,
                    offsetX: (el.width - 20) / 2,
                    offsetY: (el.height - 20) / 2,
                  }

              return {
                ...el,
                children: el.children.map((child) => {
                  if (child.type === 'text') {
                    return {
                      ...child,
                      ...textArea,
                      fontSize: fontSize,
                      // scaleX: 1 / scaleX,
                      // scaleY: 1 / scaleY,
                    }
                  }
                  return child
                }),
              }
            }
          }
          return el
        })
      )
    }
  }

  const handleLineWidthChange = (updatedLineWidth) => {
    if (updatedLineWidth && !isNaN(updatedLineWidth)) {
      setCanvasElements((prev) =>
        prev.map((el) => {
          if (selectedElements.includes(el.id)) {
            // Get the initial line template to use its base dash pattern
            const initialDash = [15, 15] // Original dash pattern from shapeTemplates
            const baseWidth = 5 // Original stroke width from shapeTemplates

            return {
              ...el,
              children: el.children.map((child) =>
                child.type === 'line'
                  ? {
                      ...child,
                      strokeWidth: updatedLineWidth,
                      // Scale dash pattern proportionally to width changes
                      dash: child.dash
                        ? initialDash.map((d) => d * (updatedLineWidth / baseWidth))
                        : undefined,
                    }
                  : child
              ),
            }
          }
          return el
        })
      )
    }
  }

  return (
    <div
      className='element-selection-panel editor-sidebar'
      onClick={handleContainerClick}
      onMouseDown={handleContainerClick}
      style={{
        position: 'absolute',
        right: '0px',
        top: '61px',
        padding: '15px',
        backgroundColor: 'white',
        borderRadius: '0px',
        boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
        width: '210px', // inherited from editor-sidebar as it has rule of !important 210px
        zIndex: 1000,
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        height: '100%',
      }}
    >
      {/* Font Size Increase Decrease */}
      {!selectedElements.some((el) => {
        if (el.startsWith('group-')) {
          // checking if the group's children include a line element based on id
          return element.children.some((child) => child.id && child.id.startsWith('line-'))
        }
        return el.startsWith('line-')
      }) && (
        <div className='editor-section '>
          <h5>
            <span
              style={{fontSize: '15px', fontWeight: 'normal'}}
              className='text-gray-600 mb-1 me-1'
            >
              Aa
            </span>{' '}
            Font Size
          </h5>
          <div>
            <input
              type='number'
              min={1}
              value={initialFontSize}
              onChange={(e) => {
                e.stopPropagation()
                e.preventDefault()
                const value = e.target.value
                // Remove any non-digit characters
                const validValue = value.replace(/[^\d]/g, '')
                // Allow empty input during typing
                if (validValue === '') {
                  setInitialFontSize('')
                  return
                }
                let updatedFontSize = parseInt(validValue, 10)
                updatedFontSize = updatedFontSize < 1 ? 1 : updatedFontSize
                setInitialFontSize(updatedFontSize)
                handleFontSizeChange(updatedFontSize)
              }}
              onBlur={(e) => {
                e.stopPropagation()
                e.preventDefault()
                const value = e.target.value
                // Allow empty input during typing
                if (value === '') {
                  setInitialFontSize(1)
                  return
                }
                const updatedFontSize = parseInt(e.target.value)
                handleFontSizeChange(updatedFontSize)
              }}
              onKeyDown={(e) => {
                // if arrow keys are pressed, up key +1 and down key -1

                if (e.key === 'ArrowUp') {
                  e.stopPropagation()
                  e.preventDefault()
                  const updatedFontSize = parseInt(initialFontSize) + 1
                  setInitialFontSize(updatedFontSize)
                  handleFontSizeChange(updatedFontSize)
                }
                if (e.key === 'ArrowDown') {
                  e.stopPropagation()
                  e.preventDefault()
                  const updatedFontSize = parseInt(initialFontSize) - 1
                  setInitialFontSize(updatedFontSize)
                  handleFontSizeChange(updatedFontSize)
                }
              }}
              step={1}
              style={{
                width: '100%',
                padding: '3px',
                paddingLeft: '6px',
                paddingRight: '6px',
                border: '1px solid #ccc',
                borderRadius: '4px',
              }}
            />
          </div>
        </div>
      )}

      {selectedElements.every(
        (el) => el.startsWith('line-')
        // ||
        // (el.startsWith('group-') &&
        // element.children.some((child) => child.id && child.id.startsWith('line-')))
      ) && <hr className='m-1' style={{borderColor: '#aaaaf6'}} />}

      {/* Show Line Width button only for lines group or multiple lines selected*/}
      {selectedElements.every(
        (el) => el.startsWith('line-')
        // ||
        // (el.startsWith('group-') &&
        // element.children.some((child) => child.id && child.id.startsWith('line-')))
      ) && (
        <div className='editor-section '>
          <h3>Line Width</h3>
          <div>
            <input
              type='number'
              min={1}
              value={initialLineWidth}
              onChange={(e) => {
                e.stopPropagation()
                e.preventDefault()
                const value = e.target.value
                // Allow empty input during typing
                if (value === '') {
                  setInitialLineWidth('')
                  return
                }
                const updatedLineWidth = parseInt(e.target.value)
                setInitialLineWidth(updatedLineWidth)
                handleLineWidthChange(updatedLineWidth)
              }}
              onBlur={(e) => {
                e.stopPropagation()
                e.preventDefault()
                const updatedLineWidth = parseInt(e.target.value)
                setInitialLineWidth(updatedLineWidth)
                handleLineWidthChange(updatedLineWidth)
              }}
              onKeyDown={(e) => {
                // if arrow keys are pressed, up key +1 and down key -1
                if (e.key === 'ArrowUp') {
                  e.stopPropagation()
                  e.preventDefault()
                  const updatedLineWidth = parseInt(initialLineWidth) + 1
                  setInitialLineWidth(updatedLineWidth)
                  handleLineWidthChange(updatedLineWidth)
                }
                if (e.key === 'ArrowDown') {
                  e.stopPropagation()
                  e.preventDefault()
                  const updatedLineWidth = parseInt(initialLineWidth) - 1
                  setInitialLineWidth(updatedLineWidth)
                  handleLineWidthChange(updatedLineWidth)
                }
              }}
              step={1}
              style={{
                width: '100%',
                padding: '4px',
                border: '1px solid #ccc',
                borderRadius: '4px',
              }}
            />
          </div>
        </div>
      )}

      {handleOnGroup !== null && <hr className='m-1' style={{borderColor: '#aaaaf6'}} />}

      {handleOnGroup !== null && (
        <div className='editor-section'>
          <h5>
            <Lock size={16} className='text-gray-600 mb-1 me-1' /> Group / Ungroup
          </h5>

          <div className='shape-grid' style={{display: 'block'}}>
            <button
              className='shape-button'
              onMouseDown={(e) => e.stopPropagation()}
              title={isGrouped ? 'Ungroup' : 'Group'}
              onClick={isGrouped ? handleOnUnGroup : handleOnGroup}
              style={{width: '100%', borderColor: '#d5d5d5'}}
            >
              <span style={{marginTop: '0px'}}>{isGrouped ? 'Ungroup' : 'Group'}</span>
            </button>
          </div>
        </div>
      )}

      {!selectedElements.some((el) => {
        if (el.startsWith('group-')) {
          // checking if the group's children include a line element based on id
          return element.children.some((child) => child.id && child.id.startsWith('line-'))
        }
        return el.startsWith('line-')
      }) && <hr className='m-1' style={{borderColor: '#aaaaf6'}} />}

      {/* Background Fill */}
      {
        // Show fill color picker only if the element is not a line
        !selectedElements.some((el) => {
          if (el.startsWith('group-')) {
            // checking if the group's children include a line element based on id
            return element.children.some((child) => child.id && child.id.startsWith('line-'))
          }
          return el.startsWith('line-')
        }) && (
          <div className='editor-section'>
            <h5>
              <Palette className='text-gray-600 mb-1 me-1' size={16} /> Background Color
            </h5>

            <div style={{width: '100%'}}>
              {/* Div showing currently filled color */}
              <ColorPickerExpanded
                initialColor={
                  element.isGroup
                    ? element.children?.[0]?.children?.[0]?.fill || '#e9edef'
                    : element.children?.[0]?.fill || '#e9edef'
                }
                onColorChange={handleFillColorChange}
              />
            </div>
          </div>
        )
      }

      {!isStandaloneLine && <hr className='m-1' style={{borderColor: '#aaaaf6'}} />}

      {!isStandaloneLine && (
        <div className='editor-section'>
          <h5 style={{marginBottom: '2px'}}>
            <RectangleHorizontal className='text-gray-600 mb-1 me-1' size={16} />
            Border Properties
          </h5>
        </div>
      )}

      {!isStandaloneLine && (
        <div className='editor-section '>
          <h6>
            <Minus className='text-gray-600 mb-1 me-1' size={16} /> Width
          </h6>
          <input
            type='number'
            value={initialStrokeWidth}
            onChange={(e) => {
              let value = e.target.value

              // Handle empty input
              if (value === '') {
                setInitalStrokeWidth('')
                return
              }

              // Convert to number and apply constraints
              let updatedStrokeWidth = parseInt(value.replace(/[^0-9]/g, ''))

              // Constrain between 1 and 20
              if (updatedStrokeWidth < 1) {
                updatedStrokeWidth = 1
              } else if (updatedStrokeWidth > 50) {
                updatedStrokeWidth = 50
              }

              setInitalStrokeWidth(updatedStrokeWidth)
              handleStrokeWidth(updatedStrokeWidth)
            }}
            onKeyDown={(e) => {
              // if arrow keys are pressed, up key +1 and down key -1

              if (e.key === 'ArrowUp') {
                e.stopPropagation()
                e.preventDefault()
                const updatedStrokeWidth = parseInt(initialStrokeWidth) + 1
                setInitalStrokeWidth(updatedStrokeWidth)
                handleStrokeWidth(updatedStrokeWidth)
              }
              if (e.key === 'ArrowDown') {
                e.stopPropagation()
                e.preventDefault()
                const updatedStrokeWidth = parseInt(initialStrokeWidth) - 1
                setInitalStrokeWidth(updatedStrokeWidth)
                handleStrokeWidth(updatedStrokeWidth)
              }
            }}
            step={1}
            style={{
              width: '100%',
              padding: '3px',
              paddingRight: '6px',
              paddingLeft: '6px',
              border: '1px solid #ccc',
              borderRadius: '4px',
            }}
          />
        </div>
      )}

      {!isStandaloneLine && (
        <div className='editor-section'>
          <h6>
            <Palette className='text-gray-600 mb-1 me-1' size={16} /> Color
          </h6>

          <BorderColorExpandedPallete
            // initialColor={element.children?.[0]?.stroke || '#f44336'}
            initialColor={
              element.isGroup
                ? element.children?.[0]?.children?.[0]?.stroke || '#333333'
                : element.children?.[0]?.stroke || '#333333'
            }
            onBorderChange={handleOnChangeStrokeColor}
          />
        </div>
      )}

      <hr className='m-1' style={{borderColor: '#aaaaf6'}} />

      {/* Other Operations */}
      <div className='editor-section'>
        <div className='shape-grid'>
          <button
            className='shape-button'
            onMouseDown={(e) => e.stopPropagation()}
            title='Duplicate'
            onClick={handleOnDuplicate}
          >
            <Copy size={24} />
            <span>Duplicate</span>
          </button>
          <button
            className='shape-button'
            style={{color: '#ff4444'}}
            onMouseDown={(e) => e.stopPropagation()}
            title='Delete'
            onClick={handleOnDelete}
          >
            <Trash2 size={24} />
            <span>Delete</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default memo(MultipleElementSelectionPanel)
