import {useIntl} from 'react-intl'
import {useAppSelector} from '../../redux/hooks'
import axios from 'axios'
import {useParams} from 'react-router-dom'
import {useEffect, useState} from 'react'
import {useForm, FormProvider} from 'react-hook-form'
import {useAlert, useLoadingOverlay} from '../../modules/sgl-utils/DialogsProvider'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../modules/auth'
import {HistoryTab} from '../../modules/components/HistoryTab'
import {ShowsTab} from './Detail/ShowsTab'
import {FacilityTab} from './Detail/FacilityTab'
import useAutoFocus from '../../modules/hooks/use-auto-focus'

// Custom Components
import {InputFormFooter} from '../../modules/sgl-utils/InputFormFooter'
import {isInvalidRecord} from '../../modules/sgl-utils/SglFunctions'
import {PageTitle} from '../../../_metronic/layout/core'
import history from '../../modules/sgl-utils/unstableHistory'
import useNavigationPrompt from '../../modules/hooks/use-navigation-prompt'
import {useConfirm} from '../../modules/sgl-utils/DialogsProvider'
import HelpLink from '../../modules/components/HelpLink'
import {StallsTab} from './Detail/StallsTab'
import useAccessChecker from '../../modules/hooks/use-access-checker'

const {PUBLIC_URL} = process.env

export const dirtyValues = (dirtyFields, allValues) => {
  // NOTE: Recursive function.

  // If *any* item in an array was modified, the entire array must be submitted, because there's no
  // way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
  if (dirtyFields === true || Array.isArray(dirtyFields)) {
    return allValues
  }

  // Here, we have an object.
  return Object.fromEntries(
    Object.keys(dirtyFields).map((key) => [key, dirtyValues(dirtyFields[key], allValues[key])])
  )
}

const BarnsDetail = (props) => {
  const intl = useIntl()
  const {barn_id} = useParams()
  const customer_id = useAppSelector((state) => state.showCompany.company_id)
  const alertDialog = useAlert()
  const navigate = useNavigate()
  const confirmDialog = useConfirm()
  const loadingOverlay = useLoadingOverlay()
  const [loading, setLoading] = useState(true)
  const user = useAuth()
  const methods = useForm({
    defaultValues: {
      barn: {
        name: '',
        active: '',
      },
    },
  })
  const designThumbnail = methods.getValues('designThumbnail')
  const [showLightbox, setShowLightbox] = useState(false)

  const tabs = ['STALLS', 'FACILITIES', 'SHOWS', 'HISTORY']
  const componentTags = [
    <StallsTab />,
    <FacilityTab />,
    <ShowsTab />,
    <HistoryTab area='Barns' rowData={methods.getValues('change_history_logs')} />,
  ]
  const [selectedTab, setSelectedTab] = useState(() => {
    const hash = window.location.hash.substring(1)
    return tabs.indexOf(hash) >= 0 && props?.id == undefined ? tabs.indexOf(hash) : 0
  })

  const {handleSubmit, reset, formState, setFocus} = methods

  const {dirtyFields} = formState
  useNavigationPrompt(formState)
  const row_selected = useAppSelector(
    (state) => state.currentDetailPageInfo.current_list_record_index
  )
  const row_ids = useAppSelector((state) => state.currentDetailPageInfo.list_ids)

  const {hasSpecialPermissionToAccess} = useAccessChecker()

  useEffect(() => {
    const next_button = document?.getElementById('next-btn')
    const last_button = document?.getElementById('last-btn')
    const prev_button = document?.getElementById('prev-btn')
    const first_button = document?.getElementById('first-btn')
    if (row_selected == row_ids?.length - 1 && prev_button && last_button) {
      next_button.disabled = true
      last_button.disabled = true
    }
    if (row_selected == 0 && prev_button && first_button) {
      prev_button.disabled = true
      first_button.disabled = true
    }
  }, [loading])

  const onSubmit = async (form_data) => {
    let submit_data = dirtyValues(dirtyFields, form_data)

    const next_button = document.getElementById('next-btn')
    const last_button = document.getElementById('last-btn')
    const prev_button = document.getElementById('prev-btn')
    const first_button = document.getElementById('first-btn')

    // means detail page is not open in modal
    if (props?.id === undefined) {
      next_button.disabled = false
      prev_button.disabled = false
      last_button.disabled = false
      first_button.disabled = false
    }

    if (barn_id == 0 && submit_data.barn.active === undefined) {
      submit_data.barn.active = true
    }

    loadingOverlay({show: true})
    return axios
      .post(process.env.REACT_APP_NEST_API_URL + '/barns/detail', {
        params: {
          barn_id,
          customer_id,
          data: submit_data,
          user_id: user?.currentUser?.id ? user?.currentUser?.id : 0,
        },
      })
      .then((response) => {
        loadingOverlay({show: false})
        if (response.data.success) {
          // Use Case (Param Id changed): Next/Prev buttons
          if (
            props?.id == undefined &&
            Number(methods.getValues('barn.barn_id')) !== Number(row_ids[row_selected]) &&
            !isNaN(Number(row_ids[row_selected]))
          ) {
            if (row_selected && row_selected != '' && row_ids[row_selected]) {
              setLoading(true)
              history.push(
                `${PUBLIC_URL}/barns/detail/${row_ids[row_selected]}?customer_id=${customer_id}${window.location.hash}`,
                {allowNavigation: true}
              )
            }
          } else if (props?.id == undefined && barn_id > 0) {
            history.push(`${PUBLIC_URL}/barns`, {allowNavigation: true})
          } else if (props?.id == undefined && barn_id == 0) {
            history.push(
              `${PUBLIC_URL}/saving?returnTo=` + encodeURIComponent(window.location.pathname),
              {allowNavigation: true}
            )
          }
          if (row_selected == row_ids?.length - 1 && props?.id === undefined) {
            if (next_button && last_button) {
              next_button.disabled = true
              last_button.disabled = true
              prev_button.disabled = false
              first_button.disabled = false
            }
          }
          if (row_selected == 0 && props?.id === undefined) {
            if (prev_button && first_button) {
              prev_button.disabled = true
              first_button.disabled = true
              next_button.disabled = false
              last_button.disabled = false
            }
          }
        } else {
          alertDialog({message: response.data.error_message, title: 'Error'})
        }
      })
  }

  useEffect(() => {
    // Show loading overlay
    loadingOverlay({show: true})
    axios
      .all([
        axios.get(process.env.REACT_APP_NEST_API_URL + '/barns/detail', {
          params: {
            barn_id,
            customer_id,
          },
        }),
      ])
      .then(
        axios.spread(({data: barnData}) => {
          //Check if record id is invalid then redirect to list page
          if ((barnData || barnData === '') && isInvalidRecord(barnData.barn, barn_id, 'barn_id')) {
            navigate('/barns')
            loadingOverlay({show: false})
          }

          if (barn_id == 0) {
            barnData['barn']['active'] = true
          }

          reset(barnData)
          // Hide loading overlay
          loadingOverlay({show: false})
          setLoading(false)
        })
      )
  }, [barn_id])

  useAutoFocus('NAME', loading)

  return (
    <>
      {!loading && (
        <>
          {props?.id == undefined ? (
            <PageTitle
              breadcrumbs={[]}
              description={[methods.watch('barn.name'), methods.getValues('barn.barn_id')]}
            >
              {intl.formatMessage({id: 'MENU.BARNS'})}
            </PageTitle>
          ) : (
            ''
          )}
          <FormProvider {...methods}>
            <div
              id={'barns_detail_page'}
              className='modal-body py-3 px-4 showfee-input-form input-form'
            >
              <form
                noValidate
                className='form max-width'
                onSubmit={handleSubmit(onSubmit)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && e.target.tagName === 'INPUT') {
                    e.preventDefault()
                  }
                }}
              >
                <HelpLink tooltip='BARNS.DETAIL.LINK.HELP' classname='top' />
                <div className='card-body p-0'>
                  {' '}
                  {/* Card Body */}
                  <div className='form-group row'>
                    <div className='row mb-2'>
                      {/* Column Group */}
                      <div className='col-lg-6'>
                        {/* Column 1 */}
                        <div className='row mb-2'>
                          <label
                            className='col-lg-2 col-form-label fs-5 fw-bold py-1'
                            htmlFor='NAME'
                            data-tooltip-id='BARNS.DETAIL.LABEL.NAME'
                          >
                            {intl.formatMessage({id: 'FORM.INPUT.BARN.LABEL.NAME'})}
                          </label>
                          <div className='col'>
                            <input
                              {...methods.register('barn.name', {
                                required: 'Barn name is required.',
                              })}
                              id='NAME'
                              type='text'
                              className='form-control form-control-sm  fs-6 min-h-20px py-1'
                            />
                            {methods.formState.errors?.barn?.name && (
                              <div>
                                <span className='error_message'>
                                  {methods.formState.errors?.barn.name?.message}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className='row mb-2'>
                          <label
                            className='col-lg-2 col-form-label fs-5 fw-bold py-1'
                            htmlFor='DESCRIPTION'
                            data-tooltip-id='BARNS.DETAIL.LABEL.DESCRIPTION'
                          >
                            {intl.formatMessage({id: 'FORM.INPUT.BARN.LABEL.DESCRIPTION'})}
                          </label>
                          <div className='col'>
                            <textarea
                              {...methods.register('barn.description')}
                              id='DESCRIPTION'
                              rows={3}
                              className='form-control form-control-sm fs-6 min-h-20px py-1'
                            ></textarea>
                          </div>
                        </div>
                        {/* End Column 1 */}
                      </div>
                      <div className='col-lg-3'>
                        {/* Column 2 */}
                        <div className='row mb-2'>
                          <div className='col-lg-8 form-check-sm form-check-custom ps-lg-3'>
                            <input
                              {...methods.register('barn.active')}
                              id='ACTIVE'
                              type='checkbox'
                              className='form-check-input'
                            />
                            <label
                              className='col-lg-2 col-form-label fs-5 fw-bold py-1'
                              htmlFor='ACTIVE'
                              style={{marginLeft: '10px'}}
                              data-tooltip-id='BARNS.DETAIL.LABEL.ACTIVE'
                            >
                              {intl.formatMessage({id: 'FORM.INPUT.BARN.LABEL.ACTIVE'})}
                            </label>
                          </div>
                        </div>

                        {hasSpecialPermissionToAccess('cloud_barns:barn-designer') && (
                          <div className='row mb-2'>
                            <div className='col-lg-8 form-check-sm form-check-custom ps-lg-3'>
                              <button
                                onClick={(e) => {
                                  const activeBarnDesignId = methods.getValues('barnDesignId')
                                  e.preventDefault()

                                  // activeBarnDesignId is -1 if no barn design is active
                                  if (activeBarnDesignId !== -1) {
                                    // if barn design is active, open barn designer with active barn design
                                    window.open(
                                      `/barns/barn-designer/${barn_id}/active/${activeBarnDesignId}`,
                                      '_blank'
                                    )
                                  } else {
                                    // if no barn design is active, open barn designer with new barn

                                    window.open(`/barns/barn-designer/${barn_id}`, '_blank')
                                  }
                                }}
                                className='designer-button'
                              >
                                {intl.formatMessage({id: 'FORM.INPUT.BARNS.OPENBARNDESIGNER'})}
                              </button>
                            </div>
                          </div>
                        )}

                        {/* End Column 2 */}
                      </div>
                      <div className='col-lg-3 pe-0'>
                        {designThumbnail && designThumbnail !== '' && (
                          <div className=''>
                            <div className='mb-2'>
                              <h6>Click to preview barn design</h6>
                            </div>
                            <div
                              style={{
                                border: '1px solid #eee',
                                padding: '0',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: '#f9f9f9',
                                overflow: 'hidden',
                                maxHeight: '100px',
                                cursor: 'pointer', // Add cursor pointer to indicate clickable
                              }}
                              onClick={() => setShowLightbox(true)}
                            >
                              <img
                                src={designThumbnail}
                                alt='Barn Thumbnail'
                                style={{
                                  height: '100%',
                                  width: '100%',
                                  objectFit: 'contain',
                                }}
                              />
                            </div>

                            {/* Lightbox Modal */}
                            {showLightbox && (
                              <div
                                style={{
                                  position: 'fixed',
                                  top: 0,
                                  left: 0,
                                  right: 0,
                                  bottom: 0,
                                  backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  zIndex: 9999,
                                  cursor: 'pointer',
                                }}
                                onClick={() => setShowLightbox(false)}
                              >
                                <div
                                  style={{
                                    position: 'relative',
                                    maxWidth: '100vw',
                                    maxHeight: '100vh',
                                    overflow: 'hidden',
                                  }}
                                >
                                  <img
                                    src={designThumbnail}
                                    alt='Barn Design Preview'
                                    style={{
                                      maxWidth: '100%',
                                      maxHeight: '100vh',
                                      objectFit: 'contain',
                                    }}
                                  />
                                  {/* Close button */}
                                  <button
                                    style={{
                                      position: 'absolute',
                                      top: '10px',
                                      right: '10px',
                                      background: 'white',
                                      border: 'none',
                                      borderRadius: '50%',
                                      width: '30px',
                                      height: '30px',
                                      cursor: 'pointer',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      fontSize: '20px',
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      setShowLightbox(false)
                                    }}
                                  >
                                    ×
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>

                      <div className='d-flex flex-column my-2 border p-2 bg-white'>
                        <ul
                          tabIndex='-1'
                          className='nav nav-tabs nav-pills fs-6 fw-bolder flex-nowrap'
                          id='myTab'
                          role='tablist'
                          style={{overflowX: 'auto', overflowY: 'hidden'}}
                        >
                          {tabs.map((tab, index) => {
                            return (
                              <li
                                key={index}
                                className='nav-item'
                                onClick={() => {
                                  setSelectedTab(index)
                                  if (props?.id == undefined) {
                                    window.location.hash = tab
                                  }
                                }}
                              >
                                <a
                                  tabIndex='-1'
                                  className={
                                    'nav-link text-active-dark px-10 py-1 text-gray-700' +
                                    (index == selectedTab ? ' active' : '')
                                  }
                                  id={tab + '-tab'}
                                  data-bs-toggle='tab'
                                  href={'#' + tab}
                                  data-tooltip-id={`BARNS.DETAIL.TAB.${tab}`}
                                >
                                  {intl.formatMessage({id: 'FORM.INPUT.BARNS.TAB.' + tab})}
                                </a>
                              </li>
                            )
                          })}
                        </ul>
                        <div className='tab-content mt-5 pb-2' id='myTabContent'>
                          {tabs.map((tab, index) => {
                            return (
                              <div
                                key={index}
                                className={
                                  'tab-pane fade show' + (index == selectedTab ? ' active' : '')
                                }
                                id={tab}
                                role='tabpanel'
                              >
                                <HelpLink tooltip={'BARNS.DETAIL.TAB.' + tab + '.LINK.HELP'} />
                                {componentTags[index]}
                              </div>
                            )
                          })}
                        </div>
                      </div>
                      {/* End Column Group */}
                    </div>
                  </div>{' '}
                  {/* End Card Body */}
                </div>{' '}
                {/* End Card Body */}
                <InputFormFooter goBackPath={'/barns'} data={'barn'} />
              </form>
            </div>
          </FormProvider>
        </>
      )}
    </>
  )
}

export {BarnsDetail}
