import axios from "axios";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useAlert, useFlashAlert } from "../../../modules/sgl-utils/DialogsProvider";
import { useAppSelector } from "../../../redux/hooks";
import moment from 'moment';
import { useSearchParams } from "react-router-dom";
import { getCurrentTimeStamp, logSiteActivity } from "../../../modules/sgl-utils/SglFunctions";
import { SESSION_STORAGE_CURRENT_RING_ID } from "../../../modules/sgl-utils/sglConstants";
import { useIntl } from "react-intl";

const NEST_API_URL = process.env.REACT_APP_NEST_API_URL

// create context
const AnnouncerToolContext = createContext()

const AnnouncerToolContextProvider = ({ children }) => {
    const intl = useIntl()
    const customerID = useAppSelector(state=> state.showCompany.company_id);
    const currentShowID = useAppSelector(state => state.currentShow.show_id);
    const currentCompany = useAppSelector(state=> state.showCompany);
    const currentShowDate = useAppSelector(state => state.currentShow.end_date);
    const [showDates, setShowDates] = useState([]);
    const [showRings, setShowRings] = useState([]);
    const [classGroups, setClassGroups] = useState([])
    const [selectedDate, setSelectedDate] = useState(() => sessionStorage.getItem("selectedDate") || "");
    const [selectedRing, setSelectedRing] = useState(() => sessionStorage.getItem("selectedRing") || 0);
    const [selectedClassGroup, setSelectedClassGroup] = useState(() => sessionStorage.getItem("selectedClassGroup") || null);
    const [errors, setErrors] = useState([]);
    const [orderUpcomingR2JO, setOrderUpcomingR2JO] = useState(false);
    const [maximumValueOfActualOrder, setMaximumValueOfActualOrder] = useState(0)
    const [showGridLoadingOverlays, setShowGridLoadingOverlays] = useState(false) // Show/Hide the overlays on lined up trip grids and stagger grids
    const [classSplits, setClassSplits] = useState([]) // View Splits - classes splits
    const [changeHistory, setChangeHistory] = useState([])

    const [currentClassGroup, setCurrentClassGroup] = useState(); //current group information
    const [currentTrip, setCurrentTrip] = useState(); //current trip information
    const [selectedRow, setSelectedRow] = useState(); //TODO: EVALUATE

    const alertDialog = useAlert();
    const [dropdowns, setDropDownLists] = useState(); //metadata list values
    const flashAlert = useFlashAlert()

    const [upcomingTrips, setUpcomingTrips] = useState([]) //Upcoming/Lined Up trips
    const [classesWithCompletedTrips, setClassesWithCompletedTrips] = useState([]) //Classes with the completed trips in stagger
    const [orderedTrips, setOrderedTrips] = useState([]) //OOG Tab - ordered trips list for class group
    const [unorderedTrips, setUnorderedTrips] = useState([]) //OOG Tab - unordered trips list for class group
    const [preferences, setPreferences] = useState({
        AddEntryFromIngate: 0,
        AddRiderFromIngate: 0,
        TripGoTimeGap: 0,
    })
    const [ farmtekRound, setFarmtekRound ] = useState('R1')
    const [searchParams, setSearchParams] = useSearchParams();//Used when announcer tool is opened from switch announcer button
    const [warningForTripsSetOfToQuickly, setWarningForTripsSetOfToQuickly] = useState(false)//Used for display warning if trips are gone to quickly
    const [lastTripGoTime, setLastTripGoTime] = useState(moment().subtract(1, 'day'))//Set time to previous day
    const [lastIngateActivity, setLastIngateActivity] = useState('')//Keep track of what activity is performed to store data site_monitoring_activity_log
    const lastStaggerUpdateRef = useRef(-1)
    const [showAgGridSettingsModal, setShowAgGridSettingsModal] = useState(false)
    const [disabledColumns, setDisabledColumns] = useState(null) //Used for keeping track of hidden columns in Ingate AgGrid Settings

    //Get metadata for ingate
    useEffect(() => {
        axios.get(`${NEST_API_URL}/ingate-announcer/getMetaData?customer_id=${customerID}`)
            .then(response => {   
                setDropDownLists({
                    hunter_score_type_list: response?.data?.hunter_type_list && response?.data?.hunter_type_list || [],
                    hunter_score_by_list: response?.data?.hunter_score_by_list && response?.data?.hunter_score_by_list || [],
                    hunter_derby_round_1_list: response?.data?.hunter_derby_round_1_list && response?.data?.hunter_derby_round_1_list || [],
                    hunter_derby_round_2_list: response?.data?.hunter_derby_round_2_list && response?.data?.hunter_derby_round_2_list || [],
                    jumper_table_list: response?.data?.jumper_table_list && response?.data?.jumper_table_list || [],
                    disqualify_status_list: response?.data?.disqualify_status_list && response?.data?.disqualify_status_list || [] 
                })
                setPreferences(response.data.preferences)
            })
            .catch((error) => {
                if (error.response) {
                    alertDialog({message: error.response.data.error});
                }
            });
    },[])

    //Populate ring and date dropdowns for show
    useEffect(() => {
        if(!currentShowID || !customerID){ 
            clearAnnouncerState()
            clearTripsStates()
        } else {
            axios.get(`${NEST_API_URL}/scheduler?show_id=${currentShowID}&customer_id=${customerID}`)
            .then(response => {
                if(!response.data.Days || !response.data.RingxShows) {            
                    clearAnnouncerState() 
                    clearTripsStates()
                } else {
                    setShowDates(response.data.Days);
                    setShowRings(response.data.RingxShows);
                    if(searchParams.get("selectedDate")){//if annoucer tool is opened from switch announcer button
                        setSelectedDate(searchParams.get("selectedDate"));
                        searchParams.delete('selectedDate');
                        setSearchParams(searchParams);
                    }else if (sessionStorage.getItem("selectedDate")) {
                        setSelectedDate(sessionStorage.getItem("selectedDate"));
                    }else{
                        //setSelectedDate(response.data.Days[0]);  
                        let currentDate = moment().format("YYYY-MM-DD")
                        let isCurrentDatePresent = response.data.Days.filter((day) => day == currentDate).length > 0
                        setSelectedDate( isCurrentDatePresent ? currentDate : response.data.Days[0]);
                    }

                    if(searchParams.get("selectedRing")){//if annoucer tool is opened from switch announcer button
                        setSelectedRing(searchParams.get("selectedRing"));
                        searchParams.delete('selectedRing');
                        setSearchParams(searchParams);
                    } else if (sessionStorage.getItem("selectedRing")) {
                        setSelectedRing(sessionStorage.getItem("selectedRing"));
                    } else {
                        const currentRingId = sessionStorage.getItem(SESSION_STORAGE_CURRENT_RING_ID)

                        // If there's a ring ID in the session storage, and it matches a ring in the current show, select that ring. If no matching ring is found, select the first ring from the current show.
                        if(currentRingId && response.data.RingxShows.find(rxs => rxs.ring_id == currentRingId)) { 
                            setSelectedRing(currentRingId)
                        } else { // Set First Ring as Selected
                            setSelectedRing(response.data.RingxShows[0].ring_id);
                        }
                    }
                } 
            })
            .catch((error) => {
                if (error.response) {
                    alertDialog({message: error.response.data.error});
                }
            });
        }
    }, [currentShowID, customerID])

    useEffect(() => {
        //Display errors on stagger if any
        displayErrors();
    }, [currentClassGroup])

    //Get list of class groups scheduled for day and ring
    useEffect(() => {
        if(selectedRing && selectedDate){ 
            clearTripsStates()
            axios.get(`${NEST_API_URL}/order-of-go/getClassGroupsListForDayAndRing?show_id=${currentShowID}&customer_id=${customerID}&day=${selectedDate}&ring_id=${selectedRing}`)
            .then(response => {
                setClassGroups(response.data.classGroups)

                if (response.data.classGroups.length > 0) {
                    if(searchParams.get("selectedClassGroup")){//if annoucer tool is opened from switch announcer button
                        setSelectedClassGroup(searchParams.get("selectedClassGroup"));
                        searchParams.delete('selectedClassGroup');
                        setSearchParams(searchParams);
                    }else if (sessionStorage.getItem("selectedClassGroup")) {
                        setSelectedClassGroup(sessionStorage.getItem("selectedClassGroup"));
                    }else{
                        setSelectedClassGroup(response.data.classGroups[0].class_group_id)
                    }
                } else {
                    setSelectedClassGroup(null)
                }
               
            })
            .catch((error) => {
                if (error.response) {
                    alertDialog({message: error.response.data.error});
                }
            })
            .finally(() => {
                searchParams.delete('selectedClassGroup');
                setSearchParams(searchParams);
            })
        }
    }, [selectedRing, selectedDate])

    useEffect(() => {
        /**
         * Values for activityPeformed
         * Day Change - Show Date drop down change
         * Ring Change - Ring drop down change
         * Group Change - Event drop down change
         * Trip Gone - when trip is checked off
         * Trip Not Gone - when trip is send back to upcoming trips
         * Trip Scratched - when trip is scratched
         * Trip Not Scratched - when trip is unscratched
         * Disqualify Trip - when trip is disqualified
         * Save Score - when hunter save score webservice is called
         * Save R1 Score - when hunter derby R1 save score webservice is called
         * Save R2 Score - when hunter derby R2 save score webservice is called
         * Save Time1 - when jumper R1 save score webservice is called
         * Save JO Score - when jumper R2 save score webservice is called
         * Save JO Start  - when jumper JO start button is clicked
         * Save R2 Start - when hunter derby R2 start button is clicked
         * Set Order Of Go - when upcoming trip are reordered and the updated order is saved
         */
        if(lastIngateActivity == 'Trip Gone' || lastIngateActivity == 'Trip Not Gone'){
            setFocusOnInput()//Set focus on first input field
        }
        // UBN - Commented code to Disable log site activity call after implementation of cloud api logs (causing performance issues for announcers due to extra calls)
        /*
        if(lastIngateActivity)
        {
            let selected_ring = showRings.filter((ring) => ring.ring_id == selectedRing )
            let logData = {
                source: 'Announcer Tool',
                tab_name: 'Ingate & Announcer',
                activity: lastIngateActivity,
                ring_no: selected_ring ? selected_ring[0].ring_number : 0,
                class_group_id: selectedClassGroup,
            }
            if(currentTrip?.entryxclasses_id)
            {
                logData.trip_id = currentTrip.entryxclasses_id
            }
            // add log in site_monitoring_activity_log table
            logSiteActivity(currentShowID, customerID, logData)
            setLastIngateActivity('')
        }
        */
    },[currentClassGroup, upcomingTrips, classesWithCompletedTrips])

    const highlightCurrentTrip = (gridApi) => {
        var rowNodeToSelect;
        // Find the row node with the specified sgl_id value
        gridApi?.forEachNode((rowNode) => {
            if(rowNode.data.sgl_id === selectedRow?.data?.sgl_id)
            {
            rowNodeToSelect =  rowNode
            }
        });
            
        // If a matching row node is found, select it and update current trip
        if (rowNodeToSelect) {
            gridApi?.selectNode(rowNodeToSelect, true);
            setSelectedRow(rowNodeToSelect)
            setCurrentTrip(rowNodeToSelect.data)
            gridApi?.redrawRows({rowNodeToSelect})
            gridApi?.ensureNodeVisible(rowNodeToSelect); // keep the selected trip in focus 
        }
    }

    // update column visibility based on group type and score type
    const updateColumnDefToggle = (columnApi) => {
        let dynamicColumns = [
            'time_one','total_faults_one','time_two','total_faults_two',
            'scoreone','scoretwo','scorethree','scorefour', 'scorefive', 'scoresix', 'total_score',
            'team','TeamxClass.score', 'r3_trip', 'time_three', 'total_faults_three'
        ]

        let visibleColumns = []
        let hiddenColumns = []

        if(currentClassGroup?.group_type == "Hunters"){
            const scoreNames = ['one', 'two', 'three', 'four', 'five', 'six'];
        
            visibleColumns.push('scoreone')
            if(currentClassGroup.group_score_type == "Hunter Derby"){ // Hunter Derby Columns
                visibleColumns.push('scoretwo')
            } else { // Hunter Columns
                for (let i = 1; i < currentClassGroup.number_of_scores; i++) {
                    visibleColumns.push(`score${scoreNames[i]}`);
                }
            }
            visibleColumns.push('total_score')
        }
        else if(currentClassGroup?.group_type == "Jumpers"){
            visibleColumns.push('time_one','total_faults_one','time_two','total_faults_two') // Jumper Columns

            if (currentClassGroup?.has_r3) {
                if (currentClassGroup?.configurable_r3) { // FEI Nations Cup Columns
                    visibleColumns.push('r3_trip')
                }
                visibleColumns.push('time_three', 'total_faults_three') 
            }
        }
        
        if(currentClassGroup?.group_score_type == "Team Scoring"){ 
            visibleColumns.push('team','TeamxClass.score') // Team Scoring Columns
        }

        hiddenColumns = dynamicColumns.filter(column => !visibleColumns.includes(column))
        setDisabledColumns([...hiddenColumns, 'class_number'])
        visibleColumns?.length > 0 && columnApi?.setColumnsVisible(visibleColumns, true)
        hiddenColumns?.length > 0 && columnApi?.setColumnsVisible(hiddenColumns, false) // Hide other columns
    } 

    const selectCurrentTrip = (params) => {
        // unselect the previously selected row
        if(selectedRow)
        {
            selectedRow?.setSelected(false)
        }
        //select the current row
        params?.node?.setSelected(true)
        setSelectedRow(params?.node)

        //set current trip in state
        setCurrentTrip(params.data)
        setFocusOnInput()//Set focus on first input field
    }

    //Get selected class group information
    const fetchClassGroupData = (showOverlay) => {
        if(showOverlay){
            setShowGridLoadingOverlays(true) // Show grid overlays only when class group is changed
        }

        axios.get(`${NEST_API_URL}/ingate-announcer/getTripsAndClassGroupDetails?show_id=${currentShowID}&customer_id=${customerID}&class_group_id=${selectedClassGroup}&reload_group=${showOverlay}`)
        .then(response => {
            if(response.data.success){
                //Set current class Group
                setCurrentClassGroup(response.data.classGroup);

                //Set OOG Tab
                setOrderedTrips(response?.data?.orderedTrips)
                setUnorderedTrips(response?.data?.unorderedTrips)
                
                //Set Lined Up Trips
                setUpcomingTrips(response?.data?.upcomingTrips);

                //Set completed trips for classes of class group
                setClassesWithCompletedTrips(response?.data?.completedTrips) 

                //Set splits for classes
                setClassSplits(response?.data?.classSplits)

                if (showOverlay) {
                    setChangeHistory(response.data.changeHistory)
                }
            }
        })
        .catch((error) => {
            if (error.response) {
            alertDialog({message: error.response.data.error});
            }
        })
        .finally(() => {
            setShowGridLoadingOverlays(false) // Hide grid overlays
        })
    }

    //update gone/not gone status for trip
    const setGoneStatus = (classId, entryxclasses_id, gone_in) => {
        let actual_order = 0
        if(gone_in)
        {
            actual_order = maximumValueOfActualOrder
            setMaximumValueOfActualOrder(maximumValueOfActualOrder+1)
            setFarmtekRound('R1') //Reset farmtek updates for R1 round on trip gone
        }

        lastStaggerUpdateRef.current =  getCurrentTimeStamp()  
        axios.patch(`${NEST_API_URL}/ingate-announcer/setGoneStatusForTrip`, {
                show_id: currentShowID,
                customer_id: customerID,
                class_group_id: currentClassGroup.class_group_id,
                class_id: classId,
                entryxclasses_id: entryxclasses_id,
                gone: gone_in,
                actual_order: actual_order
            })
            .then(response => {
                if (response.data.success) {
                    setUpcomingTrips((upcomingTrips) => { return response?.data?.upcomingTrips})
                    //updateCompletedTripsForClass(classId, response?.data?.classCompletedTrips);    
                    setClassesWithCompletedTrips((prevClassesWithCompletedTrips) => {
                        const updatedCompletedTrips = [...prevClassesWithCompletedTrips];
                        for (let classKey in updatedCompletedTrips) {
                          if (updatedCompletedTrips[classKey].class_id === classId) {
                            updatedCompletedTrips[classKey] = response?.data?.classCompletedTrips;
                            break;
                          }
                        }
                        return updatedCompletedTrips;
                    });     
                    lastStaggerUpdateRef.current =  getCurrentTimeStamp()  
                }
                else {
                    //TODO: revert state of checkbox
                }
            })
            .catch((error) => {
                if (error.response) {
                    alertDialog({message: error.response.data.error});
                }
            });
    }

    const updateCompletedTripsForClass = (classId, classCompletedTrips) => {
        let updatedCompletedTrips = [...classesWithCompletedTrips];
        for(let classKey in updatedCompletedTrips){
            if(updatedCompletedTrips[classKey].class_id == classId){
                updatedCompletedTrips[classKey] = classCompletedTrips;
                break;
            }
        }
        setClassesWithCompletedTrips(updatedCompletedTrips)
    }

    const updateGroupSettings = (groupSettings) => {
        setShowGridLoadingOverlays(true)
        axios.patch(`${NEST_API_URL}/ingate-announcer/updateGroupSettings`, {
            show_id: currentShowID,
            customer_id: customerID,
            class_group_id: currentClassGroup.class_group_id,
            group_settings: groupSettings
        })
        .then(response => {
            if (response.data.success) {
                flashAlert({ type: 'success', message: intl.formatMessage({id: 'FORM.INPUT.ANNOUNCER.TAB.INGATEANNOUNCER.GROUPSETTINGS.UPDATED'}) });
                setCurrentClassGroup(response.data.classGroup)
                setClassesWithCompletedTrips(response?.data?.completedTrips)
            }
        })
        .catch((error) => {
            if (error.response) {
                alertDialog({message: error.response.data.error});
            }
        })
        .finally(() => {
            setShowGridLoadingOverlays(false)
        });
    }

    const getMaximumActualOrderofTrips = () => {
        // Initialize the maximum_actual_order to 0.
        let maximum_actual_order = 0;
    
        // Iterate through each class with completed trips.
        for (const classWithCompletedTrip of classesWithCompletedTrips) {
            // Iterate through each trip in the current class.
            for (const trip of classWithCompletedTrip.Entryxclasses) {
                // Update maximum_actual_order if the current trip's actual_order is greater.
                maximum_actual_order = Math.max(maximum_actual_order, trip.actual_order);
            }
        }
    
        // Return the next available order by adding 1 to the maximum_actual_order.
        return maximum_actual_order + 1;
    };
    
    useEffect(() => {
        clearTripsStates()
        if(selectedClassGroup){ 
            fetchClassGroupData(true)
        }
    }, [selectedClassGroup])

    useEffect(() => {
        if(classesWithCompletedTrips)
        {
            //Get Maximum Value of the actual order present in classesWithCompletedTrips
            let maximum_actual_order = getMaximumActualOrderofTrips()
            setMaximumValueOfActualOrder(maximum_actual_order)
        }
    },[classesWithCompletedTrips])

    const clearTripsStates = () => {
        setUpcomingTrips(null)
        setClassesWithCompletedTrips(null)
        setSelectedRow()
        setCurrentTrip()
        setOrderedTrips([])
        setUnorderedTrips([])
        setOrderUpcomingR2JO(false)
        setMaximumValueOfActualOrder(0)
        setCurrentClassGroup(null)
    }

    const clearAnnouncerState = () => {
        setShowDates([]);
        setShowRings([]);
        setSelectedDate('');
        setSelectedRing(0);
    }

    const refreshGrids = (showOverlay) => {
        fetchClassGroupData(showOverlay)
    }

    const displayErrors = () => {
        let errors = [];
        let currentDate = moment().format("YYYY-MM-DD");

        if(currentShowDate >= currentDate){ // Only needed for current and future shows
            if(currentClassGroup?.group_type == "Hunters"){
                if(currentClassGroup?.hunter_type == "" || currentClassGroup?.hunter_type == "Select"){
                    errors.push("Select Hunter Score Type.");
                }

                if(currentClassGroup?.jogordertrips == 0 && currentClassGroup?.has_jog_order_trips ){
                    if(currentClassGroup?.group_score_type == 'Hunter Derby'){
                        if(currentClassGroup?.after_delay_r2){ //Do not show error for Hunter Derby Hunt n Go where trip always have R2 or back to back R2
                            errors.push("Enter no. of callback trips (R2).");
                        }
                    }
                    else {
                        errors.push("Enter Jog Order.");
                    }
                }

                if(currentClassGroup?.jogordertrips == 0 && currentClassGroup?.group_score_type == 'Hunter Derby'){
                }
            }
            else if(currentClassGroup?.group_type == "Jumpers"){
                if(currentClassGroup?.jumper_table == "" || currentClassGroup?.jumper_table == "Select"){
                    errors.push("Select Jumper Table.");
                }

                if(currentClassGroup?.timeallowed_tripone == 0){
                    errors.push("Enter Time Allowed for Round 1.");
                }
 
                if(currentClassGroup?.timeallowed_jo == 0 && currentClassGroup?.has_jo_round){
                    errors.push("Enter Time Allowed for Jump Off Round.");
                }
            }
        }

        if (errors.length > 0){
            errors.unshift("You must provide following information to get the staggers!");
        }
        setErrors(errors);
        
    }

    const isTimeDifferenceValid = (gone_in) => {
        if(preferences['TripGoTimeGap'] && gone_in)
        {//If tripGoTimeGo pref is present and trip is set to go than check the time difference between last gone trip and current trip
          const currentTime = moment();
          const timeDiff = currentTime.diff(lastTripGoTime, 'seconds') + 1;
          // If time difference is greater than pref TripGoTimeGap or is negative( for first trip ) than valid
          const isTimeDiffValid = timeDiff < 0 || timeDiff >= preferences['TripGoTimeGap'];
    
          if(isTimeDiffValid)
          {
            // If time difference is valid than trip will be gone, warning will be removed and last trip go time will be updated with current time
            setLastTripGoTime(moment(moment(), 'HH:mm:ss'))
            setWarningForTripsSetOfToQuickly(false)
          }else{
            // If difference is not valid than display warning
            setWarningForTripsSetOfToQuickly(true)
            let ring = showRings.filter(ring => ring.ring_id == currentClassGroup.ring_id)
            //add record in log_ingate_activity table
            let logData = {
                'day': currentClassGroup.day,
                'ring_number': currentClassGroup.ring, 
                'ring_name': ring.length > 0 ? ring[0].ring_name : "", 
                'entry_number': currentTrip?.Entry?.number, 
                'class_number': currentTrip.Class.number, 
                'last_update_at': lastTripGoTime.format('YYYY-MM-DD HH:mm:ss'), 
                'current_update_at': currentTime.format('YYYY-MM-DD HH:mm:ss'), 
                'time_difference': timeDiff
            }
            axios.post( process.env.REACT_APP_NEST_API_URL + '/ingate-announcer/logIngateQuickActivity', {
                show_id: currentShowID,
                customer_id: customerID,
                ingate_log_data: logData
            })
          }
          return isTimeDiffValid
        }
        return true //Return true when gone trip is not gone
    }

    const setFocusOnInput = () => {
        let inputElement = null
        if(currentClassGroup?.group_type == "Jumpers"){
            inputElement = document.getElementById("time_one");//Jumper
        }else if(currentClassGroup?.group_score_type == "Hunter Derby"){
            inputElement = document.getElementById("derbyroneponeoptionbonus");//Hunter Derby
        }
        else{ //Hunter
            inputElement = document.getElementById("scoreone");
        } 
        if (inputElement) {
            inputElement.focus();

            setTimeout(() => {
                inputElement.select();
            }, 10); // Delay focus to allow state update to complete
        }
    }
    

    return (
        <AnnouncerToolContext.Provider value={{ currentShowID, customerID, showDates, showRings, classGroups, selectedDate, setSelectedDate, selectedRing, setSelectedRing, selectedClassGroup, setSelectedClassGroup, setClassGroups, fetchClassGroupData, currentClassGroup, setCurrentClassGroup, orderUpcomingR2JO, setOrderUpcomingR2JO, currentTrip, setCurrentTrip, upcomingTrips, setUpcomingTrips, classesWithCompletedTrips, setClassesWithCompletedTrips, setGoneStatus, selectedRow, setSelectedRow, selectCurrentTrip, dropdowns, setDropDownLists, orderedTrips, unorderedTrips, clearTripsStates, highlightCurrentTrip, updateColumnDefToggle, updateGroupSettings, errors, updateCompletedTripsForClass, refreshGrids, preferences, setOrderedTrips, setUnorderedTrips, showGridLoadingOverlays, classSplits, currentCompany, warningForTripsSetOfToQuickly, setWarningForTripsSetOfToQuickly, lastTripGoTime, setLastTripGoTime, isTimeDifferenceValid, lastIngateActivity, setLastIngateActivity, maximumValueOfActualOrder, farmtekRound, setFarmtekRound, lastStaggerUpdateRef, changeHistory, showAgGridSettingsModal, setShowAgGridSettingsModal, disabledColumns }}>
            {children}
        </AnnouncerToolContext.Provider>
    );
}

// context consumer hook
const useAnnouncerToolContext = () => {
    // get the context
    const context = useContext(AnnouncerToolContext);
  
    // if `undefined`, throw an error
    if (context === undefined) {
      throw new Error("useAnnouncerToolContext was used outside of its Provider");
    }
  
    return context;
};

export { useAnnouncerToolContext, AnnouncerToolContextProvider }