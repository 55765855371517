import { useMemo, useState, useEffect, useCallback, createContext, useContext, useRef } from 'react'
import { useIntl } from 'react-intl'
import { useForm, FormProvider } from "react-hook-form";

// Custom Components
import { InputFormFooter } from '../../modules/sgl-utils/InputFormFooter'
import { GeneralTab } from './Detail/GeneralTab';
import { AddToSeriesForm } from './Detail/AddToSeriesForm';
import { ClassesTab } from './Detail/ClassesTab';
import { ClassFeesTab } from './Detail/ClassFeesTab';
import { AccountTab } from './Detail/AccountTab';
import { EntriesHistoryTab } from './Detail/HistoryTab';
import { GeneralPutOnHoldForm } from '../horses/Detail/GeneralPutOnHoldForm';
import { useAlert, useConfirm, useLoadingOverlay, useProgress } from '../../modules/sgl-utils/DialogsProvider';
import axios from 'axios';
import { useAppSelector } from '../../redux/hooks';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useCurrencyFormatter } from '../../modules/sgl-utils/Formatters';
import { isInvalidRecord } from '../../modules/sgl-utils/SglFunctions'
import { useAppDispatch } from '../../redux/hooks';
import { setNewShowId } from '../../redux/reducers/newShowIdReducer';
import { useAuth } from '../../modules/auth';
import moment from 'moment';
import DetailPageModal from '../../modules/sgl-utils/DetailPageModal';
import {PageTitle} from '../../../_metronic/layout/core'
import { UpdateEntryNumberForm } from './UpdateEntrNumberForm';
import history from '../../modules/sgl-utils/unstableHistory';
import { NotesTab } from '../../modules/input/NotesTab';
import { ChecklistTab } from './Detail/ChecklistTab';
import useNavigationPrompt from '../../modules/hooks/use-navigation-prompt';
import useAccessChecker from '../../modules/hooks/use-access-checker';
import { useOutputContext } from '../../modules/output-listing/OutputListingContext';
import { useLoadReportAndPrint } from '../reports/ReportTypeModal';
import { useSyncUpdateContext } from '../../modules/output-listing/synchronizeUpdateContext';
import { UpdateEntryRolesForm } from './UpdateEntryRolesForm';
import { AuditTrailTab } from './Detail/AuditTrailTab';
import HelpLink from '../../modules/components/HelpLink'
import { NumericFormat } from 'react-number-format';
import { Controller } from 'react-hook-form';
import TaxFormInformationModal from '../people/Detail/AdditonalTab/TaxFormInformaionModal';
import { EntriesWithPastBalance } from './Detail/EntriesWithPastBalance';

const AUTH_LOCAL_STORAGE_KEY = 'sgl-auth-react-cloud'
const { PUBLIC_URL } = process.env

export const dirtyValues = (
    dirtyFields,
    allValues
  ) => {
    // NOTE: Recursive function.
  
    // If *any* item in an array was modified, the entire array must be submitted, because there's no
    // way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
    if (dirtyFields === true || Array.isArray(dirtyFields)) {
      return allValues;
    }
  
    // Here, we have an object.
    return Object.fromEntries(
      Object.keys(dirtyFields).map((key) => [
        key,
        dirtyValues(dirtyFields[key], allValues[key])
      ])
    );
};


const EntryDetail = (props) => {
    const intl = useIntl();
    const currencyFormatter = useCurrencyFormatter()
    const alertDialog = useAlert()
    const navigate = useNavigate();
    const {progressBar} = useProgress()
    const confirmDialog = useConfirm()
    const { getEventID } = useOutputContext();
    const currentShowID = useAppSelector(state => state.currentShow.show_id)
    const currentShowStartDate = useAppSelector(state => state.currentShow.start_date)
    const [ entry_id, setEntry_id ] = useState(useContext(EntryIdContext).entry_id)
    const params_entry_id = useParams().entry_id
    const [current_entry_id, setCurrentEntryId] = useState(props.id || props.id == 0 ? props.id : entry_id);
    const { handleDetailPageCatch, hasSpecialPermissionToAccess } = useAccessChecker()
    const customer_id = useAppSelector(state=> state.showCompany.company_id);

    const governingOrganization = useAppSelector(state => state.showCompany.governing_organization)
    const dispatch = useAppDispatch()
    const [form_meta, setFormMeta] = useState();
    const loadingOverlay = useLoadingOverlay()
    const [record_id, setRecordId] = useState("")
    const [sub_area, setSubArea] = useState("")
    const [child_component, setChildComponent] = useState("")
    const [prevData, setPrevData] = useState({});
    const [linkClicked, setLinkClicked] = useState(false)
	const [update_data, setUpdateData] = useState(0)
   	// prevent function being recreated on state change
    const updateData = useCallback(() => {setUpdateData(update_data + 1) }, [update_data]);
    const [showUpdateEntryNumberForm, setShowUpdateEntryNumberForm] = useState(false);
    const [ seriesEntryNumber, setSeriesEntryNumber] = useState([])

    const [showUpdateEntryRolesForm, setShowUpdateEntryRolesForm] = useState(false)
    const seriesRolesPropsRef = useRef({ seriesFutureShowEntries: [], message: '', onClose: () => {} })
    
    const [displayDeleteButton, setDisplayDeleteButton] = useState(false);
    const [entry_number, setEntryNumber] = useState("")
    const {loadReportsAndPrint, loadSinglePageReportsandPrint} = useLoadReportAndPrint();
    const { addSubscribedEvents, removeAllEventListeners, sse } = useSyncUpdateContext()
    const [syncEvent, setSyncEvent] = useState(null);
    
    const [horse_updated, setHorseUpdated] = useState(false)
    const [rtos_updated, setRTOsUpdated] = useState(false)
    const [printInvoice, setPrintInvoice] = useState(false)
    const [lockPrizeMoneyAction, setLockPrizeMoneyAction] = useState(false);
    const [previousValues, setPreviousValues] = useState({
        entryPrices: 0,
        entryFeesData: 0,
        trips: 0,
        paymentxEntries: 0,
        prizemoneyrecipient_id: 0,
        responsibleparty_id: 0,
    });
    const [balanceInfoForRpAndPMR, setBalanceInfoForRpAndPMR] = useState(null) // store account_balance, account_credit and available credit for PMR and RP
    const [isFormDataSubmitted, setIsFormDataSubmitted] = useState(false);
    const [pastBalances, setPastBalances] = useState(null)
    const [displayPastEntries, setDisplayPastEntries] = useState(false)

    const methods = useForm({
        defaultValues: {
            "entry_number": 1050,
            "trainer_account": false,

            // Horse
            "horse_name": "",
            "horse_dob": "",
            "horse_height": "",
            "horse_search": "",

            //Owner
            "owner_dob": "",
            "owner_search": "",

            // Trainer
            "trainer_dob": "",
            "trainer_search": "",

            //Rider
            "rider_dob": "",
            "rider_search": "",

            // Cards
            "cards": "",
            "card_number": "",
            "card_type": "",
            "card_exp": "",
            "name_on_card": "",
            
            //Payment details
            "listOfStates":[],
            "listOfCountries":[],
            "CardUpdated":false,
            "RetainCard":false,
            "CardOwner":"",
            "CardOwnerID":"",            
            "base64CompleteCardNumber":"",
            "paymentType": "",
            "paymentAmount": "$0.00",        
            "paymentGatewayNameToUse": "",
            "availableCreditCardsAndPaymentMethods": "",
            //variables to get info from credit cards and display to user on page >>
            // see cc_initPaymentVars
            "creditCardNum":"",
            "creditCardExp":"",
            "creditCardCVV":"",
            "creditCardName":"",
            "creditCardName_First":"",
            "creditCardName_Last":"",
            "newPaymentAmount":"",
            "newPaymentCode": "", //ref code
            "newPaymentDescription":"",
            "refCode":"",
            "cardType":"",
            "email":"",
            "addr1": "",
            "addr2": "",
            "cityname": "",
            "state": "",
            "bankNorthStateName":"",
            "bankNorthCountryNames":"",
            "zip":"",
            "country_code": "",
            "country": "",
            "cardTypeIcon":"",
            "phoneNumber": "",
            "peopleID":"",
            "updateRTO": false,
            "paymentMethodID": 0,
            "entSecuredPaymentMethodID": 0,
            "creditCardServiceFeeAmount":"",
            "totalTransactionAmount":"",
            //variables to get info from credit cards and display to user on page <<
                        
            "entryResponsibleParty_FLName": "",            
            "prize_money_recipient_search": "",
            "responsible_party_search": "",
            "dropInputRef": false,

            "entry": {
                "sync_id": "",
                "entry_id": 0,
                "number": 0,
                "trainer_id": 0,
                "rider_id": 0,
                "entryowner_id": 0,
                "creation_date": "0000-00-00",
                "creation_time": "00:00:00",
                "show_id": 0,
                "stable_id": 0,
                "balance": 0,
                "total_fees": 0,
                "total_paid": 0,
                "horse_id": 0,
                "class_fees_subtotal": 0,
                "entry_fee_subtotal": 0,
                "rider": "",
                "trainer": "",
                "owner": "",
                "horse": "",
                "scratched": 0,
                "vetcert": 0,
                "reservationpayment_received_yn": 0,
                "reservationpayment_date": "0000-00-00",
                "reservationpayment_note": "",
                "classlist": "",
                "stable": "",
                "entry_notes": "",
                "status": "",
                "responsibleparty": "",
                "responsibleparty_id": 0,
                "responsiblepartysource": 0,
                "web_enable_authorization_code": "",
                "web_enabled": 0,
                "tax": 0,
                "addedfromweb": 0,
                "usef_suspension": 0,
                "usef_suspensioncheckdate": "0000-00-00",
                "usef_lastsuspensionchecktime": "00:00:00",
                "picked_up": 0,
                "prizemoneyrecipient": "",
                "prizemoneyrecipient_id": 0,
                "prizemoneyrecipientsource": 0,
                "rider_list": "",
                "location": "",
                "stablerequest": "",
                "creation_user": "",
                "modified_date": "0000-00-00",
                "modified_time": "00:00:00",
                "modified_user": "",
                "customer_id": 0,
                "sgl_id": 0,
                "web_add": 0,
                "web_batchid": 0,
                "trainer_account": 0,
                "creating_sgl_id": 0,
                "exclude_from_trainer_split": 0,
                "secured_payment_method_id": 0,
                "pmr_notes": "",
                "entry_uuid": "",
                "horse_type": "",
                "horse_height": "",
                "horse_age": 0,
                "card_no": 0,
                "fei_import": 0,
                "status_override": 0,
                "on_vet_hold": 0,
                "parent_guardian_id": 0,
                "EntryRiders": [],
                "previous_rto_id": 0
            },
            "horse": {},
            "linked_people": {},
            "stable": {},
            "show": {},
            "prizeMoneyPrefs": {},
            "trips": [],
            "deleted_trips": [],
            "added_class_numbers": [],
            "deleted_class_numbers": [],
            "riderTrips": {},
            "horseMemberships": [],
            "personMemberships": [],
            "entryPrices": [],
            "deletedEntryPrices": [],
            "entryFeesData": {
                "entryFees": [],
                "masterFeeChangeEnabled": false
            },
            "paymentxEntries": [],
            "new_payments": [],
            "updated_payments": {},
            "new_withholding_taxes": [],
            "updated_withholding_taxes": [],
            "new_prize_money_payments": [],
            "deleted_prize_money_payment_ids": [],
            "updated_class_prizes": {},
            "entryAuthorizationRequests": [],
            "checkMissingEntryStall": {},
            "horse_search": '',
            "horse_search_status": '',
            "newEntryFee": {},
            "deletedEntryFees": [],
            "max_rider_limit": 1,
            "notes": [],
            "checklist": [],
            "update_cc_in_future_entries": false, // Used to indicate if user want to update credit card for future entries
            "usef_show_pass_overrides": [],
            "updated_withholding_tax_payments": [],
            "updated_account_registers": [],
            "updated_account_register_items": [],
            "updated_account_register_distributions": [],
            "prizeMoneyAppliedTrips": [],
            "prizeMoneyRollbackTrips": [],
            "accountRegisterRecordsForTrips": [],
            "paymentxEntriesForOtherEntries": [], // for rollback prize money and balance refund,
            "deleted_notes": [], // used to keep track of notes that have been deleted
            "balanceRefunded": false
        }
    })

    const { register, unregister, watch, setValue, reset, getValues, handleSubmit, formState, setFocus } = methods
    const { dirtyFields } = formState;
    useNavigationPrompt(formState)
    const row_selected = useAppSelector(state => state.currentDetailPageInfo.current_list_record_index);
	const row_ids = useAppSelector(state => state.currentDetailPageInfo.list_ids);
    const show_financials_locked = watch('show.islocked')
    const entry_fees_watch = watch('entryFeesData.entryFees')

    // open 'Account Tab' when opened from payment batch step 2 RTO Summary >>>
    const [searchParams, setSearchParams] = useSearchParams();
    // const [selectedTab, setSelectedTab] = useState(0)
    const [loading, setLoading] = useState(true);
    const [showTaxInformation, setShowTaxInformation] = useState(false); // show/hide the taxform dialog
    const [personIdForTaxStatusLookup, setPersonIdForTaxStatusLookup] = useState(0) // person id for which taxform lookup is called. lookup can be called from general and classes tab
    const [selectedTaxFormRequestType, setSelectedTaxFormRequestType] = useState('') // type of taxform request to be created (Manual, Request User Form)
    const authInfo = JSON.parse(localStorage?.getItem(AUTH_LOCAL_STORAGE_KEY)) // get auth info from local storage
    
    useEffect(() => {
        // Cleanup on unmount
        return () => unregister(); // This will unregister all fields
      }, [unregister]);
    
    useEffect(() => {
        if ( authInfo?.session_replays && window.newrelic) {
            window.newrelic.setUserId(`${authInfo?.first_name} ${authInfo?.last_name}`)
            window.newrelic.recordReplay()
        }
        let selectedTab = searchParams.get("selectedTab")
        if(selectedTab){
            searchParams.delete('selectedTab');
            setSearchParams(searchParams);
            setSelectedTab(selectedTab)
        }
    }, [])
    // open 'Account Tab' when opened from payment batch step 2 RTO Summary <<<

    useEffect(() => {
        let entry_id = methods.getValues('entry.entry_id') > 0 ? methods.getValues('entry.entry_id'):  params_entry_id
        setCurrentEntryId(entry_id) // Maintaining current entry id using state
    }, [methods.watch('entry.entry_id')]);

    useEffect(() => {
        let entry_id = params_entry_id > 0? params_entry_id :methods.getValues('entry.entry_id')
        setCurrentEntryId(entry_id) // Maintaining current entry id using state
    }, [params_entry_id]);

    const onSubmit = async (form_data) => {  
        if (isFormDataSubmitted) {
            return;
        }

        setIsFormDataSubmitted(true);
        loadingOverlay({show: true})
        onSubmitData(form_data)

        // Reset the flag
        setTimeout(() => {
            setIsFormDataSubmitted(false);
        }, 3000);
    }

    function getSubmitData(form_data) {
        let submit_data = dirtyValues(dirtyFields, form_data)
        if(current_entry_id == 0 || !current_entry_id){ // For new entry we populate entry number from backend
            if(!submit_data?.entry){// if no field is change in entry detail page entry object would not exist
                submit_data.entry = {} //Create new entry object
            }

            // Sending entry number explicitly to backend because we populate entry number for new entry from backend 
            // and if user does not change it. It will not be set dirty and won't be send to backend
            submit_data.entry.number = getValues('entry.number')
            if(submit_data.entry.trainer_account && !submit_data.entry.trainer_id){
                submit_data.entry.trainer_id = getValues('entry.trainer_id')
            }
            if(!submit_data.entry.responsibleparty_id){
                submit_data.entry.responsibleparty_id = 0
            }
            if(!submit_data.entry.prizemoneyrecipient_id){
                submit_data.entry.prizemoneyrecipient_id = 0   
            }
            if(!submit_data.entry.horse_id){
                submit_data.entry.horse_id = 0   
            }

            // Set for new entry
            submit_data.entry.responsiblepartysource = getValues('entry.responsiblepartysource')
            submit_data.entry.prizemoneyrecipientsource = getValues('entry.prizemoneyrecipientsource')
        }
        
        if(submit_data?.horse?.horse_data){
            submit_data.horse.horse_data.horse_id = methods.getValues('horse.horse_data.horse_id')
        }
        if(submit_data?.entry){
            submit_data.entry.entry_id = methods.getValues('entry.entry_id')
            submit_data.entry.EntryRiders = methods.getValues('entry.EntryRiders')
        }
        submit_data.entryPrices = methods.getValues('entryPrices')
        submit_data.entryFeesData = methods.getValues('entryFeesData')
        submit_data.trips = methods.getValues('trips')
        submit_data.deletedEntryPrices = methods.getValues('deletedEntryPrices')

        submit_data.entry_rider_records = methods.getValues("entry.EntryRiders")?.map(er => methods.getValues(`linked_people[${er.rider_id}].people_data`))
        
        return submit_data
    }

    // Description: calls validations API and if all validations are passed, calls entries save API.
    const onSubmitData = async (form_data, area='submit') => {  

        loadingOverlay({show: true})
        let submit_data = getSubmitData(form_data)
        let verified = false    
      
        if(submit_data?.horse?.updated_membership_data && submit_data?.horse?.updated_membership_data?.length > 0){
            for (const membership of submit_data?.horse?.updated_membership_data){
                if(!membership){
                    continue;
                }
                membership.valid_start = (membership.valid_start && moment(membership.valid_start).isValid())?moment(membership.valid_start).format('YYYY-MM-DD'):'0000-00-00';
                membership.valid_end = (membership.valid_end && moment(membership.valid_end).isValid())?moment(membership.valid_end).format('YYYY-MM-DD'):'0000-00-00';
            }
        }

        if(submit_data?.updated_linked_people && Object?.keys(submit_data?.updated_linked_people)?.length > 0){
            for (const rto of Object.keys(submit_data?.updated_linked_people)){
                let memberships = submit_data?.updated_linked_people[rto]?.membership_data ?? []
                if(memberships){
                    for (const membership of memberships){
                        if(!membership){
                            continue;
                        }
                        membership.valid_start = (membership.valid_start && moment(membership.valid_start).isValid())?moment(membership.valid_start).format('YYYY-MM-DD'):'0000-00-00';
                        membership.valid_end = (membership.valid_end && moment(membership.valid_end).isValid())?moment(membership.valid_end).format('YYYY-MM-DD'):'0000-00-00';
                    }
                }
            }
        }

        await axios.post( process.env.REACT_APP_NEST_API_URL + '/entries/validate', {
            params: {
                entry_id: current_entry_id ?? 0,
                customer_id: customer_id,
                data: submit_data,
                show_id: currentShowID
            }
            }).then(async (response) => {
                if(response.data.success) {
                    if(response.data.shouldCreateTrainerAccount){
                        let choice = await confirmDialog({message: `Trainer Account for ${methods.getValues('entry.trainer')}  does not exist. Do you want the system to create a trainer account for this trainer?`})
                        if(choice){
                            submit_data.shouldCreateTrainerAccount = true
                            methods.setValue('shouldCreateTrainerAccount', true, {shouldDirty: true})
                        }else{
                            submit_data.shouldCreateTrainerAccount = false
                            methods.setValue('shouldCreateTrainerAccount', false, {shouldDirty: true})
                        }
                    }

                    if(area == 'submit' || area === 'add-to-series' || area === 'add-fee' || area === 'print-invoice' || area == 'digital-signature' || ['move-credit-to-account', 'move-credit-from-account', 'rollforward-balances'].includes(area)){
                        if (area === 'add-to-series' || area === 'add-fee' || area == 'print-invoice' || area == 'digital-signature' || ['move-credit-to-account', 'move-credit-from-account', 'rollforward-balances'].includes(area)) {  // user should wait for data updates before printing invoice
                            await submitData(submit_data, area)
                        } else {
                            submitData(submit_data, area)
                        }
                    }

                    verified =  true;
                } else {
                    loadingOverlay({show: false})

                    if(response.data.type == 'error'){
                        alertDialog({message: response.data.error_message.join('<br />'), title: 'Error'})
                        setPrintInvoice(false);
                    }
                    else if(response.data.type == 'warning'){
                        if(response.data.shouldCreateTrainerAccount){
                            let choice = await confirmDialog({message: `Trainer Account for ${methods.getValues('entry.trainer')}  does not exist. Do you want the system to create a trainer account for this trainer?`})
                            if(choice){
                                submit_data.shouldCreateTrainerAccount = true
                                methods.setValue('shouldCreateTrainerAccount', true, {shouldDirty: true})
                            }else{
                                submit_data.shouldCreateTrainerAccount = false
                                methods.setValue('shouldCreateTrainerAccount', false, {shouldDirty: true})
                            }
                        }

                        let choice = await confirmDialog({message: response.data.error_message.join('<br />'), title: 'Warning', icon:'warning', okButtonTitle: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.WARNING.BUTTON.OK' }), cancelButtonTitle: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.WARNING.BUTTON.CANCEL' })})
                        if(choice){
                            verified =  true;
                            if(area == 'submit' || area === 'add-to-series' || area === 'add-fee' || area == 'print-invoice' || area == 'digital-signature' || ['move-credit-to-account', 'move-credit-from-account', 'rollforward-balances'].includes(area)){
                                if (area == 'print-invoice' || ['move-credit-to-account', 'move-credit-from-account', 'rollforward-balances'].includes(area)) {  // user should wait for data updates before printing invoice
                                    await submitData(submit_data, area)
                                } else {
                                    submitData(submit_data, area)
                                }
                            } else {
                                return {verified}
                            }
                        }
                    }
                }
        }).catch((error) => {
            if (error.response) {
                alertDialog({message: error.response.data.error});
            }
        });

        if(['payments', 'rollforward-balances'].includes(area)){
            return {verified}
        }
    }

    const [open_forms, setOpenForms] = useState([])
    const [gridRowData, setGridRowData] = useState('')

    const getDetailPageInfo = (id, prevData, area, component, data) => {        
        setRecordId(id)
        setSubArea(area)
        setPrevData(prevData)
        setChildComponent(component)
        sessionStorage.setItem("dialog_is_open_in_entries", true)
        setLinkClicked(linkClicked => !linkClicked)
        const forms_open = JSON.parse(sessionStorage.getItem('forms_open'));
        setOpenForms(forms_open)
        setGridRowData(data)
    };

    useEffect(() => {
        if( sessionStorage.getItem("dialog_is_open_in_entries") == "true"){
            sessionStorage.removeItem("dialog_is_open_in_entries")
            setLinkClicked(true)
            const forms_open = JSON.parse(sessionStorage.getItem('forms_open'));
            if(open_forms.includes(sub_area+"_"+record_id) == false){
              forms_open.push(sub_area+"_"+record_id);
              sessionStorage.setItem('forms_open', JSON.stringify(forms_open));
            }
        }
    }, [linkClicked]);

    useEffect(()=> {
        setEntry_id(params_entry_id)
    }, [params_entry_id])

    useEffect(() => {
        const next_button = document.getElementById('next-btn');
        const last_button = document.getElementById('last-btn');
        const prev_button = document.getElementById('prev-btn');
        const first_button = document.getElementById('first-btn');
        if( row_selected == row_ids?.length -1 ){
          next_button.disabled = true;
          last_button.disabled = true;
        }
        if(row_selected == 0 ){
          prev_button.disabled = true;
          first_button.disabled = true;
        }
    },[])
    
    // Description: calls entries save API. Called in validations API response in case of success/confirmation.
    // area (default value - ''): to perform area/event specific actions
    // Example: we don't redirect after add to series and if save is successful, we open add to series form.
    const submitData = async (submit_data, area = '') => {
        loadingOverlay({ show: true })

        const next_button = document.getElementById('next-btn');
        const last_button = document.getElementById('last-btn');
        const prev_button = document.getElementById('prev-btn');
        const first_button = document.getElementById('first-btn');

        const forms_open = JSON.parse(sessionStorage.getItem('forms_open'));
        let id;
        if(forms_open && forms_open.length >= 1){
            const firstEntry = forms_open[0]; 
            id = Number(firstEntry.split('_')[1]); 
        }
  
        // means detail page is not open in modal
        if(props?.id === undefined && id > 0){
            next_button.disabled = false;
            prev_button.disabled = false;
            last_button.disabled = false;
            first_button.disabled = false;
        }

        // Set flag to true to propagate entry roles changes in entries of future shows
        submit_data.setSeriesRoles = true
        return axios.post( process.env.REACT_APP_NEST_API_URL + '/entries/detail', {
            params: {
                entry_id: current_entry_id,
                customer_id: customer_id,
                data: submit_data,
                show_id: currentShowID
            }
            }).then(async (response) => {
                if(response.data.success) {
                    loadingOverlay({show: false})
                    const seriesRolesMetadata = response.data.seriesRolesMetadata
                    setLockPrizeMoneyAction(false) // enable the prize money action drop down

                    // Flag to check if we need to immediately redirect (closeModal in case of drill down) after save or not.
                    let redirectOrCloseImmediately = true
                    if (seriesRolesMetadata && seriesRolesMetadata.seriesFutureShowEntries.length > 0) {
                        seriesRolesPropsRef.current.message = seriesRolesMetadata.message
                        seriesRolesPropsRef.current.seriesFutureShowEntries = seriesRolesMetadata.seriesFutureShowEntries
                        setShowUpdateEntryRolesForm(true)
                        redirectOrCloseImmediately = false 
                    }

                    // save entry and don't redirect it to listing when credit is moved to account register
                    if(['move-credit-to-account', 'rollforward-balances'].includes(area)){
                        redirectOrCloseImmediately = false
                    }

                    if(response.data.warning && response.data.warning?.length > 0){// Display warning if entry number is changed because of duplicate
                        alertDialog({message: response.data.warning.join('<br/>'), title: 'Warning'})
                    }

                    if (area === 'add-to-series') {
                        const entryId = response.data.entry.entry_id
                        // Don't close modal or redirect after save when save is called from add-to-series button.
                        await reloadPageData(entryId).then(() => {
                            if (entry_id == 0) { // Update URL for new entry
                                window.history.replaceState({}, '', `${entryId}`)
                            }
                            setShowAddToSeriesForm(true)
                        }) 
                    } else if(area === 'add-fee' || area === 'print-invoice' || area == 'digital-signature' || area == 'rollforward-balances'){
                        const entryId = response.data.entry.entry_id
                        // Don't close modal or redirect after save when save is called from add-fee button.
                        await reloadPageData(entryId).then(() => {
                            if (entry_id == 0) { // Update URL for new entry
                                window.history.replaceState({}, '', `${entryId}`)
                            }

                            // Print invoice only when save is called from print invoice button
                            if (area == 'print-invoice') {
                                setPrintInvoice(true);
                            }
                        })
                    } else if (props?.id == undefined && Number(methods.getValues('entry.entry_id')) !== Number(row_ids[row_selected]) && !isNaN(Number(row_ids[row_selected]))){ 
                        // Use Case (Param Id changed): Next/Prev buttons
                        if(row_selected && row_selected != "" && row_ids[row_selected]){
                            if (redirectOrCloseImmediately) {
                                // Don't redirect if series roles need to be updated. Redirect will be handled from inside the form. 
                            history.push(`${PUBLIC_URL}/entries/detail/${row_ids[row_selected]}?customer_id=${customer_id}${window.location.hash}`, { allowNavigation: true })
                            } else {
                                seriesRolesPropsRef.current.onClose = () => {
                                    history.push(`${PUBLIC_URL}/entries/detail/${row_ids[row_selected]}?customer_id=${customer_id}${window.location.hash}`, { allowNavigation: true })
                                }
                            }
                        }
                    }
                    // redirect to entries listing. (as detail page was not opened in modal)
                    else if(props?.id == undefined && (current_entry_id > 0 && id > 0)){
                        if (redirectOrCloseImmediately) {
                            // Don't redirect if series roles need to be updated. Redirect will be handled from inside the form. 
                        history.push(`${PUBLIC_URL}/entries`, { allowNavigation: true })
                        } else {
                            seriesRolesPropsRef.current.onClose = () => {
                                history.push(`${PUBLIC_URL}/entries`, { allowNavigation: true })
                            }
                        }
                    }
                    else if(props?.id == undefined && (current_entry_id == 0 || id == 0)){
                        if (redirectOrCloseImmediately) {
                            // Don't redirect if series roles need to be updated. Redirect will be handled from inside the form. 

                            history.push(`${PUBLIC_URL}/saving?returnTo=/entries/detail/0`, { allowNavigation: true });
                        } else {
                            seriesRolesPropsRef.current.onClose = () => {
                                history.push(`${PUBLIC_URL}/saving?returnTo=/entries/detail/0`, { allowNavigation: true });
                            }
                        }
                    }
                    // close modal. (as detail page was opened in modal)
                    else{
                        const handleModalClose = () => {
                        if(props.updateCallbackFromParent){
                            props.updateCallbackFromParent()
                            props.closeModal()
                        }
                        }

                        if (redirectOrCloseImmediately) {
                            handleModalClose()
                        } else {
                            seriesRolesPropsRef.current.onClose = () => { handleModalClose() }
                        }
                    }

                      if( row_selected == row_ids?.length -1 && props?.id === undefined && id > 0){
                        if (next_button && last_button) {
                            next_button.disabled = true;
                            last_button.disabled = true;
                            prev_button.disabled = false;
                            first_button.disabled = false;
                        }
                      }
                      if(row_selected == 0 && props?.id === undefined && id > 0){
                        if (prev_button && first_button) {
                            prev_button.disabled = true;
                            first_button.disabled = true;
                            next_button.disabled = false;
                            last_button.disabled = false;
                        }
                      }
                } else {
                    loadingOverlay({show: false})
                    alertDialog({message: response.data.error_message, title: 'Error'})
                    
                }
    
            })
    }

    const getMembershipsUpdatedRecords = () => {
        let updated_record = {}
        let deleted_record = []
        let linked_people = methods.getValues('linked_people')

        // getting entry linked people ids list
        let entry_linked_people_ids = [methods.getValues('entry.entryowner_id'), methods.getValues('entry.trainer_id'), methods.getValues('entry.responsibleparty_id'), methods.getValues('entry.prizemoneyrecipient_id')]
        let entryRiders = methods.getValues('entry.EntryRiders')
        if(entryRiders?.length > 0){
            for(let rider of entryRiders){
                if(!rider.isDeleted){
                    entry_linked_people_ids.push(rider.rider_id)
                }
            }
        }

        entry_linked_people_ids = [...new Set(entry_linked_people_ids)];

        entry_linked_people_ids = entry_linked_people_ids.filter(id => (id !== 0))

        let horse = methods.getValues('horse')
        let people_ids = Object.keys(linked_people)
        let entryFees = methods.getValues('entryFeesData.entryFees')
        
        if(entry_linked_people_ids.length > 0){
            for(let people_id of entry_linked_people_ids){
                if(linked_people[people_id]){
                    let record = {
                        people_data: linked_people[people_id].people_data?.isUpdated? linked_people[people_id].people_data: {},
                        membership_data: linked_people[people_id].membership_data?.filter(membership => membership.isUpdated),
                        membership_override_data: linked_people[people_id].membership_override_data ?? {},
                        entry_authorization_request: (linked_people[people_id]?.entryAuthorizationRequest && linked_people[people_id]?.entryAuthorizationRequest?.isUpdated)? linked_people[people_id].entryAuthorizationRequest: {},
                        updateSeriesAuthorizationRequests: linked_people[people_id]?.updateSeriesAuthorizationRequests? linked_people[people_id].updateSeriesAuthorizationRequests: false,
                    }
                    if(Object.keys(record.people_data).length > 0 || record.membership_data?.length > 0 || Object.keys(record.membership_override_data).length > 0 || Object.keys(record.entry_authorization_request).length > 0){
                        updated_record[people_id] = record
                    }
                }
            }

            if(Object.keys(updated_record).length > 0){
                methods.setValue('updated_linked_people', updated_record, {shouldDirty: true})
            }
        }

        if(horse.memberships_data){
            let horseMemberships = horse.memberships_data.filter(membership => membership.isUpdated)
            if(horseMemberships.length > 0){
                methods.setValue('horse.updated_membership_data', horseMemberships, {shouldDirty: true})
            }   
        }

        if(entryFees?.length > 0){
            // Filter updated entry fees 
            let updatedEntryFees = entryFees.filter(entryFee => entryFee.isUpdated && entryFee.entryfees_id)
            if(updatedEntryFees.length > 0){
                methods.setValue('updatedEntryFees', updatedEntryFees, {shouldDirty: true})
            }   

            // Filter new entry fees
            let addedEntryFees = entryFees?.filter(entryFee => !entryFee.entryfees_id || entryFee.entryfees_id < 0)
            if(addedEntryFees?.length > 0){
                methods.setValue('addedEntryFees', addedEntryFees, {shouldDirty: true})
            }   
        }
        deleted_record = entryRiders?.filter(entryRider => entryRider?.isDeleted )
        methods.setValue('deleted_riders', deleted_record, {shouldDirty: true})
    }


    const watchTrainerAccount = watch("entry.trainer_account")?watch("entry.trainer_account"):false
    const watchEntryNumber = watch("entry.number")

    // Get both fields from database
    const starting_web_entry_number = methods.watch('show.starting_webentry_number')
    const ending_web_entry_number = methods.watch('show.ending_web_entry_number')

    const [showAddToSeriesForm, setShowAddToSeriesForm] = useState(false)
    const [showPutOnHoldForm, setShowPutOnHoldForm] = useState(false)
    const [lockEntry, setLockEntry] = useState(false)
    const [scratchEntryBtnLabel, setScratchEntryBtnLabel] = useState(methods.watch('entry.scratched') ? "UNSCRATCHENTRY": "SCRATCHENTRY")
    const [pastBalanceMsg, setPastBalanceMsg] = useState(intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.CHECKPASTBALANCES' }))
    const [entryStatus, setEntryStatus] = useState()
    const [on_vet_hold, setOnVetHold] = useState(false);
    const {currentUser} = useAuth()

    const goToAddToSeries = async () => {
        let choice = await confirmDialog({
            message: intl.formatMessage({id: "FORM.INPUT.ENTRIES.TAB.DETAIL.ADDTOSERIES"}),
            title: "warning",
            icon: "warning"
        })

        if(choice){
            // call entries save
            getMembershipsUpdatedRecords()
            await onSubmitData(methods.getValues(), 'add-to-series')
        }
    }

    const callFormSubmit = async (area = '') => {
        getMembershipsUpdatedRecords()
        await onSubmitData(methods.getValues(), area)
    }

    const displayOnVetHoldDialog = async (e) => {
        if(methods.getValues('horse.horse_data') && methods.getValues('horse.horse_data.horse_id') && methods.getValues('horse.horse_data.horse_id') !== 0){
            if(e.target.checked){
                setShowPutOnHoldForm(true)
            }else{
                let horseName = methods.getValues('horse.horse_data.name')
                let choice = await confirmDialog({
                    message: "Are you sure you want to remove " + horseName + " from hold",
                    title: "warning",
                    icon: "warning"
                })

                if(choice){
                    methods.setValue('horse.horse_data.on_vet_hold', false, {shouldDirty: true})
                    methods.setValue('horse.horse_data.on_vet_hold_reason', '', {shouldDirty: true})
                    methods.setValue('entry.on_vet_hold', !e.target.checked, {shouldDirty: true})
                    setOnVetHold(!e.target.checked)
                }
                else{
                    setOnVetHold(e.target.checked)
                }
                entryCheckOnHold()
            }
        }else{
            alertDialog({message: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.ALERT.ONVETHOLD' })})
            setOnVetHold(false)

        }
    }

    const checkPastBalance = () => {
        setPastBalanceMsg("Past Balance(s) exist.")
    }

    const scratchUnscratchEntryDialog = async () => {
        if(methods.watch('entry.scratched')){
            let choice = await confirmDialog({
                message: "Are you sure you want to Un-Scratch this entry?",
                title: "",
                icon: "warning"
            })
            if(choice){
                methods.setValue('entry.scratched', 0, {shouldDirty: true})
                scratchUnscratchEntry()
            }

        }else{
            let goneEntries = methods.getValues('trips').filter(trip => trip.gone_in)
            let placed_trips = methods.getValues('trips').filter(trip => trip.placing > 0)
            if(goneEntries.length > 0)
            {
                alertDialog({message: "Cannot scratch this Entry because some trips are Marked as Gone."})
            } else if(placed_trips.length > 0 && goneEntries.length == 0)
            {
                alertDialog({message: "Cannot scratch this Entry because it is already placed."})
            } else 
            {
                    let choice = await confirmDialog({
                        message: "Are you sure you want to Scratch this entry?",
                        title: "",
                        icon: "warning"
                    })
                    if(choice){
                        let horse = {
                            usef_age_verified: methods.getValues("horse.horse_data.usef_age_verified"),
                            usef_measurement_cards: methods.getValues("horse.horse_data.usef_measurement_cards"),
                            measurement_override_date: methods.getValues("horse.horse_data.measurement_override_date")
                        }
                        axios.post( process.env.REACT_APP_NEST_API_URL + '/entries/scratchEntry', {
                            params: {
                                customer_id: customer_id,
                                entry: methods.getValues('entry'),
                                trips: methods.getValues('trips'),
                                show: methods.getValues('show'),
                                entry_rider_records: methods.getValues("entry.EntryRiders").map(er => methods.getValues(`linked_people[${er.rider_id}].people_data`)),
                                entryFees: methods.getValues('entryFeesData.entryFees'),
                                entry_prices: methods.getValues("entryPrices"),
                                horse,
                                show_id: methods.getValues('entry.show_id')?methods.getValues('entry.show_id'):currentShowID,
                            }
                        }).then( async response => {
                            if(response.data.success){
                                
                                // if(response.data.rule_failed_messages.length > 0){
                                //     // Display the failed rule messages
                                //     // props.setErrors(response.data.rule_failed_messages[response.data.rule_failed_messages.length - 1])
                                // } else{
                                //     // props.setErrors('')
                                // }
            
                                if(response.data.deletedEntryFees.length > 0){
                                    let deletedEntryFees = methods.getValues('deletedEntryFees')? methods.getValues('deletedEntryFees'):[]
                                    deletedEntryFees = deletedEntryFees.concat(response.data.deletedEntryFees)
                                    methods.setValue('deletedEntryFees', deletedEntryFees, {shouldDirty: true})
                                }
                                methods.setValue('trips', response.data.trips, {shouldDirty: true})
                                methods.setValue('entry', response.data.entry, {shouldDirty: true})
                                methods.setValue('entryFeesData.entryFees', response.data.entry_fees, {shouldDirty: true})
                                methods.setValue('entryPrices', response.data.entry_prices, {shouldDirty: true})
                                const existingDeletedTrips = methods.getValues("deleted_trips") ? methods.getValues("deleted_trips") : []
                                methods.setValue('deleted_trips', [...new Set([...existingDeletedTrips, ...response.data.deleted_trips])], {shouldDirty: true})
                                // const existingAddedClasses = methods.getValues("added_class_numbers") ? methods.getValues("added_class_numbers") : []
                                // methods.setValue('added_class_numbers', [...new Set([...existingAddedClasses, ...response.data.added_class_numbers])], {shouldDirty: true})
                                // const existingDeletedClasses = methods.getValues("deleted_class_numbers") ? methods.getValues("deleted_class_numbers") : []
                                // methods.setValue('deleted_class_numbers', [...new Set([...existingDeletedClasses, ...response.data.deleted_class_numbers])], {shouldDirty: true})
                                methods.setValue('deletedEntryPrices', response.data.deleted_entry_prices, {shouldDirty: true})
            
                                scratchUnscratchEntry()

                            }
                        })
                        
                    }
                
                
            }
            
        }
    }

    const debitCreditTotalRecal = async (showOverlay = true) => {
        let pmr_id = methods.getValues('entry.prizemoneyrecipient_id')
        let pmr = methods.getValues(`linked_people[${pmr_id}].people_data`)
        let rp_id = methods.getValues('entry.responsibleparty_id')
        let rp = methods.getValues(`linked_people[${rp_id}].people_data`)
        if(methods.getValues('entry.show_id') > 0){
           if(showOverlay) { loadingOverlay({show: true}) }
            axios.post( process.env.REACT_APP_NEST_API_URL + '/entries/debitCreditTotalRecal', {
                params: {
                    entry_id:methods.getValues('entry.entry_id'),
                    customer_id: customer_id,
                    entry: methods.getValues('entry'),
                    trips: methods.getValues('trips'),
                    entry_fees: methods.getValues('entryFeesData.entryFees'),
                    entry_prices: methods.getValues("entryPrices"),
                    paymentxentries: methods.getValues("paymentxEntries"),
                    show_id: methods.getValues('entry.show_id') && methods.getValues('entry.show_id') > 0 ? methods.getValues('entry.show_id'):currentShowID,
                    pmr_obj: pmr,
                    rp_obj: rp
                }
                }).then( async response => {
                    if(response.data.success){
                        methods.setValue('entry', response.data.entry, {shouldDirty: true})
                        if(response.data.pmr_obj){
                            methods.setValue(`linked_people[${pmr_id}].people_data`, response.data.pmr_obj)
                            methods.setValue(`linked_people[${pmr_id}].rtoBalance`, response.data.pmr_obj.account_balance)
                        }
                        if(response.data.rp_obj){
                            methods.setValue(`linked_people[${rp_id}].people_data`, response.data.rp_obj)
                            methods.setValue(`linked_people[${rp_id}].rtoBalance`, response.data.rp_obj.account_balance)
                        }
                    }
                    loadingOverlay({show: false})

                    let dropInputRef = document.getElementById('ENTERDROPS');
                    if (dropInputRef && methods.getValues('dropInputRef')) {
                        setTimeout(() => {
                            dropInputRef.focus();
                        }, 400);
                    }
                })
        }   
         
    }

    const scratchUnscratchEntry = () => {
        let entryNotes = methods.getValues('entry.entry_notes')
        entryNotes = entryNotes + `${moment().format('MM/DD/YY')} - ${moment().format('HH mm A')} ${currentUser.first_name} ${currentUser.last_name} - Entry ${scratchEntryBtnLabel == 'SCRATCHENTRY'? "Scratched": "Un-Scratched"}.\n`
        methods.setValue('entry.entry_notes', entryNotes, {shouldDirty: true})
        setScratchEntryBtnLabel(scratchEntryBtnLabel == "SCRATCHENTRY" ? "UNSCRATCHENTRY" : "SCRATCHENTRY")
        //  TODO
        //OBJECT SET VISIBLE(*;"BalanceAfterPrizeMoney@";(Not([Entry]Balance=vr_BalanceAfterPrizeMoney)))  //HF 10/02/2007 - show/hide PMR total
    }

    const onTrainerAccountChange = async (e) => {
        let entry = methods.getValues('entry')
        let checked = e.target.checked

        if(checked){
            axios.get( process.env.REACT_APP_NEST_API_URL + '/entries/validateTrainerAccount', {
                params: {
                trainer_id: methods.getValues('entry.trainer_id'),
                show_id: currentShowID,
                customer_id: customer_id
                }
            }).then( async response => {
                if(response.data.personHasTrainerAccount){
                    let current_riders = entry?.EntryRiders?.filter(rider => !rider.isDeleted)
                    if(!current_riders || current_riders.length == 0){
                        if(entry.horse_id !== 0){
                            let choice = await confirmDialog({message: intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.ENTRIES.ALERTDIALOG.TRAINERACCOUNT.REMOVEHORSEANDOWNER' }), icon: 'Info'})
                            if(choice){
                                // methods.setValue('entry.entryowner_id', 0 , {shouldDirty: true})
                                // methods.setValue('entry.owner', "", {shouldDirty: true})

                                methods.setValue('entry.horse_id', 0, {shouldDirty: true})
                                methods.setValue('entry.status', "", {shouldDirty: true})
                                methods.setValue('entry.horse_age', 0 , {shouldDirty: true})
                                methods.setValue('entry.horse_height', "", {shouldDirty: true})
                                methods.setValue('entry.horse_type', "", {shouldDirty: true})
                                methods.setValue('entry.horse', "", {shouldDirty: true})

                                methods.setValue('horse.horse_data.dob', '', {shouldDirty: true})
                                methods.setValue('horse.horse_data.sex', '', {shouldDirty: true})
                                methods.setValue('horse.horse_data', {}, {shouldDirty: true})

                                let horse_organizations = methods.getValues('horse.horse_organizations');
                                methods.setValue('horse.memberships_data', horse_organizations)

                                methods.setValue('entry.responsiblepartysource', 3, {shouldDirty: true})
                                methods.setValue('entry.responsibleparty_id', methods.getValues('entry.trainer_id'), {shouldDirty: true})
                                methods.setValue('entry.prizemoneyrecipientsource', 3, {shouldDirty: true})
                                methods.setValue('entry.prizemoneyrecipient_id', methods.getValues('entry.trainer_id'), {shouldDirty: true})
        
                                let choice = await confirmDialog({message: intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.ENTRIES.DIALOG.TRAINERACCOUNT.REMOVEDEFAULTFEES' }), icon: 'Info'})
                                if(choice){
                                    removeDefaulFees()
                                }
                            }else{
                                checked = !checked
                            }


                        }else{

                            methods.setValue('entry.responsiblepartysource', 3, {shouldDirty: true})
                            methods.setValue('entry.responsibleparty_id', methods.getValues('entry.trainer_id'), {shouldDirty: true})
                            methods.setValue('entry.prizemoneyrecipientsource', 3, {shouldDirty: true})
                            methods.setValue('entry.prizemoneyrecipient_id', methods.getValues('entry.trainer_id'), {shouldDirty: true})

                            let choice = await confirmDialog({message: intl.formatMessage({ id: 'FORM.ADVANCEDSEARCH.ENTRIES.DIALOG.TRAINERACCOUNT.REMOVEDEFAULTFEES' }), icon: 'Info'})
                            if(choice){
                                removeDefaulFees()
                            }
                        }
                    }
                    else{
                        alertDialog({message: intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.ALERTDIALOG.TRAINERACCOUNT.HORSESANDCLASSESERROR'})})
                        checked = !checked
                    }
                }
                else{
                    alertDialog({message: intl.formatMessage({id: 'FORM.ADVANCEDSEARCH.ENTRIES.ALERTDIALOG.TRAINERACCOUNT.EXISTINGTRAINERACCOUNT'})})
                    checked = !checked
                }
                setValue('entry.trainer_account', checked, {shouldDirty:true})
            })
        } else {
            setValue('entry.trainer_account', checked, {shouldDirty:true})

        }
    }

    // !4D -> entry_RemoveDefaultFees
    const removeDefaulFees = () => {
        let entry_fees = methods.getValues('entryFeesData.entryFees')
        let deleted_entry_fee_list = []
        for (let entry_fee of entry_fees){
            if(!entry_fee.refund){
                if(!entry_fee.refund_id || entry_fee.refund_id == 0){
                    entry_fee["reason"] = "Entry fee was deleted - " + entry_fee.description + " (Reason: Entry was turned into a Trainer Account.) for "+ entry_fee.totalprice
                    deleted_entry_fee_list.push(entry_fee)
                }
            }
        }
        if(deleted_entry_fee_list.length > 0){
            let deletedEntryFees = methods.getValues('deletedEntryFees')? methods.getValues('deletedEntryFees'):[]
            deletedEntryFees = deletedEntryFees.concat(deleted_entry_fee_list)
            methods.setValue('deletedEntryFees', deletedEntryFees, {shouldDirty: true})
            
            // Remove deleted row from Ag-grid
            let deleted_fee_ids = deleted_entry_fee_list.map(f => f.showfees_id)
            const filteredEntryFees = methods.getValues("entryFeesData.entryFees").filter((entryFee, index) => !deleted_fee_ids.includes(entryFee.showfees_id))
            methods.setValue("entryFeesData.entryFees", filteredEntryFees)
        }
    }

    const checkMissingEntryStall = async (entry, entryFeesData) => {
        const response = await axios.post( process.env.REACT_APP_NEST_API_URL + '/entries/checkMissingEntryStall', {
            params: {
                entry_id: current_entry_id ?? 0,
                trainer_account: entry.trainer_account,
                trainer_id: entry.trainer_id,
                show_id: entry.show_id,
                customer_id: customer_id,
                entry_fees_data: entryFeesData,
            }
        })
        return response.data
    }

    useEffect(() => {
        let starting_webentry_number = methods.watch('show.starting_webentry_number') ? methods.watch('show.starting_webentry_number') : 10000;
        let ending_web_entry_number = methods.watch('show.ending_web_entry_number') ? methods.watch('show.ending_web_entry_number') : 15000;
         
        if(watchEntryNumber >= starting_webentry_number && watchEntryNumber <= ending_web_entry_number){
            setLockEntry(false)
            let entryNumberInputRef = document.getElementById('ENTRYNUMBER');
                if (entryNumberInputRef) {
                    setTimeout(() => {
                        entryNumberInputRef.focus();
                    }, 100);
                }
        }else if(current_entry_id > 0){
            setLockEntry(true)
        }
    }, [])

    const getDenormalizeIds = () => {
        let peopleIds = [ methods.getValues('entry.trainer_id'), methods.getValues('entry.entryowner_id'), methods.getValues('entry.responsibleparty_id'), methods.getValues('entry.prizemoneyrecipient_id') ]
        if(methods.getValues('entry.EntryRiders')){
            for(let rider of methods.getValues('entry.EntryRiders')){
                peopleIds.push(rider.rider_id)
            }
        }

        peopleIds = peopleIds.filter(id => id != 0)

        // return {
        //     horseId: Number(methods.getValues('entry.entry_id')) === Number(params_entry_id) || methods.getValues('entry.entry_id') ? methods.getValues('entry.horse_id') : 0,
        //     linkedPeopleIds: Number(methods.getValues('entry.entry_id')) === Number(params_entry_id) ? peopleIds : [],
        //     stableId: Number(methods.getValues('entry.entry_id')) === Number(params_entry_id) ? methods.getValues('entry.stable_id') : 0
        // }

        return {
            horseId: props?.id === undefined && Number(methods.getValues('entry.entry_id')) !== Number(row_ids[row_selected]) && !isNaN(Number(row_ids[row_selected])) ? 0 : methods.getValues('entry.horse_id'),
            linkedPeopleIds: props?.id === undefined && Number(methods.getValues('entry.entry_id')) !== Number(row_ids[row_selected]) && !isNaN(Number(row_ids[row_selected])) ? [] : peopleIds ,
            stableId: props?.id === undefined && Number(methods.getValues('entry.entry_id')) !== Number(row_ids[row_selected]) && !isNaN(Number(row_ids[row_selected])) ? 0 : methods.getValues('entry.stable_id')
        }
    }
    
    const reloadPageData = async (entryId = 0) => {
        if (!entryId) {
            entryId = current_entry_id
        }

        methods.setValue('existing_people', methods.getValues('linked_people'))
        // Show progress overlay
        const eventID = getEventID('entry_detail_' + params_entry_id) 
        progressBar({ show: true, eventID, showProgressMessageOnly: true })
        setLockPrizeMoneyAction(false) // enable the prize money action drop down

        await axios.all([
            axios.get( process.env.REACT_APP_NEST_API_URL + '/entries/detail', {
                params: {
                entry_id: entryId,
                customer_id: customer_id,
                show_id: currentShowID,
                event_id: eventID,
                call_lookup: update_data == 0, // Only call lookups on entry page load the first time and not when navigating back from modal
                denormalize_ids: getDenormalizeIds()
                }
            }),
            axios.get( process.env.REACT_APP_NEST_API_URL + '/entries/metaData', {
                params: {
                    show_id: currentShowID,
                    entry_id: entryId,
                    customer_id: customer_id
                }
            }),
        ]).then (
            axios.spread( ( {data : entryData}, {data: metaData}) => {

                // Hide progress overlay
                progressBar({ show: false, showProgressMessageOnly: true, eventID })

                //Check if record id is invalid then redirect to list page
                if((entryData || entryData === '') && isInvalidRecord(entryData.entry, entryId, 'entry_id')){
                    navigate('/entries')
                }

                if(entryData){
                    if(entryId > 0 && entryData.entry.show_id != currentShowID){ //check if input show does not match current show in show Picker
                        dispatch(setNewShowId(entryData.entry.show_id)) //In this case update Show in ShowPicker
                        getMetaAndBalance(entryData.entry.show_id)
                    }
                    setFormMeta(metaData)
                    if(sessionStorage.getItem('forms_open') == undefined || entryId == entry_id){
                        sessionStorage.setItem('forms_open', JSON.stringify(["Entries_"+Number(entryId)]));
                    }

                    if(update_data > 0){

                        if(sub_area == 'People'){
                            const existing_records = methods.getValues('existing_people')
                            // This is here only to handle search case, if a new RTO is search or added
                            // It will be get set in existing people and if its detailed is view and changes will be made there
                            // New Hit won't have the searched RTO data. So it will get merge here
                            if(entryData.linked_people[record_id]){
                                existing_records[record_id] = entryData.linked_people[record_id]
                            }

                            methods.setValue('linked_people', existing_records)

                            // Initializing denormalize fields
                            if(methods.getValues('entry.entryowner_id') && methods.getValues('entry.owner') != entryData.linked_people[methods.getValues('entry.entryowner_id')].people_data.fl_name){
                                methods.setValue('entry.owner', entryData.linked_people[methods.getValues('entry.entryowner_id')].people_data.fl_name, {shouldDirty: true})
                            }

                            if(methods.getValues('entry.trainer_id') && methods.getValues('entry.trainer') != entryData.linked_people[methods.getValues('entry.trainer_id')].people_data.fl_name){
                                methods.setValue('entry.trainer', entryData.linked_people[methods.getValues('entry.trainer_id')].people_data.fl_name, {shouldDirty: true})
                            }

                            if(methods.getValues('entry.responsibleparty_id') && methods.getValues('entry.responsibleparty') != entryData.linked_people[methods.getValues('entry.responsibleparty_id')].people_data.fl_name){
                                methods.setValue('entry.responsibleparty', entryData.linked_people[methods.getValues('entry.responsibleparty_id')].people_data.fl_name, {shouldDirty: true})
                            }

                            if(methods.getValues('entry.prizemoneyrecipient_id') && methods.getValues('entry.prizemoneyrecipient') != entryData.linked_people[methods.getValues('entry.prizemoneyrecipient_id')].people_data.fl_name){
                                methods.setValue('entry.prizemoneyrecipient', entryData.linked_people[methods.getValues('entry.prizemoneyrecipient_id')].people_data.fl_name, {shouldDirty: true})
                            }
                        }

                        

                        // Same goes here when stable is searched and some fields were changed and the current hit won't 
                        // Have this stable data so get the stable from existing stable only when stable_id matches
                        if(sub_area == 'Stables'){
                            methods.setValue('stable', entryData.stable, { shouldDirty: true })
                            // Initializing denormalize fields
                            if(methods.getValues('entry.stable') != entryData.stable.name){
                                methods.setValue('entry.stable', entryData.stable.name, { shouldDirty: true })
                            }
                        }
                        // Same goes here when horse is searched and some fields were changed and the current hit won't 
                        // Have this horse data so get the horse from existing horse only when horse_id matches
                        if(sub_area == 'Horses'){
                            methods.setValue( 'horse', entryData.horse, { shouldDirty: true})
                            // Initializing denormalize fields
                            if(methods.getValues('entry.horse') != entryData.horse.horse_data.name){
                                methods.setValue('entry.horse', entryData.horse.horse_data.name, { shouldDirty: true })
                            }
                        }

                    }else{
                        reset(entryData)
                        methods.setValue('deleted_riders', [])
                        var paymentxEntries = methods.watch('paymentxEntries')
                        var pxe_updated = false
                        // set updated payment amount here
                        if(props?.updatedData && ((props?.updatedData.paymentxentry_id && props?.updatedData.amount_applied) || props?.updatedData?.new_pxe)){
                            if(props?.updatedData.paymentxentry_id && props?.updatedData.amount_applied){
                                var paymentxentry_id = props?.updatedData.paymentxentry_id;
                                var amount_applied = props?.updatedData.amount_applied;
                                let index = paymentxEntries.findIndex(pxe => pxe.sgl_id == paymentxentry_id)

                                if(index >= 0){
                                    paymentxEntries[index].amount_applied = amount_applied
                                    let updated_payments = methods.getValues('updated_payments')
                                    if(updated_payments){
                                        updated_payments[paymentxentry_id] = amount_applied
                                    } else {
                                        updated_payments = {}
                                        updated_payments[paymentxentry_id] = amount_applied

                                    }
                                    pxe_updated = true
                                    methods.setValue('updated_payments', updated_payments, {shouldDirty: true})
                                }
                            }
                            if (props?.updatedData.new_pxe){
                                paymentxEntries.push(props?.updatedData.new_pxe)
                                pxe_updated = true

                            }
                            if(pxe_updated){
                                methods.setValue('paymentxEntries', paymentxEntries)

                            }
                        }
                        if(props?.updatedData && props?.updatedData.updated_trip){
                            var trips = methods.watch('trips')
                            var updated_trip = props.updatedData.updated_trip
                            let index = trips.findIndex(trip => trip.class_id == updated_trip.class_id && trip.rider_id == updated_trip.rider_id)
                            if(index >= 0){
                                trips[index].scratch_trip = updated_trip.scratch_trip
                                trips[index].order_of_go = updated_trip.order_of_go
                                trips[index].gone_in = updated_trip.gone_in
                                trips[index].trip_verified = updated_trip.trip_verified
                                
                            } else {
                                trips.push(updated_trip)
                            }
                            methods.setValue('trips', trips)
                        }
             
                    }
                    
                    // debitCreditTotalRecal();
                    entryCheckOnHold()
                    setEntryNumber(entryData.entry.number)
                    setOnVetHold(entryData.entry.on_vet_hold?entryData.entry.on_vet_hold:false)
                    console.log('responsible_party_search from reload:', entryData.entry.responsibleparty)
                    methods.setValue('responsible_party_search', entryData.entry.responsibleparty, {shouldDirty: true})
                    methods.setValue('prize_money_recipient_search', entryData.entry.prizemoneyrecipient, {shouldDirty: true})
                }
            }))
        .catch(reason => {
            handleDetailPageCatch(reason.response, loadingOverlay, props)
        })

        setDisplayDeleteButton( params_entry_id > 0? true: false)
    }

    useEffect(() => {
        methods.setValue('existing_people', methods.getValues('linked_people'))
            // Show progress overlay
            const eventID = getEventID('entry_detail_' + params_entry_id) 
            progressBar({ show: true, eventID, showProgressMessageOnly: true })

            axios.all([
                axios.get( process.env.REACT_APP_NEST_API_URL + '/entries/detail', {
                    params: {
                    entry_id: current_entry_id,
                    customer_id: customer_id,
                    show_id: currentShowID,
                    event_id: eventID,
                    call_lookup: update_data == 0, // Only call lookups on entry page load the first time and not when navigating back from modal
                    denormalize_ids: getDenormalizeIds()
                    }
                }),
                axios.get( process.env.REACT_APP_NEST_API_URL + '/entries/metaData', {
                    params: {
                        show_id: currentShowID,
                        entry_id: current_entry_id ?? 0,
                        customer_id: customer_id
                    }
                }),
            ]).then (
                axios.spread( ( {data : entryData}, {data: metaData}) => {

                    // Hide progress overlay
                    progressBar({ show: false, showProgressMessageOnly: true, eventID })

                    //Check if record id is invalid then redirect to list page
                    if((entryData || entryData === '') && isInvalidRecord(entryData.entry, current_entry_id, 'entry_id')){
                        navigate('/entries')
                    }

                    if(entryData){
                        if(current_entry_id > 0 && entryData.entry.show_id != currentShowID){ //check if input show does not match current show in show Picker
                            dispatch(setNewShowId(entryData.entry.show_id)) //In this case update Show in ShowPicker
                            getMetaAndBalance(entryData.entry.show_id)
                        }
                        setFormMeta(metaData)
                        if(sessionStorage.getItem('forms_open') == undefined || current_entry_id == entry_id){
                            sessionStorage.setItem('forms_open', JSON.stringify(["Entries_"+Number(current_entry_id)]));
                        }

                        // setPreviousValues({
                        //     entryPrices: entryData?.entryPrices ? entryData?.entryPrices?.length : 0,
                        //     entryFeesData: entryData?.entryFeesData?.entryFees ? entryData?.entryFeesData?.entryFees?.length : 0,
                        //     trips: entryData?.trips ? entryData?.trips?.length : 0,
                        //     paymentxEntries: entryData?.paymentxEntries ? entryData?.paymentxEntries?.length : 0,
                        //     prizemoneyrecipient_id : entryData?.entry?.prizemoneyrecipient_id ? entryData?.entry?.prizemoneyrecipient_id : 0,
                        //     responsibleparty_id : entryData?.entry?.responsibleparty_id ? entryData?.entry?.responsibleparty_id : 0
                        // })

                        if(update_data > 0){

                            if(sub_area == 'People'){
                                const existing_records = methods.getValues('existing_people')
                                // This is here only to handle search case, if a new RTO is search or added
                                // It will be get set in existing people and if its detailed is view and changes will be made there
                                // New Hit won't have the searched RTO data. So it will get merge here
                                if(entryData.linked_people[record_id]){
                                    existing_records[record_id] = entryData.linked_people[record_id]
                                }
    
                                methods.setValue('linked_people', existing_records)
    
                                // Initializing denormalize fields
                                if(methods.getValues('entry.entryowner_id') && methods.getValues('entry.owner') != entryData.linked_people[methods.getValues('entry.entryowner_id')].people_data.fl_name){
                                    methods.setValue('entry.owner', entryData.linked_people[methods.getValues('entry.entryowner_id')].people_data.fl_name, {shouldDirty: true})
                                }
    
                                if(methods.getValues('entry.trainer_id') && methods.getValues('entry.trainer') != entryData.linked_people[methods.getValues('entry.trainer_id')].people_data.fl_name){
                                    methods.setValue('entry.trainer', entryData.linked_people[methods.getValues('entry.trainer_id')].people_data.fl_name, {shouldDirty: true})
                                }
    
                                if(methods.getValues('entry.responsibleparty_id') && methods.getValues('entry.responsibleparty') != entryData.linked_people[methods.getValues('entry.responsibleparty_id')].people_data.fl_name){
                                    methods.setValue('entry.responsibleparty', entryData.linked_people[methods.getValues('entry.responsibleparty_id')].people_data.fl_name, {shouldDirty: true})
                                }
    
                                if(methods.getValues('entry.prizemoneyrecipient_id') && methods.getValues('entry.prizemoneyrecipient') != entryData.linked_people[methods.getValues('entry.prizemoneyrecipient_id')].people_data.fl_name){
                                    methods.setValue('entry.prizemoneyrecipient', entryData.linked_people[methods.getValues('entry.prizemoneyrecipient_id')].people_data.fl_name, {shouldDirty: true})
                                }
                            }

                            

                            // Same goes here when stable is searched and some fields were changed and the current hit won't 
                            // Have this stable data so get the stable from existing stable only when stable_id matches
                            if(sub_area == 'Stables'){
                                methods.setValue('stable', entryData.stable, { shouldDirty: true })
                                // Initializing denormalize fields
                                if(methods.getValues('entry.stable') != entryData.stable.name){
                                    methods.setValue('entry.stable', entryData.stable.name, { shouldDirty: true })
                                }
                            }
                            // Same goes here when horse is searched and some fields were changed and the current hit won't 
                            // Have this horse data so get the horse from existing horse only when horse_id matches
                            if(sub_area == 'Horses'){
                                methods.setValue( 'horse', entryData.horse, { shouldDirty: true})
                                // Initializing denormalize fields
                                if(methods.getValues('entry.horse') != entryData.horse.horse_data.name){
                                    methods.setValue('entry.horse', entryData.horse.horse_data.name, { shouldDirty: true })
                                }
                            }

                        }else{
                            if(props?.id === undefined && Number(methods.getValues('entry.entry_id')) !== Number(row_ids[row_selected]) && !isNaN(Number(row_ids[row_selected]))){
                                reset(entryData)
                            }else{
                                reset(entryData, { keepDirtyValues: true })
                            }
                            methods.setValue('deleted_riders', [])
                            var paymentxEntries = methods.watch('paymentxEntries')
                            var pxe_updated = false
                            // set updated payment amount here
                            if(props?.updatedData && ((props?.updatedData.paymentxentry_id && props?.updatedData.amount_applied) || props?.updatedData?.new_pxe)){
                                if(props?.updatedData.paymentxentry_id && props?.updatedData.amount_applied){
                                    var paymentxentry_id = props?.updatedData.paymentxentry_id;
                                    var amount_applied = props?.updatedData.amount_applied;
                                    let index = paymentxEntries.findIndex(pxe => pxe.sgl_id == paymentxentry_id)

                                    if(index >= 0){
                                        paymentxEntries[index].amount_applied = amount_applied
                                        let updated_payments = methods.getValues('updated_payments')
                                        if(updated_payments){
                                            updated_payments[paymentxentry_id] = amount_applied
                                        } else {
                                            updated_payments = {}
                                            updated_payments[paymentxentry_id] = amount_applied

                                        }
                                        pxe_updated = true
                                        methods.setValue('updated_payments', updated_payments, {shouldDirty: true})
                                    }
                                }
                                if (props?.updatedData.new_pxe){
                                    paymentxEntries.push(props?.updatedData.new_pxe)
                                    pxe_updated = true

                                }
                                if(pxe_updated){
                                    methods.setValue('paymentxEntries', paymentxEntries)

                                }
                            }
                            if(props?.updatedData && props?.updatedData.updated_trip){
                                var trips = methods.watch('trips')
                                var updated_trip = props.updatedData.updated_trip
                                let index = trips.findIndex(trip => trip.class_id == updated_trip.class_id && trip.rider_id == updated_trip.rider_id)
                                if(index >= 0){
                                    trips[index].scratch_trip = updated_trip.scratch_trip
                                    trips[index].order_of_go = updated_trip.order_of_go
                                    trips[index].gone_in = updated_trip.gone_in
                                    trips[index].trip_verified = updated_trip.trip_verified
                                    
                                } else {
                                    trips.push(updated_trip)
                                }
                                methods.setValue('trips', trips)
                            }
                 
                        }
                        
                        entryCheckOnHold()
                        setEntryNumber(entryData.entry.number)
                        setOnVetHold(entryData.entry.on_vet_hold?entryData.entry.on_vet_hold:false)
                        console.log('entryData.entry.responsibleparty from useeffect', entryData.entry.responsibleparty)
                        methods.setValue('responsible_party_search', entryData.entry.responsibleparty, {shouldDirty: true})
                        methods.setValue('prize_money_recipient_search', entryData.entry.prizemoneyrecipient, {shouldDirty: true})

                        
                    }
                    setLoading(false)
                    console.log(current_entry_id, entryData?.entry.number, methods.watch('show.starting_webentry_number'), methods.watch('show.ending_web_entry_number'))
                    let starting_webentry_number = methods.watch('show.starting_webentry_number') ? methods.watch('show.starting_webentry_number') : 10000;
                    let ending_web_entry_number = methods.watch('show.ending_web_entry_number') ? methods.watch('show.ending_web_entry_number') : 15000;
        
                    if(current_entry_id == 0 || (entryData && entryData?.entry.number >= starting_webentry_number && entryData?.entry.number <= ending_web_entry_number)){
                        setLockEntry(false)
                        let entryNumberInputRef = document.getElementById('ENTRYNUMBER');
                        if (entryNumberInputRef) {
                            setTimeout(() => {
                                entryNumberInputRef.focus();
                            }, 100);
                        }
                    } else {
                        setLockEntry(true)
                    }
                    
                }))
            .catch(reason => {
                handleDetailPageCatch(reason.response, loadingOverlay, props)
                setLoading(false)

            })

                setDisplayDeleteButton( params_entry_id > 0? true: false)
                

    },[/*currentShowID,*/ entry_id, update_data])

    useEffect(()=>{
        if(update_data == 0 && !horse_updated && !rtos_updated){
            updateHorseRTOWithLookupData()
        }
    },[methods.getValues('horse'), methods.getValues('linked_people')])

    const updateHorseRTOWithLookupData = () => {
        // Make horse data dirty if horse data updated with lookup data
        let horse = methods.getValues('horse')
        if(horse.data_updated){
            setHorseUpdated(true)
            if(horse?.updated_horse_data && Object.keys(horse.updated_horse_data).length > 0){
                methods.setValue('horse.horse_data', {...horse.updated_horse_data}, { shouldDirty: true })
            }

            if(horse?.updated_membership_data?.length > 0){
                methods.setValue('horse.memberships_data', [...horse.updated_membership_data], { shouldDirty: true })
            }

            if(horse?.horse_data?.name && methods.getValues('entry.horse') != horse.horse_data.name){
                methods.setValue('entry.horse', horse.horse_data.name, { shouldDirty: true })
            }
        }

        // Make RTO data dirty if horse data updated with lookup data
        let rtos = methods.getValues('linked_people')
        let rto_data_updated = false
        for(const people_id in rtos){
            if(rtos[people_id]?.data_updated){                
                delete rtos[people_id].data_updated
                if(!rto_data_updated){
                    rto_data_updated = true
                }
            }
        }

        if(rto_data_updated){
            setRTOsUpdated(true)
            methods.setValue('linked_people', rtos, {shouldDirty: true})
            // methods.setValue('updated_linked_people', rtos, {shouldDirty: true})
        }

        if(methods.getValues('entry.entryowner_id') && rtos[methods.getValues('entry.entryowner_id')] && methods.getValues('entry.owner') != rtos[methods.getValues('entry.entryowner_id')].people_data.fl_name){
            methods.setValue('entry.owner', rtos[methods.getValues('entry.entryowner_id')].people_data.fl_name, {shouldDirty: true})
        }

        if(methods.getValues('entry.trainer_id') && rtos[methods.getValues('entry.trainer_id')] && methods.getValues('entry.trainer') != rtos[methods.getValues('entry.trainer_id')].people_data.fl_name){
            methods.setValue('entry.trainer', rtos[methods.getValues('entry.trainer_id')].people_data.fl_name, {shouldDirty: true})
        }

        if(methods.getValues('entry.responsibleparty_id') && rtos[methods.getValues('entry.responsibleparty_id')] && methods.getValues('entry.responsibleparty') != rtos[methods.getValues('entry.responsibleparty_id')].people_data.fl_name){
            methods.setValue('entry.responsibleparty', rtos[methods.getValues('entry.responsibleparty_id')].people_data.fl_name, {shouldDirty: true})
        }

        if(methods.getValues('entry.prizemoneyrecipient_id') && rtos[methods.getValues('entry.prizemoneyrecipient_id')] && methods.getValues('entry.prizemoneyrecipient') != rtos[methods.getValues('entry.prizemoneyrecipient_id')].people_data.fl_name){
            methods.setValue('entry.prizemoneyrecipient', rtos[methods.getValues('entry.prizemoneyrecipient_id')].people_data.fl_name, {shouldDirty: true})
        }
    }

    const getMetaAndBalance = (show_id) => {
        axios.all([
            axios.get( process.env.REACT_APP_NEST_API_URL + '/entries/metaData', {
                params: {
                    show_id: show_id,
                    entry_id: current_entry_id ?? 0,
                    customer_id: customer_id
                }
            }),
        ]).then (
            axios.spread( ( {data: metaData}) => {

                if(metaData){
                    setFormMeta(metaData)
                }
            }))
        debitCreditTotalRecal()
    }

    useEffect(() => {
        if(methods.getValues('horse.horse_data')){
            methods.setValue('entry.on_vet_hold', methods.getValues('horse.horse_data.on_vet_hold'))
            entryCheckOnHold()
        }
    }, [methods.watch('horse.horse_data')]);

    useEffect(() => {
        setOnVetHold(methods.getValues('entry.on_vet_hold'))

    }, [methods.watch('entry.on_vet_hold')]);

    const areBalanceRecalculateWatchedFieldsDirty = () => {
        // const watchedFields = [
        //     'entryPrices',
        //     'entryFeesData.entryFees',
        //     'trips',
        //     'paymentxEntries',
        //     'entry.prizemoneyrecipient_id',
        //     'entry.responsibleparty_id',
        // ]

        if( (dirtyFields['entry'] && dirtyFields['entry']['responsibleparty_id']) || 
            (dirtyFields['entry'] && dirtyFields['entry']['prizemoneyrecipient_id']) || 
            dirtyFields['added_class_numbers'] || dirtyFields['deleted_class_numbers'] || dirtyFields['deletedEntryFees']) {
                return true;
            }
        return false;
    };

    useEffect(() => {
        if (areBalanceRecalculateWatchedFieldsDirty()) {
            console.log('areBalanceRecalculateWatchedFieldsDirty(): ', areBalanceRecalculateWatchedFieldsDirty())

            const entryPrices = methods.watch('entryPrices')
            const entryFeesData = methods.watch('entryFeesData.entryFees')
            const trips = methods.watch('trips')
            const paymentxEntries = methods.watch('paymentxEntries');
            const prizemoneyrecipient_id = methods.watch('entry.prizemoneyrecipient_id')
            const responsibleparty_id = methods.watch('entry.responsibleparty_id')
            const deletedEntryFees = methods.watch('deletedEntryFees')
    
            if (
                (entryPrices && entryPrices.length !== previousValues.entryPrices) ||
                (entryFeesData && entryFeesData.length !== previousValues.entryFeesData) ||
                (deletedEntryFees && deletedEntryFees.length !== previousValues.deletedEntryFees) ||
                (trips && trips.length !== previousValues.trips) ||
                (paymentxEntries && paymentxEntries.length !== previousValues.paymentxEntries) ||
                prizemoneyrecipient_id !== previousValues.prizemoneyrecipient_id ||
                responsibleparty_id !== previousValues.responsibleparty_id
            ) {
                if (methods.getValues('entry') && methods.getValues('entry.number') > 0) {
                    debitCreditTotalRecal();
                }
    
                setPreviousValues({
                    entryPrices: entryPrices ? entryPrices.length : 0,
                    entryFeesData: entryFeesData ? entryFeesData.length : 0,
                    deletedEntryFees: deletedEntryFees ? deletedEntryFees.length : 0,
                    trips: trips ? trips.length : 0,
                    paymentxEntries: paymentxEntries ? paymentxEntries.length : 0,
                    prizemoneyrecipient_id,
                    responsibleparty_id,
                })
            }
        }
    }, [methods.watch('entryPrices'), methods.watch('entryFeesData.entryFees'), methods.watch('trips'),
        methods.watch('paymentxEntries'), methods.watch('entry.prizemoneyrecipient_id'), 
        methods.watch('entry.responsibleparty_id'), methods.watch('entry.deletedEntryFees')
    ]);
    
    useEffect(() => { //Update Callback useEffect
        if(syncEvent){
            let parsedEvent = JSON.parse(syncEvent.data)
            let variableName = ''
            let updateFlag = false
            let updateId =  parsedEvent.additional_data.triggered_id
            
            switch(parsedEvent.type){
                case `Classes-${customer_id}-${currentShowID}`:
                    variableName = 'trips'
                    let trips = methods.getValues('trips')
                    if(trips.find(trip => trip.class_id == updateId)){
                        updateFlag = true
                    }
                    break;
                
                case `Payments-${customer_id}-${currentShowID}`:
                    variableName = 'paymentxEntries'
                    let distributions = methods.getValues('paymentxEntries')
                    if(distributions.find(distribution => distribution.payment_id == updateId)){
                        updateFlag = true
                    }
                    break;
                
                case `Horses-${customer_id}`:
                    variableName = 'horse'
                    if(updateId == methods.getValues('horse.horse_data.horse_id')){
                        updateFlag = true
                    }
                    break;

                case `Entries-${customer_id}-${currentShowID}-${current_entry_id}`:
                    variableName = 'entry'
                    updateFlag = true
                    break;

                case 'Fees':
                    variableName = 'entryFeesData'
                    break;

                case `Stables-${customer_id}`:
                    variableName = 'stable'
                    if(updateId == methods.getValues('stable.stable_id')){
                        updateFlag = true
                    }
                    break;

                case `People-${customer_id}`:
                    variableName = 'linked_people'
                    let people = methods.getValues('linked_people')
                    if(updateId in people){
                        updateFlag = true
                    }
                    break;
            }

            if(updateFlag){
                let denormalizeIds = getDenormalizeIds()
                axios.get( process.env.REACT_APP_NEST_API_URL + '/entries/detail', {
                    params: {
                        entry_id: current_entry_id,
                        customer_id: customer_id,
                        show_id: currentShowID,
                        event_id: '',
                        call_lookup: false, // Only call lookups on entry page load the first time and not when navigating back from modal
                        denormalize_ids: denormalizeIds
                    }
                }).then((res) => {
                    if(parsedEvent.type.includes('Entries')){
                        // Keep Dirty Values can't handle arrays, so need to manually handle arrays
                        for(let dirty of Object.keys(dirtyFields)){
                            if(Array.isArray(methods.getValues(dirty)) || dirty == 'entryFeesData' || !(`${dirty}` in res.data)){ // keep old/dirty value for fields not received in response from lookup (card payment dialog was causing this)
                                res.data[dirty] = methods.getValues(dirty)
                            }
                        }
                        reset(res.data, { keepDirtyValues: true, keepDirty: true })
                        debitCreditTotalRecal(false)
                    }else{
                        if(parsedEvent.type.includes('Horses')){
                            let memberships = res.data['horse'].memberships_data
                            let updatedMemberships = methods.getValues('horse.memberships_data')
                            memberships = memberships.map(membership => {
                                let updatedMembership = updatedMemberships.find(m => m.organization_code == membership.organization_code && m.isUpdated)
                                if(updatedMembership){
                                    membership = updatedMembership
                                }
                                return membership
                            })

                            res.data['horse'].memberships_data = memberships

                            reset(res.data, { keepDirtyValues: true })
                        }
                        else if(parsedEvent.type.includes('People')){
                            let people = res.data['linked_people']
                            let memberships = people[updateId]?.membership_data
                            let person = people[updateId]?.people_data
                            let updatedMemberships = methods.getValues(`linked_people[${updateId}].membership_data`)
                            let updatedPerson = methods.getValues(`updatedPersons[${updateId}]`)
                            memberships = memberships?.map(membership => {
                                let updatedMembership = updatedMemberships.find(m => m.organization_code == membership.organization_code && m.isUpdated)
                                if(updatedMembership){
                                    membership = updatedMembership
                                }
                                return membership
                            })

                            for(let key in updatedPerson){
                                if(updatedPerson[key]){
                                    person[key] = updatedPerson[key]
                                }
                            }

                            people[updateId].membership_data = memberships
                            people[updateId].people_data = person
                            res.data[`linked_people`] = people
                        }
                        methods.setValue(variableName, res.data[variableName])
                    }
                })
            }            
        }
    }, [syncEvent]);

    useEffect(() => { // Remove all existing event listener and then generate and add new events with callback in SSE on change of output grid area
        removeAllEventListeners()
        return // SK - Disabling sync
        if(currentShowID && current_entry_id && sse && current_entry_id > 0){
            let syncEvents = []
            let eventIds = []
            // For Areas who contains show_id should add show id in its event id
            eventIds = [
                `Classes-${customer_id}-${currentShowID}`, 
                `Entries-${customer_id}-${currentShowID}-${current_entry_id}`, 
                `Payments-${customer_id}-${currentShowID}` ,
                `Horses-${customer_id}`,
                `People-${customer_id}`,
                `Stables-${customer_id}`
            ]

            for(let eventId of eventIds){
                // Sync Event will hold an array of eventId and callback
                syncEvents.push({
                    eventId, 
                    callback: (event) => setSyncEvent(event)
                })
            }
            addSubscribedEvents(syncEvents) 
        }

        return () => removeAllEventListeners()

    }, [customer_id, currentShowID, current_entry_id, sse]);

    // !4D -> entry_VerifyHorseSuspension
    const horseSuspensionStatus = () => {
        let horse_data = methods.getValues('horse.horse_data')
        let horse_status = ""
        //if(horse_data?.horse_id > 0){
            if(horse_data.usef_suspended && governingOrganization == 'USEF'){
                horse_status = intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.USEFSUSPENDED' })
            }
            else if(horse_data.microchip_number == "" && horse_data.foreigner == false){
                horse_status = intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.MISSINGMICROCHIP' })
            }
            else if(horse_data.ec_status == "SUSPENDED" && governingOrganization == 'EC'){
                horse_status = intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.ECSUSPENDED' })
            }
            else if(horse_data.fei_inactive_reason == "SUSPENDED" && horse_data.fei_id !='' && governingOrganization == 'FEI'){
                horse_status = intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.FEISUSPENDED' })
            }
            return horse_status
        //}
    }

    // !4D -> entry_RTO_SuspensionText
    const RTOSuspensionStatus = (rto_type, people_id, entry_status = false) => {
        let person_status = ""
        //if(people_id > 0){
            let person_data = methods.getValues('linked_people['+ people_id +'].people_data')
            if(person_data?.ec_standingsstatus == "Not In Good Standing" && governingOrganization == 'EC'){ //EC Suspended
                person_status = intl.formatMessage({ id: `FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.RTOSTATUS.${entry_status ? 'ECSUSPENDED' : 'ECNOTINGOODSTANDING'}` })
            }
            else if(person_data?.fei_isactive == false && governingOrganization == 'FEI'){ //UBN 04/08/2014 - Added case for FEI
                person_status = intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.RTOSTATUS.FEIISACTIVE' })
            }
            else if(person_data?.usef_suspension == true && governingOrganization == 'USEF'){ //UBN 03/13/2015 - Check USEF suspension
                person_status = intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.RTOSTATUS.USEFSUSPENDED' })
            }
            //UBN 11/12/2018 - Add safe sport training status for owner and trainer as well
            else if(person_data?.people_id > 0 && governingOrganization == 'USEF' && person_data?.require_safesport_training){
                person_status = intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.RTOSTATUS.SAFESPORTTRAININGEXPIRED' })
            }
            // !4D -> Method: rto_checkMedicalHold
            else if(rto_type == "RIDER" && person_data?.people_id > 0 && person_data?.on_medical_hold && !person_data?.isDeleted){ //Get Medical hold status for RTO
                person_status = intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.RTOSTATUS.ONMEDICALHOLD' })
            }
            // !4D -> Method: rto_checkCoachStatus
            //UBN 03/19/2014 - Show On Medical Hold status for rider
            else if(rto_type == "TRAINER" && governingOrganization == 'EC'){ // EC present and EC coach empty, means invalid trainer
                let isCoachSuspended = ischeckCoachStatusSuspended(people_id)
                if(isCoachSuspended){
                    person_status = intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.RTOSTATUS.ECNONACTIVECOACH' })
                }
            } 
        //}
        return person_status
    }

    // !4D -> entry_CheckForOnHold
    const entryCheckOnHold = (e) => {
        let entryStatuses = []
        let entry = methods.watch('entry')
        let linked_people = methods.getValues('linked_people')
        
        //if(current_entry_id > 0){
            if(e){
                if(e.target.checked){
                    entryStatuses.push(intl.formatMessage({ id:  'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.ENTRYSTATUS.VETHOLD' }))
                }
            }
            else if(entry.on_vet_hold){
                entryStatuses.push(intl.formatMessage({ id:  'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.ENTRYSTATUS.VETHOLD' }))
            }
            
            else if(linked_people[entry.responsibleparty_id]?.people_data.on_credit_hold){
                entryStatuses.push(intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.ENTRYSTATUS.RPCREDITHOLD' }))
            }
            else{
                for(let rider of entry.EntryRiders){
                    if(linked_people[rider.rider_id]?.people_data.on_medical_hold && !linked_people[rider.rider_id]?.people_data.isDeleted){
                        entryStatuses.push(intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.ENTRYSTATUS.MEDICALHOLD' }))
                    }
                }
            }

            if(entryStatuses.length == 0){
                let horse_status = ''
                let person_status = ''
    
                horse_status = horseSuspensionStatus()
                if(horse_status){
                    entryStatuses.push(intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.HORSE' }) +' '+ horse_status)
                }
    
                person_status = RTOSuspensionStatus("TRAINER", entry.trainer_id, true)
                if(person_status){
                    entryStatuses.push(intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.RTOTYPE.TRAINER' }) +' '+ person_status)
                }
    
                for(let rider of entry.EntryRiders){
                    person_status = RTOSuspensionStatus("RIDER", rider.rider_id, true)
                    if(person_status){
                        entryStatuses.push(intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.RTOTYPE.RIDER' }) +' ' + person_status)
                    }
                }
                person_status = RTOSuspensionStatus("OWNER", entry.entryowner_id, true)
                if(person_status){
                    entryStatuses.push(intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.GENERAL.LABEL.RTOTYPE.OWNER' }) +' '+ person_status)
                }
            }
    
            if(entryStatuses.includes('HOLD')){
                setEntryStatus(entryStatuses[0])
            }
            else{
                let entry_status = []
                for(let status of entryStatuses){
                    if(entry_status.length < 3){
                        entry_status.push(status)
                        entry_status.push(<br></br>)
                    }
                }
                setEntryStatus(entry_status)
            }
        //}
    }

    // starting RTO search/update/create related functions /////

    //Function to return next negative ID for new people records
    const generateNewRTOId = (linked_people) => {
        var RTOIds = Object.keys(linked_people)
        const nums = RTOIds.filter( x => !isNaN(x));
        let minValue = Math.min(...nums)

        // no new record is created yet
        if (minValue > 0){
            return -1
        }
        
        // already have new records created
        return (minValue - 1)
    }
    const generateNewEntryRiderId = (entry_riders) => {

        var entry_rider_ids = entry_riders?.length > 0? entry_riders.map(keys => keys.entry_rider_id): [0];
        let minValue = Math.min(...entry_rider_ids)

        // no new record is created yet
        if (minValue > 0){
            return -1
        }
        
        // already have new records created
        return (minValue - 1)
    }

    const setVetHoldStatus = (onVetHold, onVetHoldReason) => {
        methods.setValue('horse.horse_data.on_vet_hold', onVetHold, {shouldDirty: true})
        methods.setValue('entry.on_vet_hold', onVetHold, {shouldDirty: true})
        setOnVetHold(onVetHold)
        let holdReason = `${methods.getValues('horse.horse_data.name')} put on hold from entries area: ${onVetHoldReason}`

        methods.setValue('horse.horse_data.on_vet_hold_reason', holdReason, {shouldDirty: true})
        entryCheckOnHold()

    }

    async function getSelectedPeople(people, organization, person_role, number_lookup = false){

        const result = await axios.post( process.env.REACT_APP_NEST_API_URL + '/entries/selectedPeople', {
            params: {
                people: people,
                customer_id: customer_id,
                show_id: methods.getValues('entry.show_id')?methods.getValues('entry.show_id'):currentShowID,
                organization: organization,
                person_role: person_role,
                number_lookup: number_lookup,
                entry: methods.getValues('entry')
            }
        }) 
        if(result.data.success){
            if(result.data.show_alert){
                let choice = await checkUpdatePeopleWarning(result.data.people, result.data.alert_message, organization, result.data.search_status, person_role)
                if(choice){
                    return result.data.search_status
                }
            } else {
                // setPersonSearchStatus(result.data.search_status)
                updatePeople(result.data.people, organization, person_role)  
                return result.data.search_status                  
            }
        }
    }

    const checkUpdatePeopleWarning = async (people, alert_message, organization, search_status, person_role) => {
        const choice = await confirmDialog({message: alert_message})
        if(choice){ 
            // setPersonSearchStatus(search_status)
            updatePeople(people, organization, person_role)
        }  
        return choice;
    }

    function updatePeople(people, organization, person_role){
        if(!people.people_data.people_id || people.people_data.people_id == 0){
            people.people_data.people_id = generateNewRTOId(methods.getValues('linked_people'))
        }

        // update the person record only if it doesn't already exists >>>
        const linkedPeopleObject = methods.getValues('linked_people');
        if (!(people.people_data.people_id in linkedPeopleObject)) {
            methods.setValue('linked_people['+ people.people_data.people_id +'].people_data', people.people_data, {shouldDirty: true})
            methods.setValue('linked_people['+ people.people_data.people_id +'].people_data.isUpdated', true)
            methods.setValue('linked_people['+ people.people_data.people_id +'].membership_data', people.memberships_data?people.memberships_data:[])
        }
        // update the person record only if it doesn't exists <<< 

        if(person_role == 'RIDER'){
            if(methods.getValues('max_rider_limit') == 1){
                updateRider(people, organization, person_role)
            } else {
                addRider(people, organization, person_role)
            }
        } else {
            if(person_role == 'OWNER'){
                methods.setValue('linked_people['+methods.getValues('entry.entryowner_id') +'].people_data.isUpdated', false)
                methods.setValue('entry.entryowner_id', people.people_data.people_id, {shouldDirty:true})
                methods.setValue('entry.owner', methods.getValues('linked_people['+ people.people_data.people_id +'].people_data.fl_name'), {shouldDirty:true})
            }
            else if(person_role == 'TRAINER'){
                methods.setValue('linked_people['+methods.getValues('entry.trainer_id') +'].people_data.isUpdated', false)
                methods.setValue('entry.trainer_id', people.people_data.people_id, {shouldDirty:true})
                methods.setValue('entry.trainer', methods.getValues('linked_people['+ people.people_data.people_id +'].people_data.fl_name'), {shouldDirty: true})
            }
            else if(person_role == 'RECIPIENT'){
                methods.setValue('linked_people['+methods.getValues('entry.prizemoneyrecipient_id') +'].people_data.isUpdated', false)
                methods.setValue('entry.prizemoneyrecipient', methods.getValues('linked_people['+ people.people_data.people_id +'].people_data.fl_name'), {shouldDirty: true})
                methods.setValue('entry.prizemoneyrecipient_id', people.people_data.people_id, {shouldDirty:true})
            }
            else if(person_role == 'PERSON'){
                methods.setValue('linked_people['+methods.getValues('entry.responsibleparty_id') +'].people_data.isUpdated', false)
                methods.setValue('entry.responsibleparty', methods.getValues('linked_people['+ people.people_data.people_id +'].people_data.fl_name'), {shouldDirty: true})
                methods.setValue('entry.responsibleparty_id', people.people_data.people_id, {shouldDirty:true})
            }
            getSelectedPeopleData(people, organization, person_role)
        }
    }

    async function updateRider(people, organization, person_role){
        let entry_riders = methods.getValues('entry.EntryRiders');
        if(entry_riders.length == 1){
            let old_rider_id = entry_riders[0]['rider_id']
            let trips = methods.getValues('trips')
            let riderTrips = trips.filter(trip => trip.rider_id == old_rider_id)
            if(riderTrips.length > 0){
                let choice = await confirmDialog({message: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.CLASSES.CHANGERIDER.CONFIRM' }), icon: 'Error'})
                if(choice){
                    entry_riders[0]['rider_name'] = people.people_data.fl_name
                    entry_riders[0]['rider_id'] = people.people_data.people_id
                    entry_riders[0]['isUpdated'] = true
                    entry_riders[0]['isDeleted'] = false
                    entry_riders[0]['coach_id'] = people.people_data.coach_id
                    entry_riders[0]['user_status'] = ''
                    entry_riders[0]['status_override'] = 0
                    entry_riders[0]['status'] = ''
                    for(let trip of trips){
                        if(trip.rider_id == old_rider_id){
                            trip.rider_id = people.people_data.people_id
                            trip.rider_name = people.people_data.fl_name
                            trip.isUpdated = true
                        }
                    }
                    let rider_status = await getRiderStatus(people, entry_riders[0])
                    if(rider_status && rider_status != ''){
                        entry_riders[0]['status'] = rider_status
                    }
                    methods.setValue('entry.EntryRiders', entry_riders, {shouldDirty: true})
                    methods.setValue('trips', trips, {shouldDirty: true})
                    getSelectedPeopleData(people, organization, person_role)

                }
            } else {
                entry_riders[0]['rider_name'] = people.people_data.fl_name
                entry_riders[0]['rider_id'] = people.people_data.people_id
                entry_riders[0]['isUpdated'] = true
                entry_riders[0]['isDeleted'] = false
                entry_riders[0]['coach_id'] = people.people_data.coach_id
                entry_riders[0]['user_status'] = ''
                entry_riders[0]['status_override'] = 0
                entry_riders[0]['status'] = ''
                let rider_status = await getRiderStatus(people, entry_riders[0])
                if(rider_status && rider_status != ''){
                    entry_riders[0]['status'] = rider_status
                }
                
                methods.setValue('entry.EntryRiders', entry_riders, {shouldDirty: true})
                getSelectedPeopleData(people, organization, person_role)
            }
        } else {
            addRider(people, organization, person_role)
        }
    }

    async function addRider(people, organization, person_role){
        let entry_riders = methods.getValues('entry.EntryRiders')? methods.getValues('entry.EntryRiders'): [];
        let riders = entry_riders?.filter(rider => rider.rider_id == people.people_data.people_id)
        if(!riders || riders?.length == 0){
            let rider = {
                entry_rider_id: generateNewEntryRiderId(entry_riders),
                entry_id: methods.getValues('entry.entry_id'),
                rider_id: people.people_data.people_id,
                rider_name: people.people_data.fl_name,
                rider_age: people.people_data.age,
                rider_status: people.people_data.rider_status,
                coach_id: people.people_data.coach_id,
                user_status: "",
                status_override: 0,
                status: "",
                isUpdated: true
            }
            let rider_status = await getRiderStatus(people, rider)
            if(rider_status && rider_status != ''){
                rider.status = rider_status
            }
            
            entry_riders.push(rider)
            methods.setValue('entry.EntryRiders', entry_riders, {shouldDirty: true});
        } else {
            riders = riders?.filter(rider => rider.isDeleted)
            if(riders || riders?.length > 0){
                let index = entry_riders?.findIndex(rider => rider.rider_id == people.people_data.people_id)
                entry_riders[index]['isDeleted'] = false;
            }


        }
        getSelectedPeopleData(people, organization, person_role)
    }

    async function getRiderStatus(people, entry_rider){
        const result = await axios.post( process.env.REACT_APP_NEST_API_URL + '/entries/getRiderStatus', {
            params: {
                people: people.people_data,
                customer_id: customer_id,
                show_id: methods.getValues('entry.show_id')?methods.getValues('entry.show_id'):currentShowID,
                entry: methods.getValues('entry'),
                // organization: organization,
                // person_role: person_role,
                horse:  methods.getValues('horse.horse_data'),
                entry_rider: entry_rider
            }
        }) 
        if(result.data.success){
            return result.data.rider_status?result.data.rider_status:'' 
        } else {
            return ''
                
            
        }

    }

    function getSelectedPeopleData(people, organization, person_role){
        axios.post( process.env.REACT_APP_NEST_API_URL + '/entries/selectedPeopleData', {
            params: {
                people: people.people_data,
                customer_id: customer_id,
                show_id: methods.getValues('entry.show_id')?methods.getValues('entry.show_id'):currentShowID,
                entry: methods.getValues('entry'),
                organization: organization,
                person_role: person_role,
                horse:  methods.getValues('horse.horse_data')
            }
        }) 
        .then(result => { 
            if(result.data.success){
                if(result.data.stable){
                    methods.setValue('stable', result.data.stable, {shouldDirty:true})
                    methods.setValue('existing_stable', result.data.stable, {shouldDirty: true})
                }
                methods.setValue('entryWithholdingTaxApplicable', result.data.entryWithholdingTaxApplicable)
                methods.setValue('entry', result.data.entry, {shouldDirty:true})
                // update the person record only if it doesn't already exists >>>
                const linkedPeopleObject = methods.getValues('linked_people');
                if (!(result.data.people_data.people_id in linkedPeopleObject)) {
                    methods.setValue('linked_people['+result.data.people_data.people_id+'].people_data', result.data.people_data)
                }
                // update the person record only if it doesn't exists <<< 
                entryCheckOnHold()
            }
        })
    }

    async function callSearchPersonApi(search_term, person_role) {
        try {
            const result = await axios.get(process.env.REACT_APP_NEST_API_URL + '/entries/searchPeople', {
                params: {
                    people_name: search_term,
                    customer_id: customer_id,
                    person_role: person_role,
                    show_id: methods.getValues('entry.show_id') ? methods.getValues('entry.show_id') : currentShowID,
                }
            });
            
            if (result.data.success) {
                if (result.data.update_people) {
                    // Update people directly
                    let person = checkAlreadyLookupData(result.data.people, result.data.default_org);
                    await getSelectedPeople(person, result.data.default_org, person_role, true);
                } else {
                    // Open search dialog
                    let local_people = searchLocallyCreatedPerson(getFirstNameFromSearchString(search_term), getLastNameFromSearchString(search_term), '', '');
                    let search_list = result.data.people_list.concat(local_people);
    
                    if (!result.data.dialog_data['usef_no']) {
                        result.data.dialog_data['usef_no'] = '';
                    }
    
                    return {
                        search_list: search_list,
                        dialog_data: result.data.dialog_data,
                        people_count_status: result.data.people_count_status,
                        default_org: result.data.default_org,
                        showPersonLookup: true,
                    };
                }
    
                return {
                    showPersonLookup: false,
                    search_status: result.data.search_status,
                };
            } else {
                return {
                    showPersonLookup: false,
                };
            }
        } catch (error) {
            alertDialog({message: 'No results found. Please try a different search term.'})
    
            // Return a response that ensures the loading state is stopped
            return {
                showPersonLookup: false,
                error: true, 
                errorMessage: error.response?.data?.message || 'An error occurred while searching for people.',
            };
        }
    }
    
    function checkAlreadyLookupData(person, default_org){
        let created_people = []
        let linked_people = methods.getValues('linked_people')
        for (const queryKey of Object.keys(linked_people)) {
            if(parseInt(queryKey) < 0){
                created_people.push(linked_people[queryKey].people_data)
            }
        }
        if(created_people.length > 0){
            let created_person;
            if(default_org == 'USEF'){
                created_person = created_people.find(people => (people.usef_number_ahsa_number == person.people_data.usef_number_ahsa_number ))
            } else if(default_org == 'FEI'){
                created_person = created_people.find(people => (people.fei_id == person.people_data.fei_id ))
            } else if(default_org == 'EC'){
                created_person = created_people.find(people => (people.ec_sln == person.people_data.ec_sln ))
            }
            if(created_person){
                return linked_people[created_person.people_id]
            }
        }
        return person;
    }

    function searchLocallyCreatedPerson(first_name, last_name, city, state){
        let created_people = []
        let linked_people = methods.getValues('linked_people')
        for (const queryKey of Object.keys(linked_people)) {
            if(parseInt(queryKey) < 0){
                created_people.push(linked_people[queryKey].people_data)
            }
        }
        let filtered_people = created_people?.filter(people => ((first_name == '' || people?.first?.includes(first_name)) && (last_name == '' || people?.last_name?.includes(last_name)) && (city == '' || people?.city == city) && (state == '' || people?.state == state) ))
        return filtered_people
    }

    //! 4D -> bf_FirstName
    const getFirstNameFromSearchString = (search_text) => {
        let index = search_text.indexOf(",")
        if (index > 0){
                return search_text.substring(index+1).trim()
        } else { 
            index = search_text.indexOf(" ")

            if (index > 0){
                return search_text.substring(0, index).trim()

            } else { 
                return""
            }
        }
    }

    //! 4D -> bf_LastName
    const getLastNameFromSearchString = (search_text) => {
        let index = search_text.indexOf(",")
        if (index > 0){
            return search_text.substring(0, index).trim()
        } else { 
            index = search_text.indexOf(" ")

            if (index > 0){
                return search_text.substring(index+1).trim()

            } else { 
                return search_text
            }
        }
    }

    const validateEntryNumber = async () => {
        let starting_webentry_number = methods.watch('show.starting_webentry_number') ? methods.watch('show.starting_webentry_number') : 10000;
        let ending_web_entry_number = methods.watch('show.ending_web_entry_number') ? methods.watch('show.ending_web_entry_number') : 15000;

        if(entry_number != methods.getValues('entry.number')){


        await axios.post( process.env.REACT_APP_NEST_API_URL + '/entries/validateEntryNumber', {
            params: {
                number: methods.getValues('entry.number'),
                entry_id: entry_id,
                customer_id: customer_id,     
                entry: methods.getValues('entry')   
            }
        }).then(async (response) => {
            let new_entry_number = methods.getValues('entry.number')
            if(!response.data.success) {
                methods.setValue('entry.number', entry_number)
                new_entry_number = entry_number
                alertDialog({ message: response.data.entryNumberError })
            }
            else if(response?.data?.seriesEntryNumber?.entries?.length > 0){
                // If entry was in series, show was not last show of series & all validations have passed... 
                setSeriesEntryNumber(response.data.seriesEntryNumber)
                setShowUpdateEntryNumberForm(true)
                setEntryNumber(methods.getValues('entry.number'))
            }
            if (new_entry_number >= starting_webentry_number && new_entry_number <= ending_web_entry_number){
                setLockEntry(false)
            } else {
                setLockEntry(true)
            }
        })
        } else {
            if (entry_number >= starting_webentry_number && entry_number <= ending_web_entry_number){
                setLockEntry(false)
            } else {
                setLockEntry(true)
            }
        }
        
    }

    useEffect(() => {
        if (printInvoice) {
            printInvoiceReport();
        }
    }, [printInvoice])

    const printInvoiceHandler = async () => {
        // Save the opened entry before printing report
        await getMembershipsUpdatedRecords()
        await onSubmitData(methods.getValues(), 'print-invoice')
    }

    // Print invoice report, this wil get pref value for invoices and then it will give report definition which will be used for printing
    const printInvoiceReport = async () => {
        // Print report if there are no errors
        if (!printInvoice) {    // safety check
            return false;
        }

        loadingOverlay({ show: true, message: `Loading Report...` })
        const record_id = methods.getValues('entry.entry_id');

        if (record_id == 0) {   //  safety check
            loadingOverlay({ show: false })
            return false;
        }

        await axios.post(process.env.REACT_APP_NEST_API_URL + '/reports/getPrefReportForPrinting', {
            pref_name: 'Default_Invoice',
            customer_id: customer_id,
            show_id: currentShowID, 
            params: {ids: [record_id]}
        })
            .then(async (response) => {
                if (response?.data?.success) {
                    const report = response?.data?.report;
                    report.report_definition = JSON.parse(report?.report_definition);

                    // Load report to ARJS and the print
                    await loadReportsAndPrint(report);

                    // Refetch the entry data because prize money might have auto applied while printing report
                    await reloadPageData(record_id);
                    setPrintInvoice(false);
                }
                loadingOverlay({show: false})
            })
            .catch((error) => {
                loadingOverlay({show: false})
            })
    }

    const checkLocationDisable = () => {
        let enterable = false;
        if(watchTrainerAccount) {
            enterable = true;
        } else if (methods.watch('entry.horse_id') != 0 && methods.watch('entry.horse_id') != null ){
            enterable = true;
        }
        return !enterable
    }

    // 4D -> rto_checkCoachStatus
    // Description: check the suspension status for coach/ trainer
    // Coach is valid when:
    // 1- Show EC rating is Platinum or Gold and person EC membership_level is either Licensed Coach or it contains active
    // 2- Show EC rating is Bronze or Silver and person EC membership_level is either Licensed Coach, Registered Coach or it contains active
    const ischeckCoachStatusSuspended = (people_id) => {
        let ec_rating = methods.getValues('show.ec_rating')
        let person_memberships = methods.getValues('linked_people['+ people_id +']')?.membership_data
        let ec_coach_membership = person_memberships?.find((membership) => membership.organization_code == 'EC-Coach')
        if(ec_rating){
            if(ec_coach_membership?.membership_override){
                return false
            }else{
                let membership_number = ec_coach_membership?.membership_number
                if(membership_number){
                    if(ec_rating === 'Platinum' || ec_rating === 'Gold'){
                        if(membership_number === "Licensed Coach" || membership_number.includes('Active') || membership_number.includes('active')){
                            return false
                        }
                    }else if(ec_rating === 'Bronze' || ec_rating === 'Silver'){
                        if(membership_number === "Licensed Coach" || membership_number === "Registered Coach" || membership_number.includes('Active') || membership_number.includes('active')){
                            return false
                        }
                    }
                }
            }
        }else{
            return false
        }
        return true
    }

    useEffect(() => {
        if(!showTaxInformation){
            // when taxform dialog is closed clear out these state variables
            setPersonIdForTaxStatusLookup(0)
            setSelectedTaxFormRequestType('')
        }
    }, [showTaxInformation])

    // update the balance iformation for PMR and RP
    useEffect(() => {
        // webservice called in setTimeout to avoid webservice call on each keystroke for ssn and federal id
        const time_out = setTimeout(function(){
            if(methods.watch('entry.responsibleparty_id')){
                axios.post(`${process.env.REACT_APP_NEST_API_URL}/accounting/getTransferableAmountForEntry`, {
                    customer_id: customer_id,
                    entry_id: methods.getValues('entry.entry_id'),
                    entry: methods.getValues('entry'),
                    pmr: methods.watch(`linked_people[${methods.watch('entry.prizemoneyrecipient_id')}].people_data`),
                    rp: methods.watch(`linked_people[${methods.watch('entry.responsibleparty_id')}].people_data`)
                }).then((response) => {
                    setBalanceInfoForRpAndPMR(response?.data ?? null)
                    let dropInputRef = document.getElementById('ENTERDROPS');
                    if (dropInputRef && methods.getValues('dropInputRef')) {
                        setTimeout(() => {
                            dropInputRef.focus();
                        }, 400);
                    }
                })
            }else{
                setBalanceInfoForRpAndPMR(null)
            }
        }
        , 1000);
        return () => clearTimeout(time_out);
    }, [
        methods.watch('entry.responsibleparty_id'), 
        methods.watch(`linked_people[${methods.watch('entry.responsibleparty_id')}].people_data.social_security_number`),
        methods.watch(`linked_people[${methods.watch('entry.responsibleparty_id')}].people_data.federal_id`),
        methods.watch(`linked_people[${methods.watch('entry.responsibleparty_id')}].people_data.on_credit_hold`),
        methods.watch('entry.balance')
    ])

    useEffect(() => {
        if(methods.watch('entry.responsibleparty_id') && methods.watch('entry.responsibleparty_id') > 0){
            // get the past balances and entries with past balances for RP
            axios.post(`${process.env.REACT_APP_NEST_API_URL}/entries/getPastBalancesForEntry`, {
                entry_id: methods.getValues('entry.entry_id'),
                customer_id: customer_id,
                entry: methods.getValues('entry'),
                show_start_date: currentShowStartDate
            }).then((response) => {
                if(response.data.success){
                    setPastBalances({
                        rpPastBalance: response.data.rpPendingBalance,
                        rpPastBalanceEntries: response.data.rpPendingBalanceEntries
                    })
                }else{
                    setPastBalances(null)
                }
            })
        }else{
            setPastBalances(null)
        }
        
    }, [
        methods.watch('entry.responsibleparty_id'),
        methods.watch('entry.horse_id'),
        methods.watch('paymentxEntries') // Update the past balance when balance is roll forwarded to current entry
    ])

    //Clear membership dates if membership not found from look up
    function clearMembership(personId, org, clearMembershipNumber=false){
        let membership_data = methods.getValues("linked_people")
        let index = membership_data[personId].membership_data.findIndex(membership => membership.organization_code == org)
        membership_data[personId].membership_data[index].valid_start = '0000-00-00'
        membership_data[personId].membership_data[index].valid_end = '0000-00-00'
        membership_data[personId].membership_data[index].valid = false
        membership_data[personId].membership_data[index].is_membershipvalid = false
        membership_data[personId].membership_data[index].name_mismatch_accepted = false
        // membership_data[props.person_id].membership_data[index].membership_name = ''
        membership_data[personId].membership_data[index].isUpdated = true
        membership_data[personId].people_data.isUpdated = true
        if(clearMembershipNumber){
            // Use when tax status lookup return no data
            membership_data[personId].membership_data[index].membership_number = ''
            membership_data[personId].membership_data[index].membership_exist = false
            membership_data[personId].membership_data[index].membership_level = ''
        }
        if(membership_data[personId].membership_data[index].organization_code == 'EC'){
            methods.setValue(`linked_people[${personId}].people_data.ec_sport_license_type`, '', { shouldDirty: true })
        }
        methods.setValue("linked_people", membership_data)
    }

    function updatePersonRecordAfterTaxStatusLookup(person_id, personData, taxStatusMembership){
        let rtoRecords = methods.getValues("linked_people")

        // update people record (e.g address etc) with lookup data
        if(personData){
            rtoRecords[person_id].people_data = personData
            rtoRecords[person_id].people_data['isUpdated'] = true
        }
        
        if(taxStatusMembership){
            // update person Tax Status membership
            let membershipIndex = rtoRecords[person_id]?.membership_data.findIndex(membership => membership?.organization_code == 'Tax Status')
            rtoRecords[person_id].membership_data[membershipIndex] = taxStatusMembership
            rtoRecords[person_id].membership_data[membershipIndex]['isUpdated'] = true
        }
        methods.setValue("linked_people", rtoRecords)
    }

    const handleTinStatusCheck = async (personId=0) => {
        if(!personId){
            personId = personIdForTaxStatusLookup
        }

        if(!methods.getValues('linked_people['+ personId +']')){
            return 
        }

        loadingOverlay({ show: true })
        let response = await axios.post(`${process.env.REACT_APP_NEST_API_URL}/people/tinlookupandupdateperson`, {
            people_id: personId,
            customer_id: customer_id,
            people: methods.getValues('linked_people['+ personId +'].people_data')
        })

        loadingOverlay({ show: false })
        if (response.data.success) {
            let updatedPersonData = response?.data?.updated_people_data
            if(updatedPersonData){
                await updatePersonRecordAfterTaxStatusLookup(personId, updatedPersonData, null)
            }
            // updateCallbackFromParent();
        } else {
            alertDialog({ message: response.data.message})
        }
    }
    
    const tabs = ["GENERAL", "CLASSES", "CLASSFEES", "ACCOUNT", "NOTES", "CHECKLIST", "HISTORY", "AUDITTRAIL"]
    const componentTags = [
        <GeneralTab 
            generateNewRTOId={generateNewRTOId} 
            getSelectedPeople={getSelectedPeople} 
            updatePeople={updatePeople}
            callSearchPersonApi={callSearchPersonApi}
            getFirstNameFromSearchString={getFirstNameFromSearchString}
            getLastNameFromSearchString={getLastNameFromSearchString}
            form_meta={form_meta} 
            entryCheckOnHold={entryCheckOnHold} 
            RTOSuspensionStatus={RTOSuspensionStatus} 
            horseSuspensionStatus={horseSuspensionStatus} 
            callbackFromParent={getDetailPageInfo}
            updateCallbackFromParent={updateData}
            entryId={current_entry_id}
            callFormSubmit={() => callFormSubmit('digital-signature')}
            showTaxInformation={showTaxInformation}
            setShowTaxInformation={setShowTaxInformation}
            personIdForTaxStatusLookup={personIdForTaxStatusLookup} 
            setPersonIdForTaxStatusLookup={setPersonIdForTaxStatusLookup}
            selectedTaxFormRequestType={selectedTaxFormRequestType}
            clearMembership={clearMembership}
            getRiderStatus={getRiderStatus}
        />, 
        <ClassesTab 
            form_meta={form_meta} 
            RTOSuspensionStatus={RTOSuspensionStatus}
            generateNewRTOId={generateNewRTOId} 
            getSelectedPeople={getSelectedPeople} 
            updatePeople={updatePeople}
            entryCheckOnHold={entryCheckOnHold} 
            callSearchPersonApi={callSearchPersonApi}
            getFirstNameFromSearchString={getFirstNameFromSearchString}
            getLastNameFromSearchString={getLastNameFromSearchString}
            callbackFromParent={getDetailPageInfo}
            entryId={current_entry_id}
            callFormSubmit={() => callFormSubmit('digital-signature')}
            lockPrizeMoneyAction={lockPrizeMoneyAction} 
            setLockPrizeMoneyAction={setLockPrizeMoneyAction}
            showTaxInformation={showTaxInformation}
            setShowTaxInformation={setShowTaxInformation}
            personIdForTaxStatusLookup={personIdForTaxStatusLookup} 
            setPersonIdForTaxStatusLookup={setPersonIdForTaxStatusLookup}
            selectedTaxFormRequestType={selectedTaxFormRequestType} 
            setSelectedTaxFormRequestType={setSelectedTaxFormRequestType}
            clearMembership={clearMembership}
            handleTinStatusCheck={handleTinStatusCheck}
            getRiderStatus={getRiderStatus}
        />, 
        <ClassFeesTab />, 
        <AccountTab 
            generateNewRTOId={generateNewRTOId} 
            getSelectedPeople={getSelectedPeople} 
            updatePeople={updatePeople}
            callSearchPersonApi={callSearchPersonApi}
            getFirstNameFromSearchString={getFirstNameFromSearchString}
            getLastNameFromSearchString={getLastNameFromSearchString}
            formMeta={form_meta} 
            getMembershipsUpdatedRecords={getMembershipsUpdatedRecords}
            onSubmitData={onSubmitData}
            getSubmitData={getSubmitData}
            callbackFromParent={getDetailPageInfo}
            checkMissingEntryStall={checkMissingEntryStall}
            callFormSubmit={callFormSubmit}
            updateCallbackFromParent={updateData}
            setLockPrizeMoneyAction={setLockPrizeMoneyAction}
            id={props?.id}
            reloadPageData={reloadPageData}
        />, 
        <NotesTab />,
        <ChecklistTab />,
        <EntriesHistoryTab 
            rowData={methods.getValues('change_history_logs')}
        />,
        <AuditTrailTab rowData={methods.getValues('entry_ledger')} />
    ]
    const [selectedTab, setSelectedTab] = useState(() => {
		const hash = window.location.hash.substring(1);
		return tabs.indexOf(hash) >= 0 && props?.id == undefined ? tabs.indexOf(hash) : 0;
	});
    
    return (
        <>
           {props?.id == undefined ? <PageTitle breadcrumbs={[]} description={[methods.getValues('entry.number'),methods.watch('horse.horse_data.name'), watch('entry.owner'), watch('entry.trainer')].filter((value) => value)} usePipeSeparator={true}>{intl.formatMessage({ id: 'MENU.ENTRIES' })}</PageTitle> : ""}
            <div id={"entries_detail_page"} className='modal-body py-3 px-4 horse-input-form input-form'>
                <FormProvider {...methods}>
                    { showAddToSeriesForm && 
                        <AddToSeriesForm show={showAddToSeriesForm} handleClose={() => setShowAddToSeriesForm(false)} />
                    }      
                {showPutOnHoldForm ? 
                    <GeneralPutOnHoldForm show={showPutOnHoldForm} handleClose={() => setShowPutOnHoldForm(false)} handleSubmit={(onVetHold, onVetHoldReason) => setVetHoldStatus(onVetHold, onVetHoldReason)} onVetHold={'horse.horse_data.on_vet_hold'} onVetHoldReason={'horse.horse_data.on_vet_hold_reason'} />
                :null}

                {showTaxInformation ? 
                    <TaxFormInformationModal 
                    show={showTaxInformation}
                    handleClose={() => setShowTaxInformation(false)}
                    updateCallbackFromParent={updateData}
                    personData={methods.getValues('linked_people['+ personIdForTaxStatusLookup +'].people_data')}
                    organizationMembership={methods.getValues('linked_people['+ personIdForTaxStatusLookup +'].membership_data')}
                    updatePersonRecordAfterTaxStatusLookup={updatePersonRecordAfterTaxStatusLookup}
                    clearMembership={clearMembership}
                    calledFrom='Entries'
                    selectedTaxFormRequestType={selectedTaxFormRequestType}
                    handleTinStatusCheck={() => handleTinStatusCheck()}
                /> : null}

                    {   
                        showUpdateEntryNumberForm && 
                        <UpdateEntryNumberForm 
                            show={showUpdateEntryNumberForm} 
                            handleClose={() => setShowUpdateEntryNumberForm(false)} 
                            seriesEntryNumber={seriesEntryNumber} 
                        />
                    }
                    {
                        showUpdateEntryRolesForm &&
                        <UpdateEntryRolesForm
                            show={showUpdateEntryRolesForm} 
                            handleClose={() => setShowUpdateEntryRolesForm(false)} 
                            seriesRolesPropsRef={seriesRolesPropsRef.current}
                        />
                    }
                {
                    displayPastEntries &&
                    <EntriesWithPastBalance
                        show={displayPastEntries}
                        handleClose={() => setDisplayPastEntries(false)}
                        data={pastBalances}
                    />
                }
                <form id={"EntryDetail_"+current_entry_id} noValidate className='form max-width' onSubmit={(e) => {e.preventDefault();handleSubmit(onSubmit)(getMembershipsUpdatedRecords())}} onKeyDown={(e) => { if (e.key === 'Enter' && e.target.tagName === 'INPUT') { e.preventDefault() } } }>
                    <HelpLink tooltip="ENTRIES.DETAIL.LINK.HELP" classname="top" />
                    <div className='card-body p-0'> {/* Card Body */}
                        <div className="form-group row min-h-850px"> {/* Column Group */}
                            <div className='col-lg-10'> {/* Main Layout */}
                                <div className='row mb-2'>
                                    <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-lg-60px' htmlFor='ENTRYNUMBER' data-tooltip-id="ENTRIES.DETAIL.LABEL.ENTRY">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.LABEL.ENTRY' })}</label>
                                    <div className='col-lg-1 px-0'>
                                        <Controller
                                            control={methods.control}
                                            name="entry.number"
                                            render={({ field: { onChange, name, value } }) => (
                                                <NumericFormat
                                                    id='ENTRYNUMBER'
                                                    type='text'
                                                    className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                                    placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.LABEL.ENTRY' })}
                                                    disabled={lockEntry}
                                                    onBlur={validateEntryNumber}
                                                    name={name}
                                                    allowNegative={false}
                                                    decimalScale={0}
                                                    value={Number(value)}
                                                    onValueChange={(e)=>{
                                                        onChange(e.value)
                                                    }}
                                                    onFocus={(e) => {
                                                        e.target.select()
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className='col-lg-1 px-0 w-lg-40px'>
                                        <i onClick={() => setLockEntry(!lockEntry)} className={"fas fa-solid ms-2 py-3" + (lockEntry ? ' fa-lock' : ' fa-lock-open')}></i>
                                    </div>
                                    { current_entry_id > 0 ?
                                        <div className='col-lg-1 w-lg-150px'>
                                            <button 
                                                type='button' 
                                                onClick={goToAddToSeries}
                                                className="btn btn-sm btn-secondary fw-bold px-2 py-2 col-12 text-uppercase"
                                                disabled={on_vet_hold} // disable if entry is on vet hold or new entry
                                                data-tooltip-id="ENTRIES.DETAIL.BUTTON.ADDTOSERIES"
                                            >
                                                {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.BUTTON.ADDTOSERIES' })}
                                            </button>
                                        </div> : ""
                                    }
                                    <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-lg-80px' htmlFor='location' data-tooltip-id="ENTRIES.DETAIL.LABEL.LOCATION">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.LABEL.LOCATION' })}</label>
                                    <div className='col-lg-2 ps-0 min-w-lg-175px'>
                                        <input
                                            {...register("entry.location", 
                                            {
                                                required: false
                                            })
                                            }
                                            id='location'
                                            type='text'
                                            disabled={checkLocationDisable()}
                                            className='form-control form-control-sm  fs-6 min-h-20px py-1'
                                            placeholder={intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.LABEL.LOCATION' })}
                                        />
                                    </div>
                                    <div className='col'>
                                        <div className='row justify-content-end'>
                                            <div className='col-lg-1 form-check-sm form-check-custom' style={{ width: '160px' }}>
                                                <input
                                                    {...register("entry.picked_up", 
                                                    {
                                                        required: false
                                                    })
                                                    }
                                                    className='form-check-input'
                                                    type='checkbox'
                                                    id='picked_up'
                                                />
                                                <label className='col-form-label mx-2 fs-5 fw-bold py-1' htmlFor='picked_up' data-tooltip-id="ENTRIES.DETAIL.LABEL.ENTRYPICKEDUP"> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.LABEL.ENTRYPICKEDUP' })}</label>
                                            </div>
                                            <div className='col-lg-1 form-check-sm form-check-custom' style={{ width: '160px' }}>
                                                <input
                                                    {...register("entry.trainer_account", 
                                                    {
                                                        required: false
                                                    })
                                                    }
                                                    className='form-check-input'
                                                    type='checkbox'
                                                    id='trainer_account'
                                                    onChange={onTrainerAccountChange}
                                                    disabled={watch("entry.scratched")}
                                                />
                                                <label className='col-form-label mx-2 fs-5 fw-bold py-1' htmlFor='trainer_account' data-tooltip-id="ENTRIES.DETAIL.LABEL.TRAINERACCOUNT"> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.LABEL.TRAINERACCOUNT' })}</label>
                                            </div>
                                        </div>
                                        <div className='row justify-content-end'>
                                            <div className='col-lg-1 form-check-sm form-check-custom' style={{ width: '160px' }}>
                                                <input
                                                    {...register("entry.exclude_from_trainer_split", 
                                                    {
                                                        required: false
                                                    })
                                                    }
                                                    className='form-check-input'
                                                    type='checkbox'
                                                    id='exclude_from_trainer_split'
                                                    disabled={watchTrainerAccount}
                                                />
                                                <label className='col-form-label mx-2 fs-5 fw-bold py-1' htmlFor='exclude_from_trainer_split' data-tooltip-id="ENTRIES.DETAIL.LABEL.EXCLUDEFROMSPLIT"> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.LABEL.EXCLUDEFROMSPLIT' })}</label>
                                            </div>
                                            <div className='col-lg-1 form-check-sm form-check-custom' style={{ width: '160px' }}>
                                                <input
                                                    checked={on_vet_hold}
                                                    className='form-check-input'
                                                    type='checkbox'
                                                    id='on_vet_hold'
                                                    onChange={(e) => {displayOnVetHoldDialog(e);entryCheckOnHold()}}
                                                    disabled={watchTrainerAccount}
                                                />
                                                <label className='col-form-label mx-2 fs-5 fw-bold py-1' htmlFor='on_vet_hold' data-tooltip-id="ENTRIES.DETAIL.LABEL.ONVETHOLD"> {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.LABEL.ONVETHOLD' })}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='d-flex flex-column my-2 border p-2 bg-white'>
                                    <ul tabIndex="-1" className='nav nav-tabs nav-pills fs-6 fw-bolder flex-nowrap' id="myTab" role="tablist" style={{ overflowX: "auto", overflowY: "hidden" }}>
                                        {
                                            tabs.map((tab, index) => {
                                                return (
                                                    <li key={index} className='nav-item' onClick={() => { setSelectedTab(index); if(props?.id == undefined){window.location.hash = tab} }}>
                                                        <a className={"nav-link text-active-dark px-7 py-1 text-gray-700" + (index == selectedTab ? ' active' : '')}
                                                            tabIndex="-1"
                                                            id={tab + "-tab"}
                                                            data-bs-toggle='tab'
                                                            data-tooltip-id={`ENTRIES.DETAIL.TAB.${tab}`}
                                                            href={"#" + tab + "-Entry-" +current_entry_id}>{index == 4 ? `${intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.' + tab })} ${methods.getValues('notes')?.length > 0 ? `(${methods.getValues('notes')?.length})` : ''}`: intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.TAB.' + tab })}
                                                        </a>
                                                    </li>
                                                );
                                            })
                                        }
                                    </ul>

                                    <div className="tab-content mt-5 pb-2" id="myTabContent">
                                        {
                                            tabs.map((tab, index) => {
                                                return (
                                                    <div key={index} className={"tab-pane readjust-tab-padding fade show" + (index == selectedTab ? " active" : "")} id={tab + "-Entry-" +current_entry_id} role="tabpanel" >
                                                        <HelpLink tooltip={"ENTRIES.DETAIL.TAB."+tab+".LINK.HELP"}  />
                                                        {componentTags[index]}
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            </div> {/* End Main Layout */}
                            <div className='col-lg-2 d-flex flex-column'> {/* Sidebar */}
                                <div className='row sticky-top' style={{top: props?.parent_id && props?.parent_area ? '0px' : '115px', zIndex: 1}}>
                                <div className='col'>
                                { 
                                hasSpecialPermissionToAccess('cloud_entries:scratch-entry') &&
                                <div className='row mb-2 justify-content-center'>
                                    <div className='col-lg-2' style={watch('entry.scratched') ? {width: '170px'} : {width: '150px'}}>
                                        <button onClick={scratchUnscratchEntryDialog} disabled={watchTrainerAccount || (entry_fees_watch?.length && show_financials_locked)} className="btn btn-sm btn-secondary fw-bold px-2 py-2 col-12 text-uppercase"
                                        type="button" data-tooltip-id="ENTRIES.DETAIL.BUTTON.SCRATCHENTRY">
                                            {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.BUTTON.' + (watch('entry.scratched') ? "UNSCRATCHENTRY": "SCRATCHENTRY") })}
                                        </button>
                                    </div>
                                </div>
                                }
                        
                                {(entryStatus && entryStatus.length > 0)?
                                <div className='row my-4 align-items-center'>
                                    <div className='col-xl-1 col-lg-12 w-xl-40px d-flex justify-content-center'>
                                        <i className="fas fa-2x fa-regular fa-triangle-exclamation text-warning"></i>
                                    </div>
                                    <div className='col px-0 ps-1'>
                                         <label className='text-danger' style={{fontSize: '13px'}}>{entryStatus}</label> 
                                    </div>
                                </div>
                                : <div className='mb-4'></div>}

                                {/* Prize Money Box */}
                                <div className='row mb-2'>
                                    <div className='col form-control'>
                                        <div className='row'>
                                            <label className='col col-form-label fs-5 py-1 pe-0 fw-bold'>
                                                { intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.LABEL.PRIZEMONEY' })}
                                            </label>
                                        </div>
                                        <div className='row'>
                                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-90px pe-0'>
                                                { intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.LABEL.WON' })}
                                            </label>
                                            <label className='col col-form-label fs-5 py-1 d-flex justify-content-end ps-0'>
                                                { currencyFormatter(getValues('prizeMoneySummary.won'), true) }
                                            </label>
                                        </div>
                                        <div className='row'>
                                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-90px pe-0'>
                                                { intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.LABEL.UNAPPLIED' }) }
                                            </label>
                                            <label className={`col col-form-label fs-5 py-1 d-flex justify-content-end ps-0 ${getValues('prizeMoneySummary.unapplied') !== 0 && 'text-danger'}`}>
                                                { currencyFormatter(getValues('prizeMoneySummary.unapplied'), true) }
                                            </label>
                                        </div>
                                        <div className='row'>
                                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-90px pe-0'>
                                                { intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.LABEL.APPLIED' }) }
                                            </label>
                                            <label className='col col-form-label fs-5 py-1 d-flex justify-content-end ps-0'>
                                                { currencyFormatter(getValues('prizeMoneySummary.applied'), true) }
                                            </label>
                                        </div>
                                        <div className='row'>
                                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px pe-0'>
                                                { intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.LABEL.TAXWITHHELD' }) }
                                            </label>
                                            <label className='col col-form-label fs-5 py-1 d-flex justify-content-end ps-0'>
                                                { currencyFormatter(getValues('prizeMoneySummary.taxWithheld'), true)}
                                            </label>   
                                        </div>
                                        <div className='row'>
                                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-125px pe-0'>
                                                { intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.LABEL.PAYMENTAPPLIED' })}
                                            </label>
                                            <label className='col col-form-label fs-5 py-1 d-flex justify-content-end ps-0'>{
                                                currencyFormatter(getValues('prizeMoneySummary.paymentApplied'), true)
                                            }</label>
                                        </div>
                                    </div>
                                </div>

                                {/* Responsible Party Balance Box */}
                                <div className='row mb-1'>
                                    <div className='col form-control'>
                                        <div className='row'>
                                            <label className='col col-form-label fs-5 py-1 pe-0 fw-bold'>
                                                { intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.LABEL.RESPONSIBLEPARTY.BALANCES' })}
                                            </label>
                                        </div>
                                        <div className='row'>
                                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-100px pe-0'>
                                                { intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.LABEL.RESPONSIBLEPARTY.ACCOUNT.BALANCE' })}
                                            </label>
                                            <label className='col col-form-label fs-5 py-1 d-flex justify-content-end ps-0'>
                                                { currencyFormatter(balanceInfoForRpAndPMR?.rpAccountBalance ?? 0) }
                                            </label>
                                        </div>
                                        <div className='row'>
                                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-90px pe-0'>
                                                { intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.LABEL.RESPONSIBLEPARTY.ACCOUNT.CREDIT' }) }
                                            </label>
                                            <label className={`col col-form-label fs-5 py-1 d-flex justify-content-end ps-0 ${balanceInfoForRpAndPMR?.rpBalance < 0 && balanceInfoForRpAndPMR?.rpCredit >= 0 ? 'text-danger' : ''}`}>
                                                { currencyFormatter(balanceInfoForRpAndPMR?.rpBalance < 0 ? balanceInfoForRpAndPMR?.rpBalance : 0) }
                                            </label>
                                        </div>
                                        {
                                            /* Error is displayed even if there is a credit balance because the balance may not be transferable due to the missing SSN. 
                                            In such cases, we don't add the prize money account register. */
                                            balanceInfoForRpAndPMR?.rpBalance < 0 && balanceInfoForRpAndPMR?.rpBalance != balanceInfoForRpAndPMR?.rpCredit ?
                                            <div className='row'>
                                                <label className='col col-form-label fs-6 py-1 pe-0 text-danger'>
                                                    { balanceInfoForRpAndPMR.rpErrors.join(', ') }
                                                </label>
                                            </div> : null
                                        }

                                        <div className='row'>
                                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-100px pe-0'>
                                                {`Past Balance`}
                                            </label>
                                            <label className={`col col-form-label fs-5 py-1 d-flex justify-content-end ps-0 ${pastBalances && pastBalances?.rpPastBalance != 0 ? 'text-danger' : ''} text-decoration-underline cursor-pointer`} onClick={() => {setDisplayPastEntries(true)}}>
                                                {`${currencyFormatter(pastBalances?.rpPastBalance ?? 0)}`}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                {/* Entry Balance Box */}
                                <div className='row mb-2'>
                                    {/* <a onClick={checkPastBalance} className='col-lg-12 fs-5 py-1 px-0'>{pastBalanceMsg}</a> */}
                                    <div className='col form-control' onClick={() => printInvoiceHandler()}>
                                        <div className='row'>
                                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-90px pe-0' data-tooltip-id="ENTRIES.DETAIL.LABEL.CLASSFEES">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.LABEL.CLASSFEES' })}</label>
                                            <label className='col col-form-label fs-5 py-1 d-flex justify-content-end ps-0'>
                                                { currencyFormatter(watch('entry.class_fees_subtotal'), true) }
                                            </label>
                                        </div>
                                        <div className='row'>
                                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-90px pe-0' data-tooltip-id="ENTRIES.DETAIL.LABEL.MISCFEES">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.LABEL.MISCFEES' })}</label>
                                            <label className='col col-form-label fs-5 py-1 d-flex justify-content-end ps-0'>
                                                { currencyFormatter(watch('entry.entry_fee_subtotal'), true) }
                                            </label>
                                        </div>
                                        <div className='row'>
                                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-90px pe-0' data-tooltip-id="ENTRIES.DETAIL.LABEL.TAX">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.LABEL.TAX' })}</label>
                                            <label className='col col-form-label fs-5 py-1 d-flex justify-content-end ps-0 border border-dark border-top-0 border-left-0 border-right-0'>
                                                { currencyFormatter(watch('entry.tax'), true) }
                                            </label>
                                        </div>
                                        <div className='row'>
                                            <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-lg-90px pe-0' data-tooltip-id="ENTRIES.DETAIL.LABEL.SUBTOTAL">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.LABEL.SUBTOTAL' })}</label>
                                            <label className='col col-form-label fs-5 py-1 d-flex justify-content-end ps-0'>
                                                { currencyFormatter(watch('entry.total_fees'), true)} </label>
                                               
                                        </div>
                                        <div className='row'>
                                            <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-90px pe-0' data-tooltip-id="ENTRIES.DETAIL.LABEL.PAYMENTS">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.LABEL.PAYMENTS' })}</label>
                                            <label className='col col-form-label fs-5 py-1 d-flex justify-content-end ps-0 border border-dark border-top-0 border-left-0 border-right-0'>{
                                                currencyFormatter(watch('entry.total_paid'), true)
                                            }</label>
                                        </div>
                                        <div className='row'>
                                            <label className='col-lg-1 col-form-label fw-bold fs-5 py-1 w-lg-90px pe-0' data-tooltip-id="ENTRIES.DETAIL.LABEL.BALANCE">{intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.LABEL.BALANCE' })}</label>
                                            <label className='col col-form-label fs-5 py-1 d-flex justify-content-end ps-0'>
                                                { currencyFormatter(watch('entry.balance'), true)}
                                            </label>
                                        </div>
                                        <div className="row fa-item">
                                            <label className='col col-form-label fs-11 py-1 ps-0 d-flex align-items-center justify-content-end' style={{fontStyle: 'italic', fontSize: '0.9rem'}}>
                                                <i className="fas fa-print text-gray-800"  style={{ marginRight: '0.5rem' }}></i>
                                                {intl.formatMessage({ id: 'FORM.INPUT.ENTRIES.LABEL.PRINTINVOICEICON' })}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <input type='hidden' {...register("entry.entryowner_id")} />
                                <input type='hidden' {...register("entry.owner")} />
                                <input type='hidden' {...register("entry.trainer_id")} />
                                <input type='hidden' {...register("entry.trainer")} />
                                <input type='hidden' {...register("entry.horse_id")} />
                                <input type='hidden' {...register("entry.horse")} />
                                <input type='hidden' {...register("entry.responsibleparty_id")} />
                                <input type='hidden' {...register("entry.responsibleparty")} />
                                <input type='hidden' {...register("entry.prizemoneyrecipient_id")} />
                                <input type='hidden' {...register("entry.prizemoneyrecipient")} />
                                <input type='hidden' {...register("entry.prizemoneyrecipientsource")} />
                                <input type='hidden' {...register("entry.responsiblepartysource")} />
                                <input type='hidden' {...register("entry.stable_id")} />
                                <input type='hidden' {...register("entry.EntryRiders")} />
                                <input type='hidden' {...register("entry.entry_id")} />
                                <input type='hidden' {...register("entry.horse_age")} />
                                <input type='hidden' {...register("horse.horse_data")} />
                                <input type='hidden' {...register("horse.memberships_data")} />
                                <input type='hidden' {...register("entry.status")} />
                                <input type='hidden' {...register("horse.horse_data.dob")} />
                                <input type='hidden' {...register("horse.horse_data.sex")} />
                                <input type='hidden' {...register("horse.horse_data.type")} />
                                <input type='hidden' {...register("entry.horse_type")} />
                                <input type='hidden' {...register("dropInputRef")} />
                                <input type='hidden' {...register("trips")} />
                                <input type='hidden' {...register("addedEntryFees")} />
                                <input type='hidden' {...register("updatedEntryFees")} />
                                <input type='hidden' {...register("entryPrices")} />
                                <input type='hidden' {...register("prizeMoneyRollbackTrips")} />
                                <input type='hidden' {...register("prizeMoneyAppliedTrips")} />
                                <input type='hidden' {...register("entryFeesData")} />
                                <input type='hidden' {...register("entryFeesData.entryFees")} />
                                <input type='hidden' {...register("horse.horse_data.microchip_status")} />
                            </div> {/* End Sidebar */}
                            </div>
                            </div>
                        </div> {/* End Card Body */}
                    </div> {/* End Column Group */}

                    <InputFormFooter disableSave={show_financials_locked && params_entry_id == 0} goBackPath={'/entries'} propId={props?.id} display={displayDeleteButton}  data={'entry'} />
                </form>
                </FormProvider>
            </div>
            {linkClicked && <DetailPageModal prev_data={prevData} area={sub_area} detail_page={child_component} record_id={record_id} updateCallbackFromParent={updateData} open_forms={open_forms} gridRowData={gridRowData}/>}
        </>
    );
}

export const EntryIdContext = createContext()
const EntryIdContextProvider = ({children}) => {
    const [entry_id, setEntryId] = useState(useParams().entry_id);
    return <EntryIdContext.Provider value={{entry_id, setEntryId}}>{ children }</EntryIdContext.Provider>
}

const EntryIdContextWrapper = (props) => {
    return (
        <EntryIdContextProvider>
            <EntryDetail {...props} />
        </EntryIdContextProvider>
    )
}

export { EntryIdContextWrapper as EntryDetail }