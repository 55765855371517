import { AgGridReact } from "ag-grid-react";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import DecimalPointRenderer from "../../../../modules/output-listing/renderers/DecimalPointRenderer";
import { renderCheckBox } from "../../../../modules/sgl-utils/agGridHelpers";
import { useCurrencyFormatter } from "../../../../modules/sgl-utils/Formatters";
import { getNarrowHeaderHeight,getNarrowRowHeight } from "../../../../modules/sgl-utils/agGridHelpers";
import CheckboxHeader from "../../../../modules/components/CheckboxHeader";

const EntriesGrid = ({ rowData, quantity, totalPrice, setForceReapplySplit, checkAll, setCheckAll, toggleCheckAllRows, selectedFee, setRowData, clearQuantityUpdated, tabIndex=0 }) => {
    const intl = useIntl()
    const currencyFormatter = useCurrencyFormatter()
    const containerStyle = useMemo(() => ({ width: '100%', height: '300px' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%'}), []);

    const defaultColDef = useMemo(() => {
        return {
        //   minWidth: 110,
        //   width: 110,
          resizable: true,
          sortable: true,
          suppressMenu: true,
          suppressMovable: true,
          cellStyle: function(params) {
            if (typeof params.value === 'number') {
                return {textAlign: 'center'};
            } else {
              return {textAlign: 'left'};
            }
          },
          wrapHeaderText: true,
          autoHeaderHeight: true,
          headerClass: "ag-center-aligned-header",
          singleClickEdit: true,
        };
      }, []);

    const onCellEditStopped = (params) => {
        let quantity = parseFloat(params.node.data?.quantity)
        
        // Update row node
        let rowNode = params.node.data;
        rowNode.quantity = quantity;
        rowNode.quantityUpdated = true;
        rowNode.split_fee = quantity * rowNode.unit_fee;

        params.node.setData(rowNode);
        const updatedData = params.api.getDisplayedRowAtIndex(params.node.rowIndex).data;
        const updatedRowData = params.api.getRenderedNodes().map(node => node.data);
        setRowData(updatedRowData); // Update your external state (assuming `setRowData` is defined in your component)


        // Update rowData
        params.api.refreshCells({ rowNodes: [rowNode], force: true });
    }

    const columnDefs = [
        { 
            field: 'number', 
            headerName: intl.formatMessage({ id: 'LIST.ENTRIES.NUMBER' }), 
            cellStyle: { textAlign: 'center' },
            width: 100,
            sortable: true,
        },
        { 
            field: 'include',
            cellRenderer: params => renderCheckBox(params, () => {
                clearQuantityUpdated(params?.node?.data)
                setForceReapplySplit(prevState => !prevState)
            }),
            minWidth: 50,
            maxWidth: 50,
            headerComponentFramework: CheckboxHeader,
            headerComponentParams: {
                // headerName: 'include',
                onChange: (checked) => { 
                    setCheckAll(checked)
                    toggleCheckAllRows(checked)
                },
                checked: checkAll
            },
            cellStyle: ({textAlign: 'center'}),
            headerStyle: { textAlign: 'center'}
        },
        { 
            field: 'Show.show_name', 
            headerName: intl.formatMessage({ id: "ENTRIES.FEESPLIT.MODAL.GRID.SHOW" }), 
            flex: 2,
        },
        { 
            field: 'horse', 
            headerName: intl.formatMessage({ id: 'LIST.ENTRIES.HORSE' }),
            flex: 1,
            sortable: true,
        },
        { 
            field: 'rider_list', 
            headerName: intl.formatMessage({ id: "ENTRIES.FEESPLIT.MODAL.GRID.RIDER" }),
            flex: 1,
            sortable: true,
        },
        { 
            field: 'quantity',
            headerName: intl.formatMessage({ id: "ENTRIES.FEESPLIT.MODAL.GRID.QUANTITY" }),
            minWidth: 70,
            width: 70,
            cellRenderer: params => DecimalPointRenderer(params, 2),
            editable: params => selectedFee && params.data.include === true,
            cellStyle: { textAlign: 'right' },
        },
        { 
            field: 'unit_fee', 
            headerName: intl.formatMessage({ id: "ENTRIES.FEESPLIT.MODAL.GRID.UNITFEE" }),
            width: 100,
            cellRenderer: params => currencyFormatter(params.value),
            cellStyle: { textAlign: 'right' },
        },
        { 
            field: 'split_fee', 
            headerName: intl.formatMessage({ id: "ENTRIES.FEESPLIT.MODAL.GRID.SPLITFEE" }),
            width: 100,
            cellRenderer: params => currencyFormatter(params.value),
            cellStyle: { textAlign: 'right' },
        },
    ]

    return (
        <div>
            <div className="row mb-2">
                <label className='col-lg-11 col-form-label fs-5 py-1'>
                    { intl.formatMessage({ id: "ENTRIES.FEESPLIT.MODAL.GRID.LABEL" }) }
                </label>
                <div className="col-lg-1">
                    <label className='col-form-label fs-5 py-1 d-flex justify-content-end me-1'>
                        { rowData.length }
                    </label>
                </div>
            </div>
    
            <div style={containerStyle}>
                <div style={gridStyle} className="ag-theme-alpine ag-narrow-cell">
                    <AgGridReact
                        defaultColDef={defaultColDef}
                        rowData={rowData}
                        columnDefs={columnDefs} 
                        rowStyle={{ fontSize: '12px' }}
                        suppressScrollOnNewData={true}
                        headerHeight={getNarrowHeaderHeight}
                        rowHeight={getNarrowRowHeight}
                        onCellEditingStopped={onCellEditStopped}
                        stopEditingWhenCellsLoseFocus={true}
                        {...(tabIndex !== 0 && { tabIndex })} //tabIndex is set conditionally, only if a prop was passed, provided that the prop is not 0 
                    />
                </div>
            </div>
          

            <div className="row mb-2">
                <label className="col-lg-8"></label>
                <label className="w-lg-50px"></label>
                <label className='col-lg-1 col-form-label fs-5 py-1 w-lg-100px fw-bold'>
                    Totals:
                </label>
                <label className='col-lg-1 col-form-label fs-5 py-1'>
                    {rowData.reduce((sum, row) => row.include ? sum + row.quantity : sum, 0).toFixed(2)}
                    {/* { Number(quantity).toFixed(2) } */}
                </label>
                <div className="col-lg-1">
                    <label className='col-form-label fs-5 py-1 d-flex justify-content-end'>
                    {rowData.reduce((sum, row) => row.include ? sum + row.split_fee : sum, 0).toFixed(2)}

                        {/* {rowData.reduce((sum, row) => sum + row.split_fee, 0).toFixed(2)} */}

                        {/* { totalPrice.toFixed(2) } */}
                    </label>
                </div>
            </div>
        </div>
       
    )
}


export default EntriesGrid