import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import { useIntl } from 'react-intl';
import { KTSVG } from '../../../../../_metronic/helpers';
import OutputListingGridStatic from '../../../../modules/output-listing/OutputListingGridStatic';
import { getExtraNarrowRowHeight } from '../../../../modules/sgl-utils/agGridHelpers';

const modalsRoot = document.getElementById('root-modals') || document.body;

const FeeSelectionModal = ({ show, onClose, feesToDrop, onConfirm }) => {
    const intl = useIntl();
    const [gridRef, setGridRef] = useState(null);
    const [selectedFeeIds, setSelectedFeeIds] = useState([]);

    useEffect(() => {
        if (feesToDrop?.length > 0 && gridRef) {
            // const allFeeIds = feesToDrop.map(fee => fee.showfees_id);
            // setSelectedFeeIds(allFeeIds); // Preselect all fees by default
            gridRef.forEachNode(node => node.setSelected(true)); // Select all rows
        }
    }, [feesToDrop, gridRef]);

    const hideModal = () => onClose && onClose();

    const handleRowSelection = () => {
        if (gridRef) {
            const selectedIds = gridRef.getSelectedRows().map(row => row.showfees_id); // Store only IDs
            setSelectedFeeIds(selectedIds);
        }
    };

    const confirmSelection = () => {
        onConfirm(selectedFeeIds); // Return only IDs
        hideModal();
    };

    const columnDefs = [
        { field: '', checkboxSelection: true, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: false, minWidth: 50, maxWidth: 50, sortable: false },
        { field: 'description', headerName: intl.formatMessage({ id: 'FEES.SELECTION.DESCRIPTION' }), flex: 1, filter: 'agTextColumnFilter', wrapText: true, autoHeight: true },
        { field: 'quantity', headerName: intl.formatMessage({ id: 'FEES.SELECTION.QTY' }), minWidth: 70, maxWidth: 70 },
        { field: 'totalprice', headerName: intl.formatMessage({ id: 'FEES.SELECTION.TOTALPRICE' }), width: 100 }
    ];

    return createPortal(
        <Modal
            enforceFocus={false}
            tabIndex={-1}
            dialogClassName="modal-dialog modal-dialog-centered mw-550px search-form"
            show={show}
            onHide={hideModal}
        >
            <div className="modal-header py-2 px-4">
                <h2 className="fs-4">{intl.formatMessage({ id: 'FEES.SELECTION.TITLE' })}</h2>
                <button className="btn btn-sm btn-icon btn-active-color-dark" onClick={hideModal}>
                    <KTSVG className="svg-icon-1" path="/media/icons/duotune/arrows/arr061.svg" />
                </button>
            </div>

            <div className="modal-body py-3 px-4">
                {/* Informational note for the user */}
                <div className="mb-3 col-form-label fs-5 py-0">
                    {intl.formatMessage({ id: 'FEES.SELECTION.NOTE' })}
                </div>

                <OutputListingGridStatic
                    setGridRef={setGridRef}
                    columnDefs={columnDefs}
                    rowData={feesToDrop}
                    containerStyle={{ width: '100%', height: '400px' }}
                    rowHeight={getExtraNarrowRowHeight}
                    selection="multiple"
                    rowMultiSelectWithClick={true}
                    onRowSelected={handleRowSelection}
                />
            </div>

            <div className="modal-footer d-flex justify-content-end py-3 px-4">
                <button className='btn btn-sm btn-secondary me-4 fw-bold text-uppercase' onClick={hideModal}>
                    {intl.formatMessage({ id: 'DIALOG.BUTTON.CANCEL' })}
                </button>
                <button
                    className='btn btn-sm btn-dark fw-bold text-uppercase'
                    onClick={confirmSelection}
                    disabled={selectedFeeIds.length === 0}
                >
                    {intl.formatMessage({ id: 'DIALOG.BUTTON.CONFIRM' })}
                </button>
            </div>
        </Modal>,
        modalsRoot
    );
};

export { FeeSelectionModal };
